import React, { Component } from 'react';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import { Modal, Button, Form, Segment, Header, Icon } from 'semantic-ui-react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import _ from 'lodash';

// Import components.
import MimeIcon from '../Shared/MimeIcon';
// Import components
import InlineError from '../Messages/InlineError';
import ShowError from '../Messages/ShowError';

import { organizationUpdateStudent } from '../../redux/actions/studentActions';
import { humanReadableFileSize } from '../../helpers/utils';
import { deleteFile, updateDocument } from '../../api/firebase/firestore';

class StudentDocumentList extends Component {
  state = {
    modalOpen: false,
    openConfirmationDialog: false,
    deleting: false,
    documentToDelete: {},
    data: {
      id: '',
      name: '',
      expirationDate: null,
      tags: [],
    },
    errors: {},
    tagOptions: [],
    loading: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { selectedStudent } = nextProps;

    if (!_.isEmpty(selectedStudent.id)) {
      const { documents } = selectedStudent;
      return {
        documents,
      };
    }
    return prevState;
  }

  handleAddition = (e, { value }) => {
    this.setState({
      tagOptions: [{ text: value, value }, ...this.state.tagOptions],
    });
  };

  handleChange = (e, { name, value }) =>
    this.setState({ data: { ...this.state.data, [name]: value } });

  handleExpirationDate = expirationDate => {
    this.setState({
      data: {
        ...this.state.data,
        expirationDate,
      },
      errors: _.omit(this.state.errors, expirationDate),
    });
  };

  validate = data => {
    const errors = {};

    return errors;
  };

  saveChanges = () => {
    const {
      selectedStudent,
      currentOrganization: { id },
    } = this.props;

    const {
      rowData,
      data: { documentId, name, expirationDate, tags },
    } = this.state;

    if (selectedStudent && selectedStudent.id && id) {
      const studentData = {
        ...selectedStudent,
        documents: {
          ...selectedStudent.documents,
          [documentId]: {
            ...rowData,
            documentId,
            name,
            expirationDate: expirationDate
              ? expirationDate.utc().valueOf()
              : null,
            tags,
          },
        },
      };

      this.props.organizationUpdateStudent(id, studentData).then(() =>
        this.setState({
          tagOptions: [],
          data: {
            id: '',
            name: '',
            expirationDate: null,
            tags: [],
          },
        })
      );
    }
  };

  removeDocument = refId => {
    const {
      selectedStudent,
      currentOrganization: { id },
    } = this.props;

    if (selectedStudent && selectedStudent.id && id) {
      const documents = _.omit(selectedStudent.documents, [refId]);

      // TODO: Create action creator.
      updateDocument({
        path: `organizations/${id}/students/${selectedStudent.id}`,
        data: { documents },
      });
    }
  };

  renderFileForm = () => {
    const { data, errors } = this.state;

    return (
      <Segment basic clearing>
        <ShowError errors={errors} />
        <Form>
          <Form.Input
            name="name"
            label="Name"
            fluid
            value={data.name}
            onChange={this.handleChange}
            error={!!errors.name}
          >
            {errors.name && <InlineError text={errors.name} />}
          </Form.Input>
          <Form.Field
            name="expirationDate"
            label="Expiration Date"
            fluid
            placeholder="Select Expiration Date"
            dateFormat="YYYY-MM-DD"
            isClearable
            minDate={moment()}
            control={DatePicker}
            selected={data.expirationDate}
            onChange={this.handleExpirationDate}
            error={!!errors.expirationDate}
          >
            {errors.expirationDate && (
              <InlineError text={errors.expirationDate} />
            )}
          </Form.Field>

          <Form.Dropdown
            name="tags"
            label="Tags"
            search
            selection
            fluid
            multiple
            allowAdditions
            value={data.tags}
            options={this.state.tagOptions}
            onAddItem={this.handleAddition}
            onChange={this.handleChange}
            error={!!errors.tags}
          >
            {errors.tags && <InlineError text={errors.tags} />}
          </Form.Dropdown>
        </Form>
      </Segment>
    );
  };

  renderNoData = () => (
    <Segment basic clearing textAlign="center">
      <Header as={'h3'}>
        <Icon circular inverted name="info" color="grey" />
      </Header>
      <Header as={'h4'}>No files found.</Header>
      <p className="text-muted">
        You can upload files by clicking the upload button.
      </p>
    </Segment>
  );

  renderTable = () => {
    const {
      selectedStudent: { documents },
    } = this.props;

    const columns = [
      {
        text: '',
        dataField: 'documentId',
        hidden: true,
      },
      {
        text: 'Name',
        dataField: 'name',
        sort: true,
        formatter: (cell, row, rowIndex, formatExtraData) => (
          <div>
            <Header
              key={rowIndex}
              as="h4"
              className="no-margin"
              icon={<MimeIcon mime={row.type} />}
              content={
                <div>
                  <a
                    href={row.downloadUrl}
                    target="_blank"
                    download
                    rel="noopener noreferrer"
                  >
                    {cell}
                  </a>
                  {row.size && (
                    <em className="text-muted">{` (${row.size})`}</em>
                  )}
                </div>
              }
            />
            <div style={{ margin: '5px 0' }}>
              <div
                className="edited-by text-muted"
                style={{ fontSize: '12px' }}
              >
                {`Edited by: ${row.addedBy}`}
              </div>
              <div
                className="uploaded-date text-muted"
                style={{ fontSize: '12px' }}
              >
                {`Uploaded date: ${row.uploadedDate}`}
              </div>
            </div>
          </div>
        ),
        headerStyle: () => {
          return { width: '45%' };
        },
        style: { verticalAlign: 'middle' },
      },
      {
        text: 'Tags',
        dataField: 'tags',
        style: { verticalAlign: 'middle' },
        formatter: (cell, row, rowIndex, formatExtraData) => cell.join(', '),
        headerStyle: () => {
          return { width: '20%' };
        },
      },
      {
        text: 'Expiration',
        dataField: 'expirationDate',
        style: { verticalAlign: 'middle' },
        formatter: (cell, row, rowIndex, formatExtraData) =>
          cell ? moment(cell, 'x').format('YYYY-MM-DD') : '',
      },
      {
        text: '',
        dataField: '',
        key: 'edit-button',
        formatter: (cell, row, rowIndex, formatExtraData) => (
          <div>
            <Button
              circular
              icon="edit outline"
              color="green"
              onClick={() => {
                const { documents } = this.state;
                if (documents && row.documentId) {
                  const selectedDocument = documents[row.documentId];
                  this.setState({
                    modalOpen: true,
                    rowData: selectedDocument,
                    tagOptions:
                      selectedDocument.tags && selectedDocument.tags.length
                        ? selectedDocument.tags.map(tag => ({
                            text: tag,
                            value: tag,
                          }))
                        : [],
                    data: {
                      documentId: selectedDocument.documentId,
                      name: selectedDocument.name,
                      tags: selectedDocument.tags,
                      expirationDate: selectedDocument.expirationDate
                        ? moment(selectedDocument.expirationDate, 'x')
                        : null,
                    },
                  });
                }
              }}
            />
            <Button
              circular
              icon="trash alternate outline"
              color="red"
              onClick={() =>
                this.setState({
                  openConfirmationDialog: true,
                  documentToDelete: row,
                })
              }
            />
          </div>
        ),
        style: { verticalAlign: 'middle' },
        align: 'right',
        headerStyle: () => {
          return { width: '20%' };
        },
      },
    ];

    const data = documents
      ? Object.keys(documents).map(key => ({
          documentId: documents[key].documentId,
          name: documents[key].name,
          addedBy: documents[key].uploadedBy.name,
          type: documents[key].type,
          uploadedDate: documents[key].uploadedDate
            ? moment(documents[key].uploadedDate, 'x').format(
                'YYYY-MM-DD - h:m A'
              )
            : '',
          expirationDate: documents[key].expirationDate || null,
          downloadUrl: documents[key].downloadUrl,
          path: documents[key].path,
          refId: documents[key].meta.refId || '',
          size: humanReadableFileSize(documents[key].size) || '',
          tags: documents[key].tags || [],
          rowData: documents[key],
        }))
      : [];

    return data.length ? (
      <BootstrapTable
        noDataIndication="no results to display"
        keyField="documentId"
        bordered={false}
        data={data}
        columns={columns}
      />
    ) : (
      this.renderNoData()
    );
  };

  renderConfirmationDialog = () => (
    <Modal
      size="mini"
      open={this.state.openConfirmationDialog}
      onClose={() => this.setState({ openConfirmationDialog: false })}
    >
      <Modal.Header>Delete Document</Modal.Header>
      <Modal.Content>
        <p>Are you sure you want to delete this document?</p>
      </Modal.Content>
      <Modal.Actions>
        <Button>No</Button>
        <Button
          negative
          icon="checkmark"
          labelPosition="right"
          content="Yes"
          loading={this.state.deleting}
          onClick={async row => {
            const {
              documentToDelete: { path, refId },
            } = this.state;
            if (path && refId) {
              try {
                this.setState({ deleting: true });
                await this.removeDocument(refId);
                await deleteFile({ path, refId });
                this.setState({
                  documentToDelete: {},
                  openConfirmationDialog: false,
                  deleting: false,
                });
              } catch (error) {
                console.log(error);
                this.setState({
                  documentToDelete: {},
                  openConfirmationDialog: false,
                  deleting: false,
                  errors: { ...this.state.errors, error },
                });
              }
            }
          }}
        />
      </Modal.Actions>
    </Modal>
  );

  renderEditModal = () => (
    <Modal
      style={{ overflow: 'visible' }}
      key="documents-modal"
      open={this.state.modalOpen}
      onClose={() => {
        this.setState({ modalOpen: false });
      }}
      header="Edit Student Document"
      content={this.renderFileForm()}
      actions={[
        'Cancel',
        {
          key: 'done',
          content: 'Update',
          primary: true,
          onClick: () => {
            this.saveChanges();
          },
        },
      ]}
    />
  );

  render() {
    return (
      <div>
        {this.renderEditModal()}
        {this.renderConfirmationDialog()}
        {this.renderTable()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedStudent: state.students.selectedStudent,
  currentOrganization: state.organizations.currentOrganization,
});

export default connect(
  mapStateToProps,
  { organizationUpdateStudent }
)(StudentDocumentList);
