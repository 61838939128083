import { SLIDER_TOGGLED } from '../actions/types';

const initialState = {
  sliderOpen: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SLIDER_TOGGLED:
      return {
        ...state,
        sliderOpen: action.sliderOpen,
      };

    default:
      return state;
  }
};
