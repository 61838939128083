import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Card, Icon, Segment, Header } from 'semantic-ui-react';

// Import actions
import {
  startOrganizationStaffListener,
  staffSelected,
} from '../../redux/actions/staffActions';

// Import Components
import StaffEditCard from './StaffEditCard';
import StaffForm from '../Forms/StaffForm';
import withSlidingPanel from '../Shared/withSlidingPanel';
import withPermission from '../Shared/withPermission';

import { userHasPermission } from '../../api/firebase/account';

const SlidingStaffFormAdd = withSlidingPanel(StaffForm, {
  title: 'Add Staff',
});

const SlidingStaffFormEdit = withSlidingPanel(StaffForm, {
  title: 'Edit Staff',
});

const StaffCreateCard = props => (
  <Card className="add-new" {...props}>
    <Card.Content>
      <div className={'center'}>
        <Card.Header as={'h4'}>
          <Icon name="plus" />
        </Card.Header>
        <Card.Description>Add more staff</Card.Description>
      </div>
    </Card.Content>
  </Card>
);

const StaffCreateCardWithPermission = withPermission(
  StaffCreateCard,
  'can_create_staff'
);

class StaffList extends Component {
  state = {
    isAddOpen: false,
    isEditOpen: false,
  };

  componentDidMount = () => {
    this.startListeners();
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { currentOrganization } = this.props;

    if (
      !prevProps.currentOrganization ||
      prevProps.currentOrganization.id !== currentOrganization.id
    ) {
      this.startListeners();
    }
  };

  componentWillUnmount = () => {
    this.unsubscribeListeners();
  };

  startListeners = () => {
    const {
      currentOrganization: { id },
    } = this.props;
    if (id) {
      this.unsubscribeListeners();
      this.unSubscribeOrganizationStaffListener = this.props.startOrganizationStaffListener(
        id
      );
    }
  };

  unsubscribeListeners = () => {
    if (this.unSubscribeOrganizationStaffListener)
      this.unSubscribeOrganizationStaffListener();
  };

  renderPageTitle = () => {
    const {
      staff: { list },
    } = this.props;

    if (list) {
      return (
        <Segment basic clearing>
          <Header as="h4" floated="right">
            <Icon.Group>
              <Icon name="filter" />
            </Icon.Group>
            Filter
          </Header>
          <Header as="h1" floated="left">
            Staff ({list.length})
          </Header>
        </Segment>
      );
    }

    return (
      <Segment basic clearing>
        <Header as="h1" floated="left">
          Staff
        </Header>
      </Segment>
    );
  };

  renderStaffList = () => {
    const {
      staff: { list },
      itemsPerRow,
    } = this.props;

    return (
      <Card.Group stackable itemsPerRow={itemsPerRow || 4}>
        <StaffCreateCardWithPermission
          onClick={() => this.setState({ isAddOpen: true })}
        />

        {list.map(staff => (
          <StaffEditCard
            key={staff.uid}
            staff={staff}
            onClick={() => {
              if (userHasPermission('can_edit_staff')) {
                this.props.staffSelected(staff);
                this.setState({ isEditOpen: true });
              }
            }}
          />
        ))}
      </Card.Group>
    );
  };

  renderSlidingForms = () => {
    return (
      <div>
        <SlidingStaffFormAdd
          isOpen={this.state.isAddOpen}
          onClose={() => this.setState({ isAddOpen: false })}
        />
        <SlidingStaffFormEdit
          isOpen={this.state.isEditOpen}
          onClose={() => {
            this.setState({ isEditOpen: false });
          }}
        />
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderPageTitle()}
        {this.renderStaffList()}
        {this.renderSlidingForms()}
      </div>
    );
  }
}

StaffList.defaultProps = {
  itemsPerRow: 4,
};

StaffList.propTypes = {
  staff: PropTypes.shape({
    selectedStaff: PropTypes.shape({
      uid: PropTypes.string,
    }),
    list: PropTypes.arrayOf(
      PropTypes.shape({
        uid: PropTypes.string,
      })
    ),
  }),
  startOrganizationStaffListener: PropTypes.func.isRequired,
  staffSelected: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  staff: state.staff,
  currentOrganization: state.organizations.currentOrganization,
});

export default connect(
  mapStateToProps,
  { startOrganizationStaffListener, staffSelected }
)(StaffList);
