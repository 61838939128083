import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Segment } from 'semantic-ui-react';
import _ from 'lodash';
import Validator from 'validator';
import MaskedInput from 'react-text-mask';

// Import components
import InlineError from '../Messages/InlineError';
import ShowError from '../Messages/ShowError';
import StatePicker from '../Shared/StatePicker';

// Import utils
import { phoneNumberFormat, phoneNumberParse } from '../../helpers/utils';

import {
  updateMoxitOrganization,
  createMoxitOrganization,
} from '../../redux/actions/organizationActions';

class OrganizationForm extends Component {
  state = {
    data: {
      id: '',
      name: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipcode: '',
      phone: '',
      active: true,
    },
    loading: false,
    errors: {},
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { organization } = this.props;

    if (organization && this.state.data.id !== organization.id) {
      const { phone, ...rest } = organization;
      this.setState({
        data: {
          phone: phoneNumberParse(phone),
          ...rest,
        },
      });
    }
  };

  onChange = (e, { name, value }) => {
    this.setState({
      data: { ...this.state.data, [name]: value },
      errors: _.omit(this.state.errors, name),
    });
  };

  maskedOnChange = e => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
      errors: _.omit(this.state.errors, e.target.name),
    });
  };

  validate = data => {
    const errors = {};

    if (!data.name) errors.name = 'Name is required';
    if (!data.phone) {
      errors.phone = 'Phone is required';
    } else {
      if (!Validator.isMobilePhone(data.phone, 'en-US'))
        errors.phone = 'Phone is invalid';
    }
    if (!data.address1) errors.address1 = 'Address is required';
    if (!data.city) errors.city = 'City is required';
    if (!data.state) errors.state = 'State is required';
    if (!data.zipcode) errors.zipcode = 'Zip code is required';

    return errors;
  };

  onSubmit = e => {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });

    if (_.isEmpty(errors)) {
      const {
        data: { id, phone, ...rest },
      } = this.state;

      const {
        user: { uid },
      } = this.props;

      this.setState({ loading: true });
      const formData = { phone: phoneNumberFormat(phone), ...rest };

      // Update.
      if (id) {
        this.props
          .updateMoxitOrganization({
            id,
            ...formData,
          })
          .then(() => {
            // Used to close wrapper HOC. e.g Sliding panel, modal...
            if (this.props.onClose) {
              this.props.onClose('updated');
              return;
            }
            this.setState({ loading: false });
          })
          .catch(error =>
            this.setState({
              loading: false,
              errors: { 'Unable to Update': error.message },
            })
          );
      } else {
        // New entry.
        this.props
          .createMoxitOrganization({ ...formData, createdBy: uid })
          .then(() => {
            // Used to close wrapper HOC. e.g Sliding panel, modal...
            if (this.props.onClose) {
              this.props.onClose('added');
              return;
            }
            this.setState({ loading: false });
          })
          .catch(error =>
            this.setState({
              loading: false,
              errors: { 'Unable to Add': error.message },
            })
          );
      }
    }
  };

  render() {
    const { data, loading, errors } = this.state;
    return (
      <Segment basic textAlign="left">
        <ShowError errors={errors} />
        <Form
          id="location-form"
          onSubmit={this.onSubmit}
          loading={loading}
          noValidate
        >
          <Form.Field error={!!errors.name}>
            <Form.Input
              required
              type="text"
              id="name"
              name="name"
              label="Name"
              placeholder="Name"
              value={data.name}
              onChange={this.onChange}
            />
            {errors.name && <InlineError text={errors.name} />}
          </Form.Field>

          <Form.Group widths="equal">
            <Form.Field error={!!errors.address1}>
              <Form.Input
                required
                type="text"
                id="address1"
                name="address1"
                label="Address 1"
                placeholder="Address Line 1"
                value={data.address1}
                onChange={this.onChange}
              />
              {errors.address1 && <InlineError text={errors.address1} />}
            </Form.Field>
            <Form.Field error={!!errors.address2}>
              <Form.Input
                type="text"
                id="address2"
                name="address2"
                label="Address 2"
                placeholder="Address Line 2"
                value={data.address2}
                onChange={this.onChange}
              />
              {errors.address2 && <InlineError text={errors.address2} />}
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field error={!!errors.city}>
              <Form.Input
                required
                type="text"
                id="city"
                name="city"
                label="City"
                placeholder="City"
                value={data.city}
                onChange={this.onChange}
              />
              {errors.city && <InlineError text={errors.city} />}
            </Form.Field>

            <Form.Field error={!!errors.state}>
              <Form.Field
                required
                id="state"
                name="state"
                label="State"
                control={StatePicker}
                placeholder="State"
                value={data.state}
                selection
                search
                onChange={this.onChange}
              />
              {errors.state && <InlineError text={errors.state} />}
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field error={!!errors.zipcode}>
              <Form.Input
                required
                type="text"
                id="zipcode"
                name="zipcode"
                label="Zip code"
                placeholder="Zip code"
                value={data.zipcode}
                onChange={this.onChange}
              />
              {errors.zipcode && <InlineError text={errors.zipcode} />}
            </Form.Field>
            <Form.Field error={!!errors.phone}>
              <Form.Field
                required
                type="text"
                id="phone"
                name="phone"
                label="Phone"
                placeholder="Phone"
                control={MaskedInput}
                mask={[
                  '(',
                  /[1-9]/,
                  /\d/,
                  /\d/,
                  ')',
                  ' ',
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                value={data.phone}
                guide={false}
                onChange={this.maskedOnChange}
              />
              {errors.phone && <InlineError text={errors.phone} />}
            </Form.Field>
          </Form.Group>

          <Form.Group>
            <Form.Button primary content="Save" />
            <Form.Button
              basic
              content="Cancel"
              onClick={e => {
                if (e) e.preventDefault();
                if (this.props.onClose) this.props.onClose();
              }}
            />
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(
  mapStateToProps,
  {
    createMoxitOrganization,
    updateMoxitOrganization,
  }
)(OrganizationForm);
