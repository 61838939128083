import React, { Component } from 'react';
import { Menu } from 'semantic-ui-react';

export default class OrganizationMenu extends Component {
  state = { activeItem: 'overview' };

  handleItemClick = (e, { name }) =>
    this.setState({ activeItem: name.replace(/\s/g, '').toLowerCase() });

  render() {
    const { activeItem } = this.state;

    return (
      <Menu pointing secondary compact>
        <Menu.Item
          name="overview"
          active={activeItem === 'overview'}
          onClick={this.handleItemClick}
        />
        <Menu.Item
          name="hirearchy"
          active={activeItem === 'hirearchy'}
          onClick={this.handleItemClick}
        />
        <Menu.Item
          name="people"
          active={activeItem === 'people'}
          onClick={this.handleItemClick}
        />
        <Menu.Item
          name="metrics"
          active={activeItem === 'metrics'}
          onClick={this.handleItemClick}
        />
        <Menu.Item
          name="documents"
          active={activeItem === 'documents'}
          onClick={this.handleItemClick}
        />
        <Menu.Item
          name="financials"
          active={activeItem === 'financials'}
          onClick={this.handleItemClick}
        />
        <Menu.Item
          name="Customer Journey"
          active={activeItem === 'customerjourney'}
          onClick={this.handleItemClick}
        />
      </Menu>
    );
  }
}
