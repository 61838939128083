import {
  STAFF_FETCHED,
  STAFF_LISTENER_STARTED,
  STAFF_ADDED,
  STAFF_SELECTED,
  STAFF_SELECTION_CLEARED,
  STAFF_UPDATED,
  USER_EMAIL_RESET_LINK_SENT,
} from './types';
import {
  organizationStaffOnListen,
  createStaffAccount,
  updateOrganizationStaff,
} from '../../api/firebase/staff';

import { sendPasswordResetEmail } from '../../api/firebase/account';

export const staffListenerStarted = () => ({
  type: STAFF_LISTENER_STARTED,
});

export const staffFetched = ({ count, staff }) => ({
  type: STAFF_FETCHED,
  count,
  staff,
});

export const staffAdded = () => ({
  type: STAFF_ADDED,
});

export const staffUpdated = () => ({
  type: STAFF_UPDATED,
});

export const staffSelected = staff => ({
  type: STAFF_SELECTED,
  staff,
});

export const staffSelectionCleared = () => ({
  type: STAFF_SELECTION_CLEARED,
});

export const emailResetLinkSent = () => ({
  type: USER_EMAIL_RESET_LINK_SENT,
});

export const startOrganizationStaffListener = organizationId => dispatch => {
  return organizationStaffOnListen(
    organizationId,
    data => {
      dispatch(staffListenerStarted());
      if (data) dispatch(staffFetched(data));
    },
    error => console.log(error.message)
  );
};

export const organizationAddStaff = staffData => dispatch =>
  createStaffAccount(staffData).then(response => {
    const { data } = response;

    // Skip bogus emails
    if (data && data.uid && data.email)
      if (data.email.indexOf('moxit_') === -1)
        dispatch(resetStaffPassword(data.email));

    return dispatch(staffAdded());
  });

export const organizationUpdateStaff = (
  organizationId,
  staffData
) => dispatch =>
  updateOrganizationStaff(organizationId, staffData).then(location =>
    dispatch(staffUpdated())
  );

export const resetStaffPassword = email => dispatch =>
  sendPasswordResetEmail(email, {
    url: `${process.env.REACT_APP_PATH}/invited`,
    handleCodeInApp: true,
  }).then(() => dispatch(emailResetLinkSent()));
