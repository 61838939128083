import React from 'react';
import { NavLink } from 'react-router-dom';
import { Grid, Header, Image, Segment } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';

import SignUpForm from '../Components/Forms/Auth/SignUpForm';
import moxitLogoSquare from '../styles/img/moxit-logo.png';

const Signup = () => {
  const renderPageHead = () => (
    <Helmet>
      <title>Sign-up</title>
      <body class="auth sign-up" />
    </Helmet>
  );

  return (
    <Grid textAlign="center" style={{ height: '100%' }} verticalAlign="middle">
      {renderPageHead()}

      <Grid.Column style={{ maxWidth: 550 }}>
        {/* <Header as="h2" textAlign="center">
          <Image src={moxitLogoSquare} />
        </Header> */}
        <Segment>
          <SignUpForm />
        </Segment>
        <Segment basic>
          Already have an account? <NavLink to="/signin">Sign in</NavLink>.
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

export default Signup;
