import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';
import config from './config';

firebase.initializeApp(config);
const settings = { /* your settings... */ timestampsInSnapshots: true };
firebase.firestore().settings(settings);

// firebase
//   .firestore()
//   .enablePersistence()
//   .catch(err => {
//     if (err.code === 'failed-precondition') {
//       console.log(
//         'Multiple tabs open, persistence can only be enabled in one tab at a a time'
//       );
//     } else if (err.code === 'unimplemented') {
//       console.log(
//         'The current browser does not support all of the features required to enable persistence'
//       );
//     }
//   });

// Export firebase stuff.
export const { auth, firestore, storage, functions } = firebase;
export default firebase;
