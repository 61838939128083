import React from 'react';
import PropTypes from 'prop-types';
import { Card, Header, List } from 'semantic-ui-react';
import Avatar from 'react-avatar';

const StudentCard = ({ student, onClick }) => {
  const studentTree = student.family
    ? Object.keys(student.family).map(key => {
        const member = student.family[key];

        if (member) {
          return (
            <List.Item key={key} style={{ marginLeft: 0, marginRight: 0 }}>
              <Avatar
                name={`${member.firstName} ${member.lastName}`}
                round
                size={35}
                src={member.picture}
              />
              <List.Content>
                <br />
                <List.Header as="h5">{`${member.firstName}`}</List.Header>
              </List.Content>
            </List.Item>
          );
        }
        return null;
      })
    : null;

  return (
    <Card className="shadow center student" onClick={onClick} key={student.id}>
      <Card.Content className="center">
        <Avatar
          name={`${student.firstName} ${student.lastName}`}
          round
          size={75}
          className="left floated"
          src={student.picture}
        />
      </Card.Content>

      <Card.Content className="no-border" extra>
        <Card.Header>
          <Header as="h3">{`${student.firstName} ${student.middleName || ''} ${
            student.lastName
          }`}</Header>
        </Card.Header>
        <Card.Meta>{student.email}</Card.Meta>
        <Card.Description>
          <br />
          <br />
        </Card.Description>
        <Card.Description>
          <List horizontal relaxed>
            {studentTree}
          </List>
        </Card.Description>
      </Card.Content>
    </Card>
  );
};

StudentCard.propTypes = {
  students: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default StudentCard;
