// User
export const USER_SIGNED_IN = 'USER_SIGNED_IN';
export const USER_AUTHENTICATING = 'USER_AUTHENTICATING';
export const USER_AUTHENTICATED = 'USER_AUTHENTICATED';
export const USER_AUTHENTICATION_FAILED = 'USER_AUTHENTICATION_FAILED';
export const USER_SIGNING_OUT = 'USER_SIGNING_OUT';
export const USER_SIGNED_OUT = 'USER_SIGNED_OUT';
export const USER_SIGNED_UP = 'USER_SIGNED_UP';
export const USER_PROFILE_FETCHED = 'USER_PROFILE_FETCHED';
export const USER_PROFILE_UPDATED = 'USER_PROFILE_UPDATED';
export const USER_TOKEN_REFRESHED = 'USER_TOKEN_REFRESHED';
export const USER_EMAIL_VERIFICATION_LINK_SENT =
  'USER_EMAIL_VERIFICATION_LINK_SENT';
export const USER_EMAIL_RESET_LINK_SENT = 'USER_EMAIL_RESET_LINK_SENT';
export const USER_EMAIL_VERIFIED = 'USER_EMAIL_VERIFIED';
export const USER_RESET_CODE_VERIFIED = 'USER_RESET_CODE_VERIFIED';
export const USER_PASSWORD_RESET_CONFIRMED = 'USER_PASSWORD_RESET_CONFIRMED';
export const USER_PASSWORD_RESET_SENT = 'USER_PASSWORD_RESET_SENT';
export const USER_PROFILE_LISTENER_STARTED = 'USER_PROFILE_LISTENER_STARTED';
export const USER_PIN_UPDATED = 'USER_PIN_UPDATED';

// Permissions
export const PERMISSIONS_LISTENER_STARTED = 'PERMISSIONS_LISTENER_STARTED';
export const PERMISSION_CHANGED = 'PERMISSION_CHANGED';

// Roles
export const ROLES_LISTENER_STARTED = 'ROLES_LISTENER_STARTED';
export const ROLES_CHANGED = 'ROLES_CHANGED';

// Organization
export const ORGANIZATION_SELECTED = 'ORGANIZATION_SELECTED';
export const ORGANIZATION_CREATED = 'ORGANIZATION_CREATED';
export const ORGANIZATION_UPDATED = 'ORGANIZATION_UPDATED';
export const ORGANIZATION_FETCHED = 'ORGANIZATION_FETCHED';
export const ORGANIZATION_PROVISIONING_STARTED =
  'ORGANIZATION_PROVISIONING_STARTED';
export const ORGANIZATION_PROVISIONING_COMPLETE =
  'ORGANIZATION_PROVISIONING_COMPLETE';
export const ORGANIZATION_LISTENER_STARTED = 'ORGANIZATION_LISTENER_STARTED';
export const MOXIT_ORGANIZATIONS_FETCHED = 'MOXIT_ORGANIZATIONS_FETCHED';
export const ORGANIZATION_SWITCHED = 'ORGANIZATION_SWITCHED';

// Locations
export const LOCATIONS_FETCHED = 'LOCATIONS_FETCHED';
export const LOCATION_ADDED = 'LOCATION_ADDED';
export const LOCATION_UPDATED = 'LOCATION_UPDATED';
export const LOCATION_SELECTED = 'LOCATION_SELECTED';
export const LOCATION_SELECTION_CLEARED = 'LOCATION_SELECTION_CLEARED';
export const LOCATIONS_LISTENER_STARTED = 'LOCATIONS_LISTENER_STARTED';

// Rooms
export const ROOMS_FETCHED = 'ROOMS_FETCHED';
export const ROOM_ADDED = 'ROOM_ADDED';
export const ROOM_UPDATED = 'ROOM_UPDATED';
export const ROOM_SELECTED = 'ROOM_SELECTED';
export const ROOM_SELECTION_CLEARED = 'ROOM_SELECTION_CLEARED';
export const ROOMS_LISTENER_STARTED = 'ROOMS_LISTENER_STARTED';

// Staff
export const STAFF_FETCHED = 'STAFF_FETCHED';
export const STAFF_ADDED = 'STAFF_ADDED';
export const STAFF_UPDATED = 'STAFF_UPDATED';
export const STAFF_SELECTED = 'STAFF_SELECTED';
export const STAFF_SELECTION_CLEARED = 'STAFF_SELECTION_CLEARED';
export const STAFF_LISTENER_STARTED = 'STAFF_LISTENER_STARTED';

// Students
export const ALL_STUDENTS_FETCHED = 'ALL_STUDENTS_FETCHED';
export const STUDENTS_FETCHED = 'STUDENTS_FETCHED';
export const STUDENTS_LISTENER_STARTED = 'STUDENTS_LISTENER_STARTED';
export const STUDENT_ADDED = 'STUDENT_ADDED';
export const STUDENT_UPDATED = 'STUDENT_UPDATED';
export const STUDENT_SELECTED = 'STUDENT_SELECTED';
export const STUDENT_SELECTION_CLEARED = 'STUDENT_SELECTION_CLEARED';
export const STUDENT_FAMILY_MEMBER_SELECTED = 'STUDENT_FAMILY_MEMBER_SELECTED';
export const STUDENT_FAMILY_SELECTION_CLEARED =
  'STUDENT_FAMILY_SELECTION_CLEARED';
export const STUDENT_FAMILY_MEMBER_ADDED = 'STUDENT_FAMILY_MEMBER_ADDED';

// Layout
export const SLIDER_TOGGLED = 'SLIDER_TOGGLED';

// Devices
export const DEVICES_FETCHED = 'DEVICES_FETCHED';
export const DEVICE_REFRESHED = 'DEVICE_REFRESHED';
export const DEVICE_SET = 'DEVICE_SET';
export const DEVICE_ADDED = 'DEVICE_ADDED';
export const DEVICE_ADDED_TO_LIST = 'DEVICE_ADDED_TO_LIST';
export const DEVICE_REMOVE_FROM_LIST = 'DEVICE_REMOVE_FROM_LIST';
export const DEVICE_UPDATED = 'DEVICE_UPDATED';
export const DEVICE_DELETED = 'DEVICE_DELETED';
export const DEVICE_SELECTED = 'DEVICE_SELECTED';
export const DEVICE_SELECTION_CLEARED = 'DEVICE_SELECTION_CLEARED';
