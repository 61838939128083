import React, { Component } from 'react';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button, Modal, Segment, Header, Icon, Label } from 'semantic-ui-react';
import Avatar from 'react-avatar';

// Import components.
import StudentFamilyForm from '../Forms/Students/StudentFamilyForm';

// Import actions.
import { getRoleById } from '../../redux/actions/roleActions';
import {
  familyMemberSelected,
  familySelectionCleared,
} from '../../redux/actions/studentActions';

class FamilyList extends Component {
  state = { modalOpen: false };

  componentWillUnmount = () => {
    this.props.familySelectionCleared();
  };

  renderNoData = () => (
    <Segment basic clearing textAlign="center">
      <Header as={'h3'}>
        <Icon circular inverted name="info" color="grey" />
      </Header>
      <Header as={'h4'}>No family or emergency contact added.</Header>
      <p className="text-muted">
        You can add a parent, relative, guardian or emergency contact by
        clicking the add button.
      </p>
    </Segment>
  );

  renderTable = () => {
    const {
      selectedStudent: { family },
    } = this.props;

    const columns = [
      {
        dataField: 'id',
        text: 'id',
        hidden: true,
      },
      {
        text: '',
        dataField: 'avatar',
        formatter: (cell, row, rowIndex, formatExtraData) => (
          <Avatar
            email={!cell ? row.email || '' : ''}
            name={row.firstName || ''}
            round
            size={48}
            className="left floated"
            src={cell}
          />
        ),
        headerStyle: () => {
          return { width: '10%' };
        },
      },
      {
        dataField: 'name',
        formatter: (cell, row, rowIndex, formatExtraData) => (
          <div>
            <div
              className="family-member-name"
              style={{ fontSize: '14px', fontWeight: 'bolder' }}
            >
              {cell}
            </div>
            <div className="family-member-email">{row.email || ''}</div>
          </div>
        ),
        text: 'Name',
        sort: true,
        headerAlign: 'left',
        style: { verticalAlign: 'middle' },
      },
      {
        dataField: 'phone',
        text: 'Phone',
        headerAlign: 'left',
        style: { verticalAlign: 'middle' },
      },
      {
        dataField: 'relationship',
        text: 'Relationship',
        headerAlign: 'left',
        style: { verticalAlign: 'middle' },
      },
      {
        dataField: 'familyPin',
        text: 'PIN',
        headerAlign: 'left',
        style: { verticalAlign: 'middle' },
      },
      {
        dataField: 'enabled',
        text: 'Account status',
        headerAlign: 'left',
        style: { verticalAlign: 'middle' },
        formatter: (cell, row, rowIndex, formatExtraData) => (
          <Label
            content={cell === true ? 'Enabled' : 'Disabled'}
            color={cell === true ? 'green' : 'red'}
          />
        ),
      },
      {
        text: '',
        dataField: '',
        formatter: (cell, row, rowIndex, formatExtraData) => (
          <Button
            circular
            icon="edit outline"
            color="green"
            onClick={() => {
              if (row && row.id) this.props.familyMemberSelected(row.id);
              this.setState({ modalOpen: true });
            }}
          />
        ),
        style: { verticalAlign: 'middle' },
        align: 'right',
      },
    ];

    const data = family
      ? Object.keys(family).map(key => ({
          id: key,
          firstName: family[key].firstName,
          name: `${family[key].firstName} ${family[key].middleName || ''}  ${
            family[key].lastName
          }`,
          phone: family[key].phone,
          familyPin: family[key].familyPin,
          relationship: family[key].relationship
            ? getRoleById(family[key].relationship).name
            : '',
          email: family[key].email || '',
          avatar: family[key].photoURL || '',
          enabled: family[key].enabled,
          rowData: family[key],
        }))
      : [];

    return data.length ? (
      <BootstrapTable
        noDataIndication="no results to display"
        keyField="id"
        bordered={false}
        data={data}
        columns={columns}
      />
    ) : (
      this.renderNoData()
    );
  };

  render() {
    return (
      <div>
        <Modal
          open={this.state.modalOpen}
          onClose={() => {
            this.setState({ modalOpen: false });
          }}
          header="Edit Family Member or Emergency Contact"
          content={
            <StudentFamilyForm
              done={() => this.setState({ modalOpen: false })}
            />
          }
        />
        {this.renderTable()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedStudent: state.students.selectedStudent,
});

export default connect(
  mapStateToProps,
  { familyMemberSelected, familySelectionCleared }
)(FamilyList);
