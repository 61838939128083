import _ from 'lodash';
import {
  ALL_STUDENTS_FETCHED,
  STUDENTS_FETCHED,
  STUDENTS_LISTENER_STARTED,
  STUDENT_SELECTED,
  STUDENT_SELECTION_CLEARED,
  STUDENT_UPDATED,
  STUDENT_FAMILY_SELECTION_CLEARED,
  STUDENT_FAMILY_MEMBER_ADDED,
  STUDENT_FAMILY_MEMBER_SELECTED,
} from '../actions/types';

const initialState = {
  selectedStudent: {
    selectedFamilyMember: {},
    family: {},
  },
  count: 0,
  list: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case STUDENTS_LISTENER_STARTED:
      return { ...state };

    case STUDENTS_FETCHED: {
      let selectedStudent = state.selectedStudent || {};
      let selectedFamilyMember =
        state.selectedStudent.selectedFamilyMember || {};

      if (!_.isEmpty(selectedStudent)) {
        selectedStudent =
          _.find(action.students, { id: selectedStudent.id }) || {};

        if (!_.isEmpty(selectedFamilyMember)) {
          selectedFamilyMember =
            selectedStudent.family[selectedFamilyMember.uid];
          if (selectedFamilyMember)
            selectedStudent.selectedFamilyMember = selectedFamilyMember;
        }
      }

      return {
        ...state,
        count: action.count,
        list: _.orderBy(action.students, student =>
          student.displayName.toLowerCase()
        ),
        selectedStudent,
      };
    }

    case ALL_STUDENTS_FETCHED: {
      let selectedStudent = state.selectedStudent || {};
      let selectedFamilyMember = {};

      if (!_.isEmpty(selectedStudent)) {
        selectedStudent =
          _.find(action.students, { id: selectedStudent.id }) || {};

        if (!_.isEmpty(selectedFamilyMember)) {
          selectedFamilyMember =
            selectedStudent.family[selectedFamilyMember.uid];
          if (selectedFamilyMember)
            selectedStudent.selectedFamilyMember = selectedFamilyMember;
        }
      }

      return {
        ...state,
        count: action.count,
        list: _.orderBy(
          action.students.data,
          student => student.updatedAt,
          'desc'
        ),
        selectedStudent,
      };
    }

    case STUDENT_SELECTED:
      return { ...state, selectedStudent: action.student };

    // Replace selected with the newly updated data.
    // New data is fetched by the listener as soon as listener receives the update.
    case STUDENT_UPDATED: {
      return {
        ...state,
        selectedStudent: _.find(state.list, { id: state.selectedStudent.id }),
      };
    }

    case STUDENT_SELECTION_CLEARED:
      return { ...state, selectedStudent: {} };

    case STUDENT_FAMILY_MEMBER_ADDED: {
      // Temporarily add form data until listener receives data.
      if (action.memberData.uid) {
        const studentObject = _.find(state.list, {
          id: action.studentId,
        });

        studentObject.family = {
          ...studentObject.family,
          [action.memberData.uid]: { ...action.memberData },
        };

        const studentIndex = state.list.findIndex(
          student => student.id === action.studentId
        );

        const list = [
          ...state.list.slice(0, studentIndex),
          studentObject,
          ...state.list.slice(studentIndex + 1),
        ];

        return {
          ...state,
          list,
          selectedStudent: {
            ...state.selectedStudent,
            family: {
              ...state.selectedStudent.family,
              [action.memberData.uid]: {
                uid: action.memberData.uid,
                ...action.memberData,
              },
            },
          },
        };
      }
      return state;
    }

    case STUDENT_FAMILY_MEMBER_SELECTED:
      return {
        ...state,
        selectedStudent: {
          ...state.selectedStudent,
          selectedFamilyMember:
            state.selectedStudent.family[action.familyMemberId],
        },
      };

    case STUDENT_FAMILY_SELECTION_CLEARED:
      return {
        ...state,
        selectedStudent: {
          ...state.selectedStudent,
          selectedFamilyMember: {},
        },
      };

    default:
      return state;
  }
};
