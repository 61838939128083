import _ from 'lodash';

import {
  ROOMS_FETCHED,
  ROOMS_LISTENER_STARTED,
  ROOM_ADDED,
  ROOM_SELECTED,
  ROOM_SELECTION_CLEARED,
  ROOM_UPDATED,
} from './types';

import {
  organizationRoomsOnListen,
  addOrganizationRoom,
  updateOrganizationRoom,
} from '../../api/firebase/rooms';

import store from '../store';

export const roomsListenerStarted = () => ({
  type: ROOMS_LISTENER_STARTED,
});

export const roomsFetched = ({ count, rooms }) => ({
  type: ROOMS_FETCHED,
  count,
  rooms,
});

export const roomAdded = room => ({
  type: ROOM_ADDED,
  room,
});

export const roomUpdated = () => ({
  type: ROOM_UPDATED,
});

export const roomSelected = room => ({
  type: ROOM_SELECTED,
  room,
});

export const roomSelectionCleared = () => ({
  type: ROOM_SELECTION_CLEARED,
});

export const getRoomById = id => {
  if (!id) return null;
  const rooms = store.getState().rooms.list || [];
  if (rooms) return _.find(rooms, { id });
};

export const getRooms = () => {
  const rooms = store.getState().rooms.list;
  if (_.isEmpty(rooms)) return null;
  return rooms || null;
};

export const startOrganizationRoomsListener = organizationId => dispatch => {
  return organizationRoomsOnListen(
    organizationId,
    data => {
      dispatch(roomsListenerStarted());
      if (data) dispatch(roomsFetched(data));
    },
    error => console.log(error.message)
  );
};

export const organizationAddRoom = (organizationId, roomData) => dispatch =>
  addOrganizationRoom(organizationId, roomData).then(location =>
    dispatch(roomAdded(location))
  );

export const organizationUpdateRoom = (organizationId, roomData) => dispatch =>
  updateOrganizationRoom(organizationId, roomData).then(location =>
    dispatch(roomUpdated())
  );
