import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Segment } from 'semantic-ui-react';
import _ from 'lodash';

// Import components
import InlineError from '../Messages/InlineError';
import ShowError from '../Messages/ShowError';
import LocationPicker from '../Locations/LocationPicker';

// Import actions
import {
  organizationAddRoom,
  organizationUpdateRoom,
  roomSelectionCleared,
} from '../../redux/actions/roomActions';
import { updateSetupStatus } from '../../api/firebase/setup';

class RoomForm extends Component {
  state = {
    data: {
      id: '',
      name: '',
      location: '',
      enabledActivities: {},
      ratio: '',
    },
    loading: false,
    errors: {},
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      rooms: { selectedRoom },
    } = nextProps;

    if (!_.isEmpty(selectedRoom.id) && prevState.data.id !== selectedRoom.id) {
      return {
        data: { ...selectedRoom },
      };
    }

    return prevState;
  }

  componentWillUnmount() {
    // Clear selection from store.
    this.props.roomSelectionCleared();
  }

  onChange = (e, { name, value }) => {
    this.setState({
      data: { ...this.state.data, [name]: value },
      errors: _.omit(this.state.errors, name),
    });
  };

  validate = data => {
    const errors = {};

    if (!data.name) errors.name = 'Name is required';
    if (!data.location) errors.location = 'Location is required';

    return errors;
  };

  onSubmit = e => {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });

    if (_.isEmpty(errors)) {
      const {
        data: { id, ...rest },
      } = this.state;
      const { currentOrganization } = this.props;

      this.setState({ loading: true });

      if (currentOrganization && currentOrganization.id)
        if (id) {
          // Update.
          this.props
            .organizationUpdateRoom(currentOrganization.id, {
              id,
              organization: currentOrganization.id,
              ...rest,
            })
            .then(() => {
              // Used to close wrapper HOC. e.g Sliding panel, modal...
              if (this.props.onClose) {
                this.props.onClose('updated');
                return;
              }
              this.setState({ loading: false });
            })
            .catch(error =>
              this.setState({
                loading: false,
                errors: { 'Unable to Update': error.message },
              })
            );
        } else {
          // New entry.
          this.props
            .organizationAddRoom(currentOrganization.id, {
              organization: currentOrganization.id,
              ...rest,
            })
            .then(() => {
              // Update setup flags.
              updateSetupStatus(currentOrganization.id, 'firstRoomCreated');

              // Used to close wrapper HOC. e.g Sliding panel, modal...
              if (this.props.onClose) {
                this.props.onClose('added');
                return;
              }
              this.setState({ loading: false });
            })
            .catch(error =>
              this.setState({
                loading: false,
                errors: { 'Unable to Add': error.message },
              })
            );
        }
    }
  };

  render() {
    const { data, loading, errors } = this.state;

    return (
      <Segment basic textAlign="left">
        <Form
          id="room-form"
          onSubmit={this.onSubmit}
          loading={loading}
          noValidate
        >
          <ShowError errors={errors} />
          <Form.Field error={!!errors.name}>
            <Form.Input
              required
              type="text"
              id="name"
              name="name"
              label="Name"
              placeholder="Room name"
              value={data.name}
              onChange={this.onChange}
            />
            {errors.name && <InlineError text={errors.name} />}
          </Form.Field>

          <Form.Field error={!!errors.location}>
            <Form.Field
              required
              id="location"
              name="location"
              label="Location"
              control={LocationPicker}
              placeholder="Select location"
              value={data.location}
              selection
              search
              onChange={this.onChange}
            />
            {errors.location && <InlineError text={errors.location} />}
          </Form.Field>

          <Form.Field error={!!errors.ratio}>
            <Form.Input
              type="text"
              id="ratio"
              name="ratio"
              label="Student ratio for one teacher"
              placeholder="4"
              value={data.ratio}
              onChange={this.onChange}
            />
            {errors.ratio && <InlineError text={errors.ratio} />}
          </Form.Field>

          <Form.Group>
            <Form.Button primary content={data.uid ? 'Update' : 'Save'} />
            <Form.Button
              basic
              content="Cancel"
              onClick={e => {
                if (e) e.preventDefault();
                if (this.props.onClose) this.props.onClose();
              }}
            />
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

RoomForm.propTypes = {
  currentOrganization: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  rooms: PropTypes.shape({
    selectedRoom: PropTypes.shape({
      id: PropTypes.string,
    }),
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
  }),
  organizationAddRoom: PropTypes.func.isRequired,
  organizationUpdateRoom: PropTypes.func.isRequired,
  roomSelectionCleared: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  currentOrganization: state.organizations.currentOrganization,
  rooms: state.rooms,
});

export default connect(
  mapStateToProps,
  {
    organizationAddRoom,
    organizationUpdateRoom,
    roomSelectionCleared,
  }
)(RoomForm);
