import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Segment } from 'semantic-ui-react';
import _ from 'lodash';
import Validator from 'validator';
import MaskedInput from 'react-text-mask';
import DatePicker from 'react-datepicker';
import moment from 'moment';

// Import components
import InlineError from '../Messages/InlineError';
import ShowErrors from '../Messages/ShowError';
import LocationPicker from '../Locations/LocationPicker';
import RoomPicker from '../Rooms/RoomPicker';
import SalutationPicker from '../Shared/SalutationPicker';
import LanguagePicker from '../Shared/LanguagePicker';
import StaffPicker from '../Staff/StaffTypePicker';
import withPermission from '../Shared/withPermission';

// Import utils
import { phoneNumberFormat, phoneNumberParse } from '../../helpers/utils';

// Import actions
import {
  organizationAddStaff,
  organizationUpdateStaff,
  staffSelectionCleared,
} from '../../redux/actions/staffActions';
import { updateSetupStatus } from '../../api/firebase/setup';

const SaveEditButton = props => <Form.Button {...props} />;

const RestrictedSaveButton = withPermission(SaveEditButton, 'can_create_staff');
const RestrictedEditButton = withPermission(SaveEditButton, 'can_edit_staff');

class StaffForm extends Component {
  state = {
    data: {
      uid: '',
      salutation: '',
      firstName: '',
      middleName: '',
      lastName: '',
      phone: '',
      email: '',
      location: '',
      locationName: '',
      rooms: [],
      hireDate: null,
      language: '',
      staff: true,
      allowStaffLogin: true,
      staffType: [],
      enabled: true,
    },
    loading: false,
    errors: {},
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      staff: { selectedStaff },
    } = nextProps;

    if (
      !_.isEmpty(selectedStaff.uid) &&
      prevState.data.uid !== selectedStaff.uid
    ) {
      const {
        phone,
        defaultLocation,
        locations,
        defaultRoom,
        rooms,
        hireDate,
        ...rest
      } = selectedStaff;
      return {
        data: {
          hireDate: hireDate ? moment(`${hireDate}`, 'x') : null,
          phone: phoneNumberParse(phone),
          location: defaultLocation,
          rooms: rooms ? Object.keys(rooms).map(key => key) : [],
          ...rest,
        },
      };
    }

    return prevState;
  }

  componentWillUnmount() {
    // Clear selection from store.
    this.props.staffSelectionCleared();
  }

  onChange = (e, { name, value, checked }) => {
    this.setState(
      {
        data: {
          ...this.state.data,
          [name]: !_.isUndefined(checked) ? checked : value,
        },
        errors: _.omit(this.state.errors, name),
      },
      () => {
        // Clear selected rooms whenever new location is selected
        if (name === 'location')
          this.setState({ data: { ...this.state.data, rooms: [] } });
      }
    );
  };

  maskedOnChange = e => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
      errors: _.omit(this.state.errors, e.target.name),
    });
  };

  handleHireDateChange = hireDate => {
    this.setState({
      data: {
        ...this.state.data,
        hireDate,
      },
      errors: _.omit(this.state.errors, hireDate),
    });
  };

  validate = data => {
    const errors = {};

    if (!data.salutation) errors.salutation = 'Salutation is required';
    if (!data.firstName) errors.firstName = 'First Name is required';
    if (!data.lastName) errors.lastName = 'Last Name is required';
    if (_.isEmpty(data.staffType)) errors.staffType = 'Staff type is required';

    if (data.phone)
      if (!Validator.isMobilePhone(data.phone, 'en-US'))
        errors.phone = 'Phone is invalid';

    if (!data.allowStaffLogin && data.email)
      if (!Validator.isEmail(data.email)) errors.email = 'Invalid email';

    if (data.allowStaffLogin && !data.email) errors.email = 'Email is required';

    if (data.allowStaffLogin && data.email)
      if (!Validator.isEmail(data.email)) errors.email = 'Invalid email';

    return errors;
  };

  onSubmit = e => {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });

    if (_.isEmpty(errors)) {
      const {
        data: {
          uid,
          phone,
          location,
          organizations,
          defaultOrganization,
          rooms,
          hireDate,
          ...rest
        },
      } = this.state;
      const { currentOrganization } = this.props;

      // Add rooms object
      const selectedRooms = {};
      if (rooms && rooms.length)
        rooms.forEach(room => (selectedRooms[room] = true));

      this.setState({ loading: true });
      const formData = {
        hireDate: hireDate ? hireDate.utc().valueOf() : null,
        displayName: `${rest.firstName} ${rest.lastName}`,
        phone: phoneNumberFormat(phone),
        currentOrganization: currentOrganization.id,
        defaultLocation: location || '',
        locations: location ? { [location]: true } : {},
        rooms: selectedRooms,
        ...rest,
      };

      if (currentOrganization && currentOrganization.id)
        if (uid) {
          // Update.
          this.props
            .organizationUpdateStaff(currentOrganization.id, {
              uid,
              ...formData,
            })
            .then(() => {
              // Used to close wrapper HOC. e.g Sliding panel, modal...
              if (this.props.onClose) {
                this.props.onClose('updated');
                return;
              }
              this.setState({ loading: false });
            })
            .catch(error =>
              this.setState({
                loading: false,
                errors: { 'Unable to Update': error.message },
              })
            );
        } else {
          // New entry.
          this.props
            .organizationAddStaff(formData)
            .then(() => {
              // Update setup flags.
              updateSetupStatus(currentOrganization.id, 'firstStaffCreated');

              // Used to close wrapper HOC. e.g Sliding panel, modal...
              if (this.props.onClose) {
                this.props.onClose('added');
                return;
              }
              this.setState({ loading: false });
            })
            .catch(error =>
              this.setState({
                loading: false,
                errors: { 'Unable to Add': error.message },
              })
            );
        }
    }
  };

  render() {
    const { data, loading, errors } = this.state;

    return (
      <Segment basic textAlign="left">
        <ShowErrors errors={errors} />

        <Form
          id="staff-form"
          onSubmit={this.onSubmit}
          loading={loading}
          noValidate
        >
          <Form.Group widths={3}>
            <Form.Field error={!!errors.salutation}>
              <Form.Field
                required
                id="salutation"
                name="salutation"
                label="Salutation"
                control={SalutationPicker}
                placeholder="Salutation"
                value={data.salutation}
                selection
                search
                onChange={this.onChange}
              />
              {errors.salutation && <InlineError text={errors.salutation} />}
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field error={!!errors.firstName}>
              <Form.Input
                required
                type="text"
                id="firstName"
                name="firstName"
                value={data.firstName}
                onChange={this.onChange}
                label="First Name"
                placeholder="First Name"
              />
              {errors.firstName && <InlineError text={errors.firstName} />}
            </Form.Field>

            <Form.Field error={!!errors.middleName}>
              <Form.Input
                type="text"
                id="middleName"
                name="middleName"
                value={data.middleName}
                onChange={this.onChange}
                label="Middle Name"
                placeholder="Middle Name"
              />
              {errors.middleName && <InlineError text={errors.middleName} />}
            </Form.Field>
          </Form.Group>

          <Form.Field error={!!errors.lastName}>
            <Form.Input
              required
              type="text"
              id="lastName"
              name="lastName"
              value={data.lastName}
              onChange={this.onChange}
              label="Last Name"
              placeholder="Last Name"
            />
            {errors.lastName && <InlineError text={errors.lastName} />}
          </Form.Field>

          <Form.Group widths="equal">
            <Form.Field error={!!errors.email}>
              <Form.Input
                required={data.allowStaffLogin}
                type="text"
                id="email"
                name="email"
                value={
                  data.email && data.email.indexOf('moxit_') !== -1
                    ? ''
                    : data.email
                }
                onChange={this.onChange}
                label="Email"
                placeholder="Email"
              />
              {errors.email && <InlineError text={errors.email} />}
            </Form.Field>

            <Form.Field error={!!errors.phone}>
              <Form.Input
                type="text"
                id="phone"
                name="phone"
                onChange={this.maskedOnChange}
                label="Cell Phone"
                control={MaskedInput}
                mask={[
                  '(',
                  /[1-9]/,
                  /\d/,
                  /\d/,
                  ')',
                  ' ',
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                guide={false}
                value={data.phone}
                placeholder="(123) 456-7890"
              />
              {errors.phone && <InlineError text={errors.phone} />}
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <LocationPicker
              error={!!errors.location}
              id="location"
              name="location"
              label="Location"
              placeholder="Select location"
              value={data.location}
              selection
              search
              onChange={this.onChange}
            >
              {errors.location && <InlineError text={errors.location} />}
            </LocationPicker>
            <RoomPicker
              error={!!errors.rooms}
              id="rooms"
              name="rooms"
              label="Rooms"
              placeholder="Select rooms"
              value={data.rooms}
              location={data.location}
              dependent
              multiple
              selection
              search
              noResultsMessage={
                !data.location ? 'Select location first' : 'No room foun'
              }
              onChange={this.onChange}
            >
              {errors.rooms && <InlineError text={errors.rooms} />}
            </RoomPicker>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field error={!!errors.language}>
              <Form.Field
                id="language"
                name="language"
                label="Language"
                control={LanguagePicker}
                placeholder="Language"
                value={data.language}
                selection
                search
                onChange={this.onChange}
              />
              {errors.locationType && (
                <InlineError text={errors.locationType} />
              )}
            </Form.Field>

            <Form.Field
              fluid
              isClearable
              id="hireDate"
              name="hireDate"
              label="Hire Date"
              placeholder="Select Hire Date"
              dateFormat="YYYY-MM-DD"
              maxDate={moment()}
              control={DatePicker}
              selected={data.hireDate}
              onChange={this.handleHireDateChange}
              error={!!errors.hireDate}
            >
              {errors.hireDate && <InlineError text={errors.hireDate} />}
            </Form.Field>
          </Form.Group>

          <StaffPicker
            required
            id="staffType"
            name="staffType"
            label="Staff Type"
            placeholder="Staff Type"
            value={data.staffType}
            selection
            multiple
            search
            onChange={this.onChange}
            error={!!errors.staffType}
          >
            {errors.staffType && <InlineError text={errors.staffType} />}
          </StaffPicker>

          <Form.Field error={!!errors.allowStaffLogin}>
            <label>Allow user to login using PIN code?</label>
            <Form.Radio
              toggle
              id="allowStaffLogin"
              name="allowStaffLogin"
              onChange={this.onChange}
              checked={data.allowStaffLogin}
            />
            {errors.allowStaffLogin && (
              <InlineError text={errors.allowStaffLogin} />
            )}
          </Form.Field>

          <Form.Field error={!!errors.enabled}>
            <label>Account enabled?</label>
            <Form.Radio
              toggle
              id="enabled"
              name="enabled"
              onChange={this.onChange}
              checked={data.enabled}
            />
            {errors.enabled && <InlineError text={errors.enabled} />}
          </Form.Field>

          <br />
          <br />
          <Form.Group>
            {data.uid && <RestrictedEditButton primary content="Update" />}
            {!data.uid && <RestrictedSaveButton primary content="Save" />}
            {/* <Form.Button primary content={data.uid ? 'Update' : 'Save'} /> */}
            <Form.Button
              basic
              content="Cancel"
              onClick={e => {
                if (e) e.preventDefault();
                if (this.props.onClose) this.props.onClose();
              }}
            />
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

StaffForm.propTypes = {
  currentOrganization: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  staff: PropTypes.shape({
    selectedStaff: PropTypes.shape({
      id: PropTypes.string,
    }),
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
  }),
  organizationAddStaff: PropTypes.func.isRequired,
  organizationUpdateStaff: PropTypes.func.isRequired,
  staffSelectionCleared: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  currentOrganization: state.organizations.currentOrganization,
  staff: state.staff,
});

export default connect(
  mapStateToProps,
  {
    organizationAddStaff,
    organizationUpdateStaff,
    staffSelectionCleared,
  }
)(StaffForm);
