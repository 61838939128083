import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import {
  Message,
  Segment,
  Icon,
  Step,
  Grid,
  Loader,
  Dimmer,
  Button,
} from 'semantic-ui-react';
import _ from 'lodash';

// Import components.
import SetupRooms from './SetupRooms';
import SetupLocations from './SetupLocations';
import SetupStaff from './SetupStaff';
import { getCurrentUser } from '../../api/firebase/account';
import SetupStudents from './SetupStudents';

// Import actions
import { startOrganizationRoomsListener } from '../../redux/actions/roomActions';
import { startOrganizationStaffListener } from '../../redux/actions/staffActions';
import { startOrganizationLocationsListener } from '../../redux/actions/locationActions';
import { startOrganizationStudentsListener } from '../../redux/actions/studentActions';

const steps = [
  {
    key: 'firstLocationCreated',
    title: 'Locations',
    description: 'Add your locations',
  },
  { key: 'firstRoomCreated', title: 'Rooms', description: 'Add your rooms' },
  { key: 'firstStaffCreated', title: 'Staff', description: 'Add your staff' },
  {
    key: 'firstStudentCreated',
    title: 'Students',
    description: 'Add your students',
  },
];

class Setup extends Component {
  state = { activeStep: '', currentOrganizationId: '' };

  componentDidMount = () => {
    this.startListeners();
    this.selectNextStep();
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      currentOrganization: { id, setupComplete },
    } = this.props;

    if (
      prevProps.currentOrganization.id !== id ||
      prevProps.currentOrganization.setupComplete !== setupComplete
    ) {
      // this.checkIfSetupComplete(prevProps);
      this.selectNextStep();
      this.startListeners();
    }
  }

  componentWillUnmount = () => {
    this.unsubscribeListeners();
  };

  startListeners = () => {
    const {
      currentOrganization: { id, setupComplete },
    } = this.props;

    if (this.isSetupRoute() && id && setupComplete === false) {
      this.unSubscribeOrganizationStaffListener = this.props.startOrganizationStaffListener(
        id
      );

      this.unSubscribeOrganizationRoomsListener = this.props.startOrganizationRoomsListener(
        id
      );

      this.unSubscribeOrganizationLocationsListener = this.props.startOrganizationLocationsListener(
        id
      );
      this.unSubscribeOrganizationStudentsListener = this.props.startOrganizationStudentsListener(
        id
      );
    }
  };

  unsubscribeListeners = () => {
    if (this.unSubscribeOrganizationRoomsListener)
      this.unSubscribeOrganizationRoomsListener();
    if (this.unSubscribeOrganizationLocationsListener)
      this.unSubscribeOrganizationLocationsListener();
    if (this.unSubscribeOrganizationStaffListener)
      this.unSubscribeOrganizationStaffListener();
    if (this.unSubscribeOrganizationStudentsListener)
      this.unSubscribeOrganizationStudentsListener();
  };

  selectNextStep = () => {
    const { currentOrganization } = this.props;
    const { activeStep } = this.state;

    if (
      !currentOrganization ||
      !currentOrganization.setup ||
      currentOrganization.setupComplete
    )
      return;

    const firstIncompleteStep = _.findKey(
      currentOrganization.setup,
      item => item === false
    );

    if (!firstIncompleteStep) return;
    const nextStep = _.find(steps, { key: firstIncompleteStep }).title;

    if (_.isEmpty(activeStep)) {
      this.setState({ activeStep: nextStep });
    }
  };

  // Check if setup is complete and redirect to dashboard.
  checkIfSetupComplete = prevProps => {
    const { currentOrganization, history } = this.props;
    if (currentOrganization && currentOrganization.setupComplete) {
      if (
        this.isSetupRoute() &&
        prevProps.currentOrganization &&
        prevProps.currentOrganization.setupComplete !==
          currentOrganization.setupComplete
      )
        history.push('/');
    }

    this.selectNextStep();
  };

  isSetupRoute = () => {
    const { match } = this.props;
    return match.path === '/setup';
  };

  renderSetupStatusMessage() {
    const { currentOrganization } = this.props;
    if (this.isSetupRoute()) return null;
    if (!currentOrganization.setup) return null;
    if (currentOrganization && !currentOrganization.setupComplete)
      return (
        <Message info size="big">
          <Icon name="info" />
          Please complete setting up your account by going to the{' '}
          <strong>
            <em>
              <NavLink to="/setup">setup</NavLink>
            </em>
          </strong>{' '}
          page.
        </Message>
      );
  }

  handleStepClick = (e, { title }) => this.setState({ activeStep: title });

  renderCheckList = () => {
    const { activeStep } = this.state;
    const { currentOrganization } = this.props;
    if (!this.isSetupRoute()) return;
    if (!currentOrganization.setupComplete)
      return (
        <Segment basic className="setup-step">
          <Step.Group vertical size="mini">
            {steps.map(step => (
              <Step
                key={step.key}
                link
                onClick={this.handleStepClick}
                title={step.title}
                // description={step.description}
                active={activeStep === step.title}
                icon={{ name: 'circle outline' }}
                completed={
                  !currentOrganization.setup
                    ? false
                    : currentOrganization.setup[step.key]
                }
              />
            ))}
          </Step.Group>
        </Segment>
      );
  };

  renderStepContent = () => {
    const { activeStep } = this.state;
    if (!this.isSetupRoute()) return;

    switch (activeStep.toLocaleLowerCase()) {
      case 'locations':
        return <SetupLocations />;
      case 'rooms':
        return <SetupRooms />;
      case 'staff':
        return <SetupStaff />;
      case 'students':
        return <SetupStudents />;
      default:
        return <div>{activeStep}</div>;
    }
  };

  /**
   * This loader will be displayed only during first organization creation.
   * Sometimes cloud triggers are slow so this is used as a visual queue.
   */
  renderFirstTimeOrganizationLoader = () => {
    const {
      currentOrganization: { setup },
    } = this.props;

    if (_.isEmpty(setup) && this.isSetupRoute())
      return (
        <Dimmer page active inverted>
          <Loader
            inline="centered"
            indeterminate
            content="Just a moment while we setup your organization..."
          />
        </Dimmer>
      );
  };

  renderWelcomeMessage = () => {
    const {
      currentOrganization: { setupComplete },
      history,
    } = this.props;
    if (setupComplete)
      return (
        <Segment basic textAlign="center">
          <h3>Setup Complete!</h3>
          <Button
            size="huge"
            content="Go to your Dashboard"
            color="green"
            icon="flag checkered"
            onClick={() => {
              history.replace('/');
            }}
          />
        </Segment>
      );
  };

  renderSetupGrids = () => {
    const {
      currentOrganization: { setupComplete },
    } = this.props;

    if (setupComplete === false)
      return (
        <Grid columns="equal">
          <Grid.Column width={3}>{this.renderCheckList()}</Grid.Column>
          <Grid.Column>{this.renderStepContent()}</Grid.Column>
        </Grid>
      );
  };

  render() {
    return getCurrentUser() ? (
      <Segment basic>
        {this.renderFirstTimeOrganizationLoader()}
        {this.renderSetupStatusMessage()}
        {this.renderWelcomeMessage()}
        {this.renderSetupGrids()}
      </Segment>
    ) : null;
  }
}

Setup.defaultProps = {
  currentOrganization: {
    id: '',
    setup: {
      firstLocationCreated: false,
      firstRoomCreated: false,
      firstStaffCreated: false,
      firstStudentCreated: false,
    },
    setupComplete: false,
  },
};

Setup.propTypes = {
  currentOrganization: PropTypes.shape({
    id: PropTypes.string,
    setup: PropTypes.shape({
      firstLocationCreated: PropTypes.bool,
      firstRoomCreated: PropTypes.bool,
      firstStaffCreated: PropTypes.bool,
      firstStudentCreated: PropTypes.bool,
    }),
    setupComplete: PropTypes.bool,
  }),
  startOrganizationLocationsListener: PropTypes.func.isRequired,
  startOrganizationRoomsListener: PropTypes.func.isRequired,
  startOrganizationStaffListener: PropTypes.func.isRequired,
  startOrganizationStudentsListener: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  currentOrganization: state.organizations.currentOrganization,
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      startOrganizationLocationsListener,
      startOrganizationRoomsListener,
      startOrganizationStaffListener,
      startOrganizationStudentsListener,
    }
  )(Setup)
);
