import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Segment,
  Icon,
  Header,
  Button,
  Loader,
  Dimmer,
} from 'semantic-ui-react';

// Import hoc
import withSlidingPanel from '../Shared/withSlidingPanel';

// Import components
import StudentPersonalInformationForm from '../Forms/Students/StudentPersonalInformationForm';
import StudentList from '../Students/StudentList';

import { newOrganizationStudent } from '../../api/firebase/students';

// Import actions
import { studentSelected } from '../../redux/actions/studentActions';

const SlidingStudentsForm = withSlidingPanel(StudentPersonalInformationForm, {
  title: 'Add New Student',
  width: '70%',
});

class SetupStudents extends Component {
  state = { isOpen: false, added: false };

  renderAddBlock = () => {
    const {
      currentOrganization,
      students: { list },
    } = this.props;

    if (list.length) return null;

    return (
      <Segment basic clearing textAlign="center">
        <Segment basic>
          <Icon name="group" size="huge" color="red" />
          <Header as={'h2'}>Add Your Students</Header>
          <p>Add your student roster.</p>
        </Segment>
        <Segment basic>
          <SlidingStudentsForm
            isOpen={this.state.isOpen}
            onClose={status => {
              this.setState({ isOpen: false, added: status === 'added' });
            }}
          />
          <Button
            content="Add Students"
            primary
            onClick={() => {
              if (currentOrganization && currentOrganization.id) {
                const newStudent = newOrganizationStudent(
                  currentOrganization.id
                );
                // Initialize new student's id. The student id is useful to upload to firebase storage.
                this.props.studentSelected({
                  id: newStudent.id,
                });
              }

              this.setState({ isOpen: true });
            }}
          />
        </Segment>
      </Segment>
    );
  };

  /**
   * This loader will be displayed only during first student creation.
   * Sometimes cloud triggers are slow so this is used as a visual queue.
   */
  renderFirstTimeLoader = () => {
    const {
      currentOrganization: { setup },
    } = this.props;

    if (this.state.added && !setup.firstStudentCreated)
      return (
        <Dimmer active inverted>
          <Loader
            inline="centered"
            indeterminate
            content="Just a moment while we add your first student..."
          />
        </Dimmer>
      );
  };

  renderListBlock = () => {
    const {
      students: { list },
    } = this.props;

    if (!list.length) return null;
    return <StudentList />;
  };
  render() {
    return (
      <Segment basic>
        {/* {this.renderFirstTimeLoader()} */}
        {this.renderAddBlock()}
        {this.renderListBlock()}
      </Segment>
    );
  }
}

SetupStudents.propTypes = {};

const mapStateToProps = state => ({
  currentOrganization: state.organizations.currentOrganization,
  students: state.students,
});
export default connect(
  mapStateToProps,
  { studentSelected }
)(SetupStudents);
