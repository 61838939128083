// import types.
import {
  USER_SIGNED_IN,
  USER_AUTHENTICATION_FAILED,
  USER_AUTHENTICATED,
  USER_AUTHENTICATING,
  USER_SIGNING_OUT,
  USER_SIGNED_OUT,
  USER_PROFILE_FETCHED,
  USER_SIGNED_UP,
  USER_PROFILE_UPDATED,
  USER_TOKEN_REFRESHED,
  ORGANIZATION_PROVISIONING_STARTED,
  ORGANIZATION_PROVISIONING_COMPLETE,
  USER_EMAIL_VERIFICATION_LINK_SENT,
  USER_EMAIL_VERIFIED,
  ORGANIZATION_SELECTED,
  USER_PROFILE_LISTENER_STARTED,
  USER_RESET_CODE_VERIFIED,
  USER_PASSWORD_RESET_CONFIRMED,
  USER_PASSWORD_RESET_SENT,
  USER_PIN_UPDATED,
} from '../actions/types';

// Import API
import {
  getUserProfile,
  userOnListen,
  updateOrganizationUser,
  updateOrganizationUserPin,
} from '../../api/firebase/users';

import {
  signInWithEmail,
  signUpWithEmail,
  startProvisioning,
  sendEmailVerificationLink,
  userForceTokenRefresh,
  verifyEmail,
  authOnListen,
  signOutUser,
  verifyPasswordResetCode,
  confirmPasswordReset,
  sendPasswordResetEmail,
} from '../../api/firebase/account';
import { assignPin } from '../../api/firebase/tabletPins';

export const userSignedIn = (
  { uid, email, displayName, phoneNumber, photoURL },
  claims
) => ({
  type: USER_SIGNED_IN,
  user: {
    uid,
    email,
    displayName,
    phoneNumber,
    photoURL,
    claims,
  },
});

export const userSigningOut = () => ({
  type: USER_SIGNING_OUT,
});

export const userSignedOut = () => ({
  type: USER_SIGNED_OUT,
});

export const userAuthenticating = () => ({
  type: USER_AUTHENTICATING,
});

export const userAuthenticationFailed = () => ({
  type: USER_AUTHENTICATION_FAILED,
});

export const userAuthenticated = () => ({
  type: USER_AUTHENTICATED,
});

export const userProfileFetched = user => ({
  type: USER_PROFILE_FETCHED,
  user,
});

export const userProfileUpdated = user => ({
  type: USER_PROFILE_UPDATED,
  user,
});

export const userSignedUp = () => ({
  type: USER_SIGNED_UP,
});

export const userTokenRefreshed = () => ({
  type: USER_TOKEN_REFRESHED,
});

export const organizationProvisioningStarted = () => ({
  type: ORGANIZATION_PROVISIONING_STARTED,
});

export const organizationProvisioningComplete = () => ({
  type: ORGANIZATION_PROVISIONING_COMPLETE,
});

export const emailVerificationEmailSent = () => ({
  type: USER_EMAIL_VERIFICATION_LINK_SENT,
});

export const emailVerified = () => ({
  type: USER_EMAIL_VERIFIED,
});

export const resetCodeVerified = () => ({
  type: USER_RESET_CODE_VERIFIED,
});

export const passwordResetConfirmed = () => ({
  type: USER_PASSWORD_RESET_CONFIRMED,
});

export const resetPasswordSent = () => ({
  type: USER_PASSWORD_RESET_SENT,
});

export const userOrganizationSelected = organization => ({
  type: ORGANIZATION_SELECTED,
  organization,
});

export const userProfileListenerStarted = () => ({
  type: USER_PROFILE_LISTENER_STARTED,
});

export const signOut = () => dispatch => {
  dispatch(userSigningOut());
  signOutUser().then(() => dispatch(userSignedOut()));
};

export const userPinUpdated = () => ({
  type: USER_PIN_UPDATED,
});

export const userDataUpdated = () => ({
  type: USER_PROFILE_UPDATED,
});

export const addAuthListener = () => dispatch => {
  dispatch(userAuthenticating());
  return authOnListen(
    user => {
      if (user && user.uid) {
        user.getIdTokenResult().then(idTokenResult => {
          dispatch(userSignedIn(user, idTokenResult.claims));
          if (process.env.NODE_ENV === 'development') {
            console.log('++++++++++++++++ Moxit Dev ******************');
            console.log({
              moxitAdmin: !!idTokenResult.claims.moxitAdmin,
              organizationAdmin: !!idTokenResult.claims.organizationAdmin,
              organizationDevice: !!idTokenResult.claims.organizationDevice,
              locationAdmin: !!idTokenResult.claims.locationAdmin,
              supportStaff: !!idTokenResult.claims.supportStaff,
              teacher: !!idTokenResult.claims.teacher,
              parent: !!idTokenResult.claims.parent,
            });
            console.log('++++++++++++++++ Moxit Dev ******************');
          }
        });

        // dispatch(userSignedIn(user));
        dispatch(userAuthenticated());
      } else {
        dispatch(userAuthenticationFailed());
      }
    },
    error => console.log(error.message)
  );
};

export const startUserProfileListener = uid => dispatch => {
  dispatch(userProfileListenerStarted());
  return userOnListen(
    uid,
    user => {
      if (user) {
        dispatch(userProfileFetched(user));
        if (user.defaultOrganization)
          dispatch(
            userOrganizationSelected({
              id: user.defaultOrganization,
            })
          );
      }
    },
    error => console.log(error.message)
  );
};

export const signUpWithEmailAndPassword = (
  userData,
  signup = false
) => dispatch =>
  signUpWithEmail(userData).then(() => {
    if (signup) {
      // Only during first organization user signup
      dispatch(provisionOrganizationProfile(userData));
      dispatch(sendEmailVerifyLink());
    }
    return dispatch(userSignedUp());
  });

export const sendEmailVerifyLink = () => dispatch =>
  sendEmailVerificationLink().then(() =>
    dispatch(emailVerificationEmailSent())
  );

export const verifyUserEmail = actionCode => dispatch =>
  verifyEmail(actionCode).then(() => {
    // force id token refresh after verification.
    userForceTokenRefresh();
    return dispatch(emailVerified());
  });

export const verifyResetCode = actionCode => dispatch =>
  verifyPasswordResetCode(actionCode).then(email => {
    dispatch(resetCodeVerified());
    return email;
  });

export const confirmPassword = (actionCode, password) => dispatch =>
  confirmPasswordReset(actionCode, password).then(userCredential =>
    dispatch(passwordResetConfirmed())
  );

export const resetPassword = (email, actionCodeSettings = null) => dispatch =>
  sendPasswordResetEmail(email, actionCodeSettings).then(() =>
    dispatch(resetPasswordSent())
  );

export const signInWithEmailAndPassword = (email, password) => dispatch =>
  signInWithEmail(email, password).then(user => dispatch(userAuthenticated()));

export const fetchUserProfile = uid => dispatch =>
  getUserProfile(uid)
    .then(user => {
      dispatch(userProfileFetched(user));
      if (user && user.defaultOrganization) {
        dispatch(userOrganizationSelected({ id: user.defaultOrganization }));
      }
    })
    .catch(error => {
      console.log(error.message);
    });

export const provisionOrganizationProfile = userData => async dispatch => {
  try {
    dispatch(organizationProvisioningStarted());
    const createdUser = await startProvisioning(userData);
    dispatch(organizationProvisioningComplete());
    if (createdUser) return dispatch(userProfileUpdated(createdUser));
    return null;
  } catch (error) {
    console.log(error);
  }
};

export const updateUser = (organizationId, userId, data) => async dispatch => {
  try {
    await updateOrganizationUser(organizationId, userId, data);
    return dispatch(userDataUpdated());
  } catch (error) {
    console.log(error);
  }
};

export const changeUserPin = (
  organizationId,
  userId,
  type
) => async dispatch => {
  try {
    const pin = await assignPin(userId, organizationId, type);
    await updateOrganizationUserPin(organizationId, userId, type, pin);
    dispatch(userPinUpdated());
    return pin;
  } catch (error) {
    console.log(error);
  }
};
