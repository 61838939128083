import { auth } from '.';
import _ from 'lodash';

import store from '../../redux/store';
import { provisionOrganizationUserAccount } from './organizations';

export const authOnListen = (next, error, complete) =>
  auth().onAuthStateChanged(user => next(user || {}), error, complete);

export const signInAnonymously = () =>
  auth()
    .signInAnonymously()
    .then(user => user);

export const signInWithEmail = (email, password) =>
  auth()
    .signInWithEmailAndPassword(email, password)
    .then(user => user);

export const signOutUser = () => auth().signOut();

export const signUpWithEmail = ({ email, password }) =>
  auth()
    .createUserWithEmailAndPassword(email, password)
    .then(user => user);

export const verifyPasswordResetCode = actionCode =>
  auth().verifyPasswordResetCode(actionCode);

export const confirmPasswordReset = (actionCode, newPassword) =>
  auth().confirmPasswordReset(actionCode, newPassword);

export const sendPasswordResetEmail = (email, actionCodeSettings = null) =>
  auth().sendPasswordResetEmail(email, actionCodeSettings);

export const userForceTokenRefresh = () => auth().currentUser.getIdToken(true);

export const sendEmailVerificationLink = () =>
  auth().currentUser.sendEmailVerification();

export const verifyEmail = (actionCode, continueUrl = '') =>
  auth().applyActionCode(actionCode);

export const checkActionCode = actionCode => auth().checkActionCode(actionCode);

export const getCurrentUser = () => auth().currentUser;

export const isUserEmailVerified = () => {
  if (auth().currentUser && auth().currentUser.emailVerified) return true;
  return false;
};

export const userHasRole = async role => {
  if (role && auth().currentUser && auth().currentUser.emailVerified) {
    const idTokenResult = await auth().currentUser.getIdTokenResult();
    if (idTokenResult.claims[role] && idTokenResult.claims[role] === true)
      return true;
  }
  return false;
};

// export const userHasOneOfRoles = async roles => {
//   if (
//     roles &&
//     roles.length &&
//     auth().currentUser &&
//     auth().currentUser.emailVerified
//   ) {
//     const idTokenResult = await auth().currentUser.getIdTokenResult();
//     const claims = idTokenResult.claims;
//     if (!claims) return false;
//     // Check if there's at least one true.
//     return roles.some(role => claims[role] === true);
//   }
//   return false;
// };

export const userHasOneOfRoles = roles => {
  const user = store.getState().user || {};
  if (
    roles &&
    roles.length &&
    auth().currentUser &&
    auth().currentUser.emailVerified &&
    !_.isEmpty(user) &&
    !_.isEmpty(user.claims)
  )
    return roles.some(role => user.claims[role] === true);

  return false;
};

export const userHasPermission = permissionKey => {
  const permissions = store.getState().permissions || {};
  const user = store.getState().user || {};

  // Override all permissions for moxitAdmin claims.
  // Not this doesn't mean data is protected. Firestore rule MUST be in place
  // to control data read/write.
  if (user && user.claims && !!user.claims.moxitAdmin) return true;
  if (
    permissionKey &&
    permissions[permissionKey] &&
    permissions[permissionKey].roles
  ) {
    const allowedRoles = permissions[permissionKey].roles || [];
    return userHasOneOfRoles(allowedRoles);
  }
  return false;
};

export const startProvisioning = ({
  firstName = null,
  lastName = null,
  photoURL = null,
  phoneNumber = null,
  organizationName = null,
}) => {
  const user = auth().currentUser;
  if (!user.uid) return;
  let profileData = {
    uid: user.uid,
    displayName: null,
    email: user.email,
    firstName,
    lastName,
    photoURL,
    phoneNumber,
  };
  if (firstName && lastName)
    profileData.displayName = `${firstName} ${lastName}`;

  if (organizationName) profileData.organizationName = organizationName;

  return provisionOrganizationUserAccount(profileData);
  // return functions().httpsCallable(
  //   'callables-organizations-provisionOrganizationUserAccount'
  // )(profileData);
};
