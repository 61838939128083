import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Header, Divider } from 'semantic-ui-react';
import moment from 'moment';

const FreelancerDetailInformation = ({
  selectedStudent: {
    id,
    typeOfWork,
    fullName,
    email,
    location,
    skills,
    availablity,
    minimumRate,
    // enabled: true,
    resumeURL,
    contactName,
    contactEmail,
    comment,
    // displayName,
    // birthday,
    // gender,
    // allergies,
    // medications,
    // doctorName,
    // notes,
    // address1,
    // address2,
    // city,
    // state,
    // zipcode,
  },
}) => {
  if (!id) return null;
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={6}>Full Name</Grid.Column>
        <Grid.Column width={10}>
          <b>{fullName}</b>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={6}>Email</Grid.Column>
        <Grid.Column width={10}>
          <b>{email}</b>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={6}>Location</Grid.Column>
        <Grid.Column width={10}>
          <b>{location}</b>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={6}>Skills/Interest</Grid.Column>
        <Grid.Column width={10}>
          <b>{skills}</b>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={6}>Minimum Rate</Grid.Column>
        <Grid.Column width={10}>
          <b>{minimumRate}</b>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={6}>Availability</Grid.Column>
        <Grid.Column width={10}>
          <b>{availablity}</b>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={6}>Resume URL</Grid.Column>
        <Grid.Column width={10}>
          <b>{resumeURL}</b>
        </Grid.Column>
      </Grid.Row>

      {/* 
      <Grid.Row>
        <Grid.Column width={6}>Birthday</Grid.Column>
        <Grid.Column width={10}>
          <b>
            {birthday
              ? moment(`${birthday}`, 'x').format('YYYY-MM-DD')
              : 'No birthday'}
          </b>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>Gender</Grid.Column>
        <Grid.Column width={10}>
          <b>{gender || 'No gender selected'}</b>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>Allergies</Grid.Column>
        <Grid.Column width={10}>
          <b>{allergies || 'No allergies added'}</b>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>Medications</Grid.Column>
        <Grid.Column width={10}>
          <b>{medications || 'No medications added'}</b>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>Doctor</Grid.Column>
        <Grid.Column width={10}>
          <b>{doctorName || 'No Doctor information added'}</b>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>Notes</Grid.Column>
        <Grid.Column width={10}>
          <b>{notes || 'There are no notes added'}</b>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>Address</Grid.Column>
        <Grid.Column width={10}>
          {address1 ? (
            <p>
              <b>{address1}</b>
            </p>
          ) : (
            <b>No address added</b>
          )}
          {address2 && (
            <p>
              <b>{address2}</b>
            </p>
          )}

          <p>
            <b>
              {city && `${city}, `}
              {state && `${state}, `}
              {zipcode && `${zipcode}`}
            </b>
          </p>
        </Grid.Column>
      </Grid.Row>*/}
    </Grid>
  );
};

FreelancerDetailInformation.propTypes = {
  selectedStudent: PropTypes.shape({
    id: PropTypes.string,
    displayName: PropTypes.string,
    birthday: PropTypes.number,
    gender: PropTypes.string,
    allergies: PropTypes.string,
    medications: PropTypes.string,
    doctorName: PropTypes.string,
    notes: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipcode: PropTypes.string,
  }),
};
const mapStateToProps = state => ({
  selectedStudent: state.students.selectedStudent,
});
export default connect(mapStateToProps)(FreelancerDetailInformation);
