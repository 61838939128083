import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Header, Form, Segment } from 'semantic-ui-react';
import Validator from 'validator';
import { withRouter } from 'react-router-dom';

// Import actions
import { signUpWithEmailAndPassword } from '../../../redux/actions/userActions';

// Import Components
import ShowErrors from '../../Messages/ShowError';
import InlineError from '../../Messages/InlineError';
// import OrganizationPicker from '../Organizations/OrganizationPicker';

class SignUpForm extends Component {
  state = {
    data: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      phone: '',
      organizationName: '',
      enabled: true,
    },
    loading: false,
    errors: {},
  };

  onChange = (e, { name, value }) => {
    this.setState({
      data: { ...this.state.data, [name]: value },
      errors: _.omit(this.state.errors, name),
    });
  };

  onSubmit = e => {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });

    if (_.isEmpty(errors)) {
      this.setState({ loading: true });

      this.props
        .signUpWithEmailAndPassword(this.state.data, this.isSignUpRoute())
        .then(() => {
          if (this.isSignUpRoute()) {
            this.props.history.push('/verify');
            // window.location.replace('/verify');
          }
        })
        .catch(error =>
          this.setState({
            errors: { 'Sign Up Error': error.message },
            loading: false,
          })
        );
    }
  };

  validate = data => {
    const errors = {};

    if (!data.firstName) errors.firstName = 'First name is required';
    if (!data.lastName) errors.lastName = 'Last name is required';
    // if (!data.organizationName)
    //   errors.organizationName = 'Facility name is required';
    if (!Validator.isEmail(data.email)) errors.email = 'Invalid email';
    if (!data.password) errors.password = 'Password is required';
    return errors;
  };

  isSignUpRoute = () => {
    const { match } = this.props;
    return match.path === '/signup';
  };

  render() {
    const { data, errors, loading } = this.state;

    return (
      <Segment basic textAlign="left">
        <Header as="h2" textAlign="center">
          Create your Account
        </Header>
        <ShowErrors errors={errors} />

        <Form onSubmit={this.onSubmit} loading={loading} noValidate>
          <Form.Group widths="equal">
            <Form.Field error={!!errors.firstName}>
              <Form.Input
                required
                fluid
                type="text"
                id="firstName"
                name="firstName"
                label="First name"
                placeholder="First name"
                value={data.firstName}
                onChange={this.onChange}
              />
              {errors.firstName && <InlineError text={errors.firstName} />}
            </Form.Field>

            <Form.Field error={!!errors.lastName}>
              <Form.Input
                required
                fluid
                type="text"
                id="lastName"
                name="lastName"
                label="Last name"
                placeholder="Last name"
                value={data.lastName}
                onChange={this.onChange}
              />
              {errors.lastName && <InlineError text={errors.lastName} />}
            </Form.Field>
          </Form.Group>

          {/* <Form.Field error={!!errors.organizationName}>
            <Form.Input
              required
              fluid
              type="text"
              id="organizationName"
              name="organizationName"
              label="Name of facility"
              placeholder="Name of your facility e.g. My Cool Daycare"
              value={data.organizationName}
              onChange={this.onChange}
            />
            {errors.organizationName && (
              <InlineError text={errors.organizationName} />
            )}
          </Form.Field> */}

          <Form.Field error={!!errors.email}>
            <Form.Input
              required
              fluid
              type="email"
              id="email"
              name="email"
              label="Email"
              placeholder="e.g. lucy@email.com"
              value={data.email}
              onChange={this.onChange}
            />
            {errors.email && <InlineError text={errors.email} />}
          </Form.Field>

          <Form.Field error={!!errors.password}>
            <Form.Input
              required
              fluid
              type="password"
              id="password"
              name="password"
              label="Password"
              placeholder="Combine letters, numbers and symbols"
              value={data.password}
              onChange={this.onChange}
            />
            {errors.password && <InlineError text={errors.password} />}
          </Form.Field>

          <Form.Button fluid size="large" primary content="Sign up" />
        </Form>
      </Segment>
    );
  }
}

SignUpForm.propTypes = {
  signUpWithEmailAndPassword: PropTypes.func.isRequired,
};

export default withRouter(
  connect(
    null,
    { signUpWithEmailAndPassword }
  )(SignUpForm)
);
