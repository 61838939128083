import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Segment, Header, Modal, Button } from 'semantic-ui-react';
import { omit, isUndefined, isEmpty } from 'lodash';
import shortid from 'shortid';

// Import components
import InlineError from '../Messages/InlineError';
import ShowError from '../Messages/ShowError';

// Import actions
import {
  deviceSelectionCleared,
  setOrganizationDevice,
  deleteOrganizationDevice,
} from '../../redux/actions/deviceActions';

const deviceOptions = [
  {
    key: 1,
    text: 'iOS Device',
    value: 'ios',
    content: (
      <Header
        icon="apple"
        content="iOS Device"
        subheader="A device with iOS operating system"
      />
    ),
  },
  {
    key: 2,
    text: 'Android Device',
    value: 'android',
    content: (
      <Header
        icon="android"
        content="Android Device"
        subheader="A device with Android operating system"
      />
    ),
  },
];

class DeviceForm extends Component {
  state = {
    data: {
      id: '',
      name: '',
      type: '',
      serialNumber: '',
      email: '',
      password: '',
      enabled: true,
    },
    openConfirmationDialog: false,
    loading: false,
    deleting: false,
    errors: {},
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      devices: {
        selected: { id, name, type, enabled, serialNumber, ...rest },
      },
    } = nextProps;

    if (prevState.data.id !== id && !isEmpty(id)) {
      return {
        data: {
          id,
          name: name || '',
          type: type || '',
          serialNumber: serialNumber || '',
          enabled: enabled === false ? false : true,
          ...rest,
        },
      };
    }

    return prevState;
  }

  componentWillUnmount() {
    // Clear selection from store.
    this.props.deviceSelectionCleared();
  }

  onChange = (e, { name, value, checked }) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: !isUndefined(checked) ? checked : value,
      },
      errors: omit(this.state.errors, name),
    });
  };

  validate = data => {
    const errors = {};

    if (!data.name) errors.name = 'Name is required';
    if (!data.type) errors.type = 'Type is required';
    return errors;
  };

  saveChanges = async () => {
    const {
      data: { id, email, password, ...rest },
    } = this.state;
    const { currentOrganization } = this.props;

    const currentTime = Date.now();

    this.setState({ loading: true });

    try {
      await this.props.setOrganizationDevice(currentOrganization.id, {
        id,
        ...rest,
        email: email || `device_${shortid.generate()}@moxit.com`,
        password: password || `${shortid.generate()}`,
        organization: currentOrganization.id,
        createdAt: currentTime,
        updatedAt: currentTime,
      });

      this.setState({ loading: false });

      // Used to close wrapper HOC. e.g Sliding panel, modal...
      if (this.props.onClose) {
        this.props.onClose(id);
      }
    } catch (error) {
      this.setState({
        loading: false,
        errors: { ...this.state.errors, error },
      });
    }
  };

  deleteData = async () => {
    const {
      data: { id, email },
    } = this.state;

    const { currentOrganization } = this.props;

    if (id && currentOrganization && currentOrganization.id && email) {
      try {
        this.setState({ deleting: true });

        await this.props.deleteOrganizationDevice(currentOrganization.id, id);

        this.setState({
          openConfirmationDialog: false,
          deleting: false,
        });

        // Used to close wrapper HOC. e.g Sliding panel, modal...
        if (this.props.onClose) {
          this.props.onClose();
        }
      } catch (error) {
        console.log(error);
        this.setState({
          openConfirmationDialog: false,
          deleting: false,
          errors: { ...this.state.errors, error },
        });
      }
    }
  };

  onSubmit = e => {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });

    if (isEmpty(errors)) {
      this.saveChanges();
    }
  };

  renderConfirmationDialog = () => (
    <Modal
      size="mini"
      open={this.state.openConfirmationDialog}
      onClose={() => this.setState({ openConfirmationDialog: false })}
    >
      <Modal.Header>Remove Device</Modal.Header>
      <Modal.Content>
        <p>Are you sure you want to remove this device?</p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={e => {
            if (e) e.preventDefault();
            this.setState({
              openConfirmationDialog: false,
              deleting: false,
            });
          }}
        >
          No
        </Button>
        <Button
          negative
          icon="checkmark"
          labelPosition="right"
          content="Yes"
          loading={this.state.deleting}
          onClick={e => {
            if (e) e.preventDefault();
            this.deleteData();
          }}
        />
      </Modal.Actions>
    </Modal>
  );

  render() {
    const { data, loading, errors } = this.state;

    return (
      <Segment basic textAlign="left">
        {this.renderConfirmationDialog()}

        {data.email && (
          <Segment secondary>
            <Header> Device credential </Header>
            <div>
              username: <code>{data.email}</code>
            </div>
            <div>
              password: <code>{data.password}</code>
            </div>
          </Segment>
        )}

        <Form
          id="device-form"
          onSubmit={this.onSubmit}
          loading={loading}
          noValidate
        >
          <ShowError errors={errors} />
          <Form.Field error={!!errors.name}>
            <Form.Input
              required
              type="text"
              id="name"
              name="name"
              label="Name"
              placeholder="Device name. e.g.: Frogs Tablet"
              value={data.name}
              onChange={this.onChange}
            />
            {errors.name && <InlineError text={errors.name} />}
          </Form.Field>

          <Form.Field error={!!errors.type}>
            <Form.Dropdown
              required
              id="type"
              name="type"
              label="Type"
              placeholder="Select tablet type"
              options={deviceOptions}
              value={data.type}
              selection
              search
              onChange={this.onChange}
            />
            {errors.type && <InlineError text={errors.type} />}
          </Form.Field>

          <Form.Field error={!!errors.serialNumber}>
            <Form.Input
              type="text"
              id="serialNumber"
              name="serialNumber"
              label="Serial number"
              placeholder="Device name serial number"
              value={data.serialNumber}
              onChange={this.onChange}
            />
            {errors.serialNumber && <InlineError text={errors.serialNumber} />}
          </Form.Field>

          <Form.Field error={!!errors.enabled}>
            <label>Account enabled?</label>
            <Form.Radio
              toggle
              id="enabled"
              name="enabled"
              onChange={this.onChange}
              checked={data.enabled}
            />
            {errors.enabled && <InlineError text={errors.enabled} />}
          </Form.Field>

          <Form.Group>
            <Form.Button primary content={'Save'} />
            <Form.Button
              basic
              content="Cancel"
              onClick={e => {
                if (e) e.preventDefault();
                if (this.props.onClose) this.props.onClose();
              }}
            />
            {/* Only Show delete button during edit. */}

            {data.email && (
              <Form.Button
                negative
                content={'Remove'}
                onClick={e => {
                  if (e) e.preventDefault();
                  this.setState({
                    openConfirmationDialog: true,
                  });
                }}
              />
            )}
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

const mapStateToProps = state => ({
  currentOrganization: state.organizations.currentOrganization,
  devices: state.devices,
});

export default connect(
  mapStateToProps,
  { deviceSelectionCleared, setOrganizationDevice, deleteOrganizationDevice }
)(DeviceForm);
