import React from 'react';
import { Container, Segment } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';

const Error = () => {
  // document.title = 'Oh no! App';
  // document.body.classList.add('error');

  const renderPageHead = () => (
    <Helmet>
      <title>Oh no!</title>
      <body class="error" />
    </Helmet>
  );

  return (
    <Container className="no-padding no-margin">
      {renderPageHead()}

      <Segment basic>
        <h1>¯\_(ツ)_/¯</h1>
      </Segment>
    </Container>
  );
};

export default Error;
