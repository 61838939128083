import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header, Form, Segment } from 'semantic-ui-react';
import Validator from 'validator';
import PropTypes from 'prop-types';
import { withRouter, NavLink } from 'react-router-dom';
import _ from 'lodash';

// Import actions.
import { signInWithEmailAndPassword } from '../../../redux/actions/userActions';

// Import components
import ShowErrors from '../../Messages/ShowError';

class SignInForm extends Component {
  state = {
    data: {
      email: '',
      password: '',
    },
    loading: false,
    errors: {},
  };

  onChange = (e, { name, value }) =>
    this.setState({
      data: { ...this.state.data, [name]: value },
      errors: _.omit(this.state.errors, name),
    });

  onSubmit = () => {
    const errors = this.validate();
    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      const {
        data: { email, password },
      } = this.state;
      this.setState({ loading: true });

      this.props
        .signInWithEmailAndPassword(email, password)
        .then(user => {
          if (user) {
            const { location, history } = this.props;
            if (location.state && location.state.from) {
              const { pathname } = location.state.from;
              history.replace(pathname);
            } else {
              history.replace('/');
            }
          }
        })
        .catch(error => {
          this.setState({
            loading: false,
            errors: { ...this.state.errors, 'Sign In Error': error.message },
          });
        });
    }
  };

  validate = () => {
    const errors = {};
    if (!Validator.isEmail(this.state.data.email))
      errors.email = 'Invalid email';
    if (!this.state.data.password) errors.password = 'Password is required';
    return errors;
  };

  render() {
    const { data, errors, loading } = this.state;

    return (
      <Segment basic textAlign="left">
        <Header as="h2" textAlign="center">
          Sign in to your account
        </Header>

        <ShowErrors errors={errors} />
        <Form onSubmit={this.onSubmit} loading={loading} noValidate>
          <Form.Input
            required
            error={!!errors.email}
            autoComplete="username"
            name="email"
            type="email"
            label="Email"
            placeholder="Your email"
            value={data.email}
            onChange={this.onChange}
          />
          <Form.Input
            required
            error={!!errors.password}
            autoComplete="current-password"
            type="password"
            name="password"
            label="Password"
            placeholder="Your password"
            value={data.password}
            onChange={this.onChange}
          />
          <Form.Field>
            <strong>Help: </strong>
            <NavLink to="/forgot-password">Reset your password</NavLink>
          </Form.Field>

          <Form.Button primary fluid size="large" content="Sign in" />
        </Form>
      </Segment>
    );
  }
}

SignInForm.propTypes = {
  signInWithEmailAndPassword: PropTypes.func.isRequired,
};

export default withRouter(
  connect(
    null,
    { signInWithEmailAndPassword }
  )(SignInForm)
);
