import { combineReducers } from 'redux';
// Import action.
import { USER_SIGNED_OUT } from '../actions/types';

// Import reducers
import auth from './auth';
import user from './user';
import permissions from './permissions';
import roles from './roles';
import students from './students';
import organizations from './organizations';
import locations from './locations';
import rooms from './rooms';
import staff from './staff';
import layout from './layout';
import devices from './devices';

const rootReducer = combineReducers({
  auth,
  user,
  permissions,
  roles,
  students,
  organizations,
  locations,
  rooms,
  staff,
  layout,
  devices,
});

// Handy trick to clear store upon user sign out.
export default (state, action) =>
  action.type === USER_SIGNED_OUT
    ? rootReducer({}, action)
    : rootReducer(state, action);
