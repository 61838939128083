import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Segment, Message } from 'semantic-ui-react';
import { withRouter, NavLink } from 'react-router-dom';

//Import actions
import {
  sendEmailVerifyLink,
  verifyUserEmail,
  signOut,
} from '../../redux/actions/userActions';

import ShowSuccess from '../Messages/ShowSuccess';
import ShowWaiting from '../Messages/ShowWaiting';
import ShowErrors from '../Messages/ShowError';

import { getCurrentUser } from '../../api/firebase/account';

class EmailVerify extends Component {
  state = {
    verifying: true,
    successMessage: '',
    newLinkSentMessage: '',
    error: '',
  };

  componentDidMount() {
    // Holding the process to give the user some visuals queue. Not mandatory.
    setTimeout(() => {
      this.handleVerification();
    }, 2000);
  }

  static getDerivedStateFromProps(props, state) {
    const { history } = props;
    const currentUser = getCurrentUser();

    if (!currentUser) {
      history.replace('/');
      return {};
    }

    if (currentUser && currentUser.emailVerified) {
      history.replace('/');
      return {};
    }

    return state;
  }

  handleLetsGetStarted = () => {
    const { verifying } = this.state;

    return !verifying ? (
      <Button
        content="Let's get started"
        color="green"
        size="huge"
        onClick={() => window.location.replace('/setup')}
      />
    ) : null;
  };

  handleVerification = () => {
    const { actionCode, verifyUserEmail } = this.props;
    const currentUser = getCurrentUser();

    if (!currentUser) return null;

    verifyUserEmail(actionCode)
      .then(() => {
        this.setState({
          verifying: false,
          successMessage: `Email Verified! Welcome to Moxit.`,
          error: '',
        });
      })
      .catch(error => {
        this.setState({
          verifying: false,
          successMessage: '',
          error: error.message,
        });
      });
  };

  renderMessages = () => (
    <Segment basic>
      <ShowWaiting
        header="Just a moment"
        content="We verifying your email address"
        show={this.state.verifying}
      />
      <ShowSuccess content={this.state.successMessage} />
      <ShowErrors content={this.state.error} />
      {this.state.newLinkSentMessage && (
        <Message header="Email Sent" content={this.state.newLinkSentMessage} />
      )}
    </Segment>
  );

  renderSendLink = () => {
    const { sendEmailVerifyLink, signOut } = this.props;

    // Only show this block if there is error, there's no active user session or verification has successfully ended.
    if (!this.state.error && !getCurrentUser() && this.state.successMessage)
      return null;

    return (
      <Segment basic textAlign="center">
        <Button
          content="Send Verification Email Again"
          color="green"
          size="massive"
          onClick={() => {
            sendEmailVerifyLink()
              .then(() => {
                this.setState({
                  verifying: false,
                  successMessage: '',
                  newLinkSentMessage: `We just sent you a new verification email to ${
                    getCurrentUser().email
                  }`,
                  error: '',
                });
              })
              .catch(error => {
                this.setState({
                  verifying: false,
                  successMessage: '',
                  newLinkSentMessage: '',
                  error: error.message,
                });
              });
          }}
        />
        <Segment basic>
          <span>Forget everything just </span>
          <NavLink
            to=""
            onClick={e => {
              e.preventDefault();
              signOut();
            }}
          >
            Sign me out
          </NavLink>
        </Segment>
      </Segment>
    );
  };

  render() {
    return (
      <Segment basic>
        <h3> Verify Your Account</h3>
        {this.renderMessages()}
        {this.handleLetsGetStarted()}
        {/* {this.renderSendLink()} */}
      </Segment>
    );
  }
}

EmailVerify.propTypes = {
  user: PropTypes.shape({
    uid: PropTypes.string,
  }),
  sendEmailVerifyLink: PropTypes.func.isRequired,
  verifyUserEmail: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
};

export default withRouter(
  connect(
    null,
    { sendEmailVerifyLink, verifyUserEmail, signOut }
  )(EmailVerify)
);
