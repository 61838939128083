import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

export const phoneNumberFormat = (phoneNumber, code = 'US') =>
  phoneNumber
    ? phoneUtil.format(
        phoneUtil.parseAndKeepRawInput(phoneNumber, code),
        PhoneNumberFormat.E164
      )
    : '';

export const phoneNumberParse = (phoneNumber, code = 'US') =>
  phoneNumber
    ? phoneUtil.format(
        phoneUtil.parseAndKeepRawInput(phoneNumber, code),
        PhoneNumberFormat.NATIONAL
      )
    : '';

export const humanReadableFileSize = bytes => {
  var i = Math.floor(Math.log(bytes) / Math.log(1024));
  return (
    (bytes / Math.pow(1024, i)).toFixed(2) * 1 +
    ' ' +
    ['B', 'KB', 'MB', 'GB', 'TB'][i]
  );
};

export const dataURLtoBlob = dataUrl => {
  var arr = dataUrl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

export const blobToDataURL = (blob, callback) => {
  var a = new FileReader();
  a.onload = function(e) {
    callback(e.target.result);
  };
  a.readAsDataURL(blob);
};
