import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Segment } from 'semantic-ui-react';
import _ from 'lodash';
import Validator from 'validator';
import MaskedInput from 'react-text-mask';

// Import components
import InlineError from '../../Messages/InlineError';
import ShowErrors from '../../Messages/ShowError';

// Import actions
import {
  familySelectionCleared,
  addFamilyMember,
  updateFamilyMemberData,
} from '../../../redux/actions/studentActions';
import { changeUserPin } from '../../../redux/actions/userActions';

// Import utils
import { phoneNumberFormat, phoneNumberParse } from '../../../helpers/utils';
import FamilyTypePicker from '../../Students/FamilyTypePicker';

class StudentFamilyForm extends Component {
  state = {
    data: {
      uid: '',
      firstName: '',
      middleName: '',
      lastName: '',
      phone: '',
      email: '',
      relationship: '',
      familyPin: '',
      allowParentLogin: true,
      enabled: true,
    },
    generating: false,
    loading: false,
    errors: {},
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      selectedStudent: { selectedFamilyMember },
    } = nextProps;

    if (
      selectedFamilyMember &&
      !_.isEmpty(selectedFamilyMember.uid) &&
      prevState.data.uid !== selectedFamilyMember.uid
    ) {
      const { phone, ...rest } = selectedFamilyMember;
      return {
        data: { phone: phoneNumberParse(phone), ...rest },
      };
    }
    return prevState;
  }

  componentWillUnmount() {
    // Clear selection from store.
    this.props.familySelectionCleared();
  }

  onChange = (e, { name, value, checked }) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: !_.isUndefined(checked) ? checked : value,
      },
      errors: _.omit(this.state.errors, name),
    });
  };

  maskedOnChange = e => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
      errors: _.omit(this.state.errors, e.target.name),
    });
  };

  validate = data => {
    const errors = {};

    if (!data.firstName) errors.firstName = 'First Name is required';
    if (!data.lastName) errors.lastName = 'Last Name is required';
    if (data.phone)
      if (!Validator.isMobilePhone(data.phone, 'en-US'))
        errors.phone = 'Phone is invalid';

    if (!data.allowParentLogin && data.email)
      if (!Validator.isEmail(data.email)) errors.email = 'Invalid email';

    if (data.allowParentLogin && !data.email)
      errors.email = 'Email is required';

    if (data.allowParentLogin && data.email)
      if (!Validator.isEmail(data.email)) errors.email = 'Invalid email';

    if (!data.relationship) errors.relationship = 'Relationship is required';

    return errors;
  };

  saveChanges = (closeAfterSave = true) => {
    const {
      data: { uid, phone, ...rest },
    } = this.state;

    const { currentOrganization, selectedStudent } = this.props;
    this.setState({ loading: true });

    const formData = {
      phone: phoneNumberFormat(phone),
      currentOrganization: currentOrganization.id,
      uid,
      ...rest,
    };

    if (
      selectedStudent &&
      selectedStudent.id &&
      currentOrganization &&
      currentOrganization.id
    )
      if (uid) {
        // Update.
        this.props
          .updateFamilyMemberData(selectedStudent.id, formData)
          .then(() => {
            // Used to close wrapper HOC. e.g Sliding panel, modal...
            if (this.props.done && closeAfterSave) {
              this.props.done();
              return;
            }
            this.setState({ loading: false });
          })
          .catch(error =>
            this.setState({
              loading: false,
              errors: { 'Unable to Add': error.message },
            })
          );
      } else {
        // New entry
        this.props
          .addFamilyMember(selectedStudent.id, formData)
          .then(() => {
            // Used to close wrapper HOC. e.g Sliding panel, modal...
            if (this.props.done && closeAfterSave) {
              this.props.done();
              return;
            }
            this.setState({ loading: false });
          })
          .catch(error =>
            this.setState({
              loading: false,
              errors: { 'Unable to Add': error.message },
            })
          );
      }
  };

  onSubmit = e => {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });

    if (_.isEmpty(errors)) {
      this.saveChanges();
    }
  };

  render() {
    const { data, loading, errors } = this.state;
    return (
      <Segment basic textAlign="left">
        <ShowErrors errors={errors} />

        <Form onSubmit={this.onSubmit} loading={loading} noValidate>
          <Form.Group widths="equal">
            <Form.Field error={!!errors.firstName}>
              <Form.Input
                required
                type="text"
                id="firstName"
                name="firstName"
                value={data.firstName}
                onChange={this.onChange}
                label="First Name"
                placeholder="First Name"
              />
              {errors.firstName && <InlineError text={errors.firstName} />}
            </Form.Field>

            <Form.Field error={!!errors.middleName}>
              <Form.Input
                type="text"
                id="middleName"
                name="middleName"
                value={data.middleName}
                onChange={this.onChange}
                label="Middle Name"
                placeholder="Middle Name"
              />
              {errors.middleName && <InlineError text={errors.middleName} />}
            </Form.Field>
          </Form.Group>

          <Form.Field error={!!errors.lastName}>
            <Form.Input
              required
              type="text"
              id="lastName"
              name="lastName"
              value={data.lastName}
              onChange={this.onChange}
              label="Last Name"
              placeholder="Last Name"
            />
            {errors.lastName && <InlineError text={errors.lastName} />}
          </Form.Field>

          <Form.Field error={!!errors.email}>
            <Form.Input
              required={data.allowParentLogin}
              type="text"
              id="email"
              name="email"
              value={
                data.email && data.email.indexOf('moxit_') !== -1
                  ? ''
                  : data.email
              }
              onChange={this.onChange}
              label="Email"
              placeholder="Email"
            />
            {errors.email && <InlineError text={errors.email} />}
          </Form.Field>

          <Form.Group widths="equal">
            <FamilyTypePicker
              required
              id="relationship"
              name="relationship"
              label="Relationship"
              placeholder="Relationship"
              selection
              selectOnBlur={false}
              value={data.relationship}
              onChange={this.onChange}
              error={!!errors.relationship}
            >
              {errors.relationship && (
                <InlineError text={errors.relationship} />
              )}
            </FamilyTypePicker>

            <Form.Field error={!!errors.phone}>
              <Form.Input
                type="text"
                id="phone"
                name="phone"
                onChange={this.maskedOnChange}
                label="Cell Phone"
                control={MaskedInput}
                mask={[
                  '(',
                  /[1-9]/,
                  /\d/,
                  /\d/,
                  ')',
                  ' ',
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                guide={false}
                value={data.phone}
                placeholder="(123) 456-7890"
              />
              {errors.phone && <InlineError text={errors.phone} />}
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field error={!!errors.allowParentLogin}>
              <label>Allow user to login using PIN code?</label>
              <Form.Radio
                toggle
                id="allowParentLogin"
                name="allowParentLogin"
                onChange={this.onChange}
                checked={data.allowParentLogin}
              />
              {errors.allowParentLogin && (
                <InlineError text={errors.allowParentLogin} />
              )}
            </Form.Field>
            {data.allowParentLogin && data.uid && (
              <Form.Input
                readOnly
                label="PIN"
                value={data.familyPin}
                action={{
                  color: 'teal',
                  icon: 'sync',
                  loading: this.state.generating,
                  onClick: async e => {
                    e.preventDefault();
                    const { id } = this.props.currentOrganization;

                    this.setState({ generating: true });

                    if (data.uid && id) {
                      try {
                        // Generate PIN.
                        const familyPin = await this.props.changeUserPin(
                          id,
                          data.uid,
                          'familyPin'
                        );

                        // If PIN Generation is successful, save changes.
                        if (familyPin) {
                          this.setState(
                            {
                              generating: false,
                              data: { ...this.state.data, familyPin },
                            },
                            () => this.saveChanges(false)
                          );
                        }
                      } catch (error) {
                        console.log(error);
                        this.setState({ generating: false });
                      }
                    }
                  },
                }}
              />
            )}
          </Form.Group>

          <Form.Field error={!!errors.enabled}>
            <label>Account enabled?</label>
            <Form.Radio
              toggle
              id="enabled"
              name="enabled"
              onChange={this.onChange}
              checked={data.enabled}
            />
            {errors.enabled && <InlineError text={errors.enabled} />}
          </Form.Field>

          <br />
          <br />
          <Form.Group>
            <Form.Button primary content={data.uid ? 'Update' : 'Save'} />

            <Form.Button
              basic
              content="Cancel"
              onClick={e => {
                if (e) e.preventDefault();
                if (this.props.done) this.props.done();
              }}
            />
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

StudentFamilyForm.propTypes = {
  currentOrganization: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  selectedStudent: PropTypes.shape({
    id: PropTypes.string,
    selectedFamilyMember: PropTypes.shape({
      uid: PropTypes.string,
    }),
    family: PropTypes.shape({
      uid: PropTypes.string,
    }),
  }),
  familySelectionCleared: PropTypes.func.isRequired,
  addFamilyMember: PropTypes.func.isRequired,
  updateFamilyMemberData: PropTypes.func.isRequired,
  changeUserPin: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  currentOrganization: state.organizations.currentOrganization,
  selectedStudent: state.students.selectedStudent,
});

export default connect(
  mapStateToProps,
  {
    familySelectionCleared,
    addFamilyMember,
    changeUserPin,
    updateFamilyMemberData,
  }
)(StudentFamilyForm);
