import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Card, Icon, Segment, Header, Button } from 'semantic-ui-react';
import { isEmpty } from 'lodash';

// Import actions.
import {
  getMoxitOrganizations,
  switchOrganization,
} from '../../redux/actions/organizationActions';

// Import components
import withSlidingPanel from '../Shared/withSlidingPanel';
import withPermission from '../Shared/withPermission';
import OrganizationCard from './OrganizationCard';
import OrganizationForm from '../Forms/OrganizationForm';

const SlidingOrganizationAddForm = withSlidingPanel(OrganizationForm, {
  title: 'Add New Franchise',
});

const NewOrganizationCard = props => (
  <Card className="add-new" {...props}>
    <Card.Content>
      <div className={'center'}>
        <Card.Header as={'h4'}>
          <Icon name="plus" />
        </Card.Header>
        <Card.Description>Add new Franchise</Card.Description>
      </div>
    </Card.Content>
  </Card>
);

const CreateNewOrganization = withPermission(
  NewOrganizationCard,
  'can_create_moxit_organization'
);

class AllOrganizationsList extends Component {
  state = {
    isAddOpen: false,
    isEditOpen: false,
    pageLoading: false,
    organizations: {},
  };

  componentDidMount() {
    const {
      organizations: { list },
    } = this.props;

    if (isEmpty(list)) this.getData(10);
  }

  getData = async limit => {
    const {
      organizations: { last },
    } = this.props;
    try {
      this.setState({ pageLoading: true });
      await this.props.getMoxitOrganizations(limit, last);
      this.setState({ pageLoading: false });
    } catch (error) {
      this.setState({ pageLoading: false });
      console.log(error);
    }
  };

  renderOrganizationsList = () => {
    const {
      organizations: { list },
      user: { uid, defaultOrganization },
    } = this.props;

    return (
      <Card.Group stackable itemsPerRow={4}>
        <CreateNewOrganization
          onClick={() => {
            // if (id) {
            //   const newStudent = newOrganizationStudent(id);
            //   // Initialize new student's id. The student id is useful to upload to firebase storage.
            //   this.props.studentSelected({
            //     id: newStudent.id,
            //   });
            // }
            this.setState({ isAddOpen: true });
          }}
        />

        {Object.keys(list).map(key => {
          const organization = list[key];

          return (
            <OrganizationCard
              key={key}
              organization={organization}
              currentUserDefaultOrganization={defaultOrganization}
              onClick={async () => {
                if (defaultOrganization && organization.id && uid) {
                  // if (defaultOrganization !== organization.id)
                  try {
                    await this.props.switchOrganization(
                      defaultOrganization,
                      organization.id,
                      uid
                    );

                    this.props.history.push(
                      `/organizations/${organization.id}`
                    );
                  } catch (error) {
                    console.log(error);
                  }
                }
              }}
            />
          );
        })}
      </Card.Group>
    );
  };

  renderPageTitle = () => {
    const {
      organizations: { list },
    } = this.props;

    if (list) {
      return (
        <Segment basic clearing>
          {/* <Header as="h4" floated="right">
            <Icon.Group>
              <Icon name="filter" />
            </Icon.Group>
            Filter
          </Header> */}
          <Header as="h1">Franchisees</Header>
          <Header.Subheader>Washington, DC Market</Header.Subheader>
        </Segment>
      );
    }

    return (
      <Segment basic clearing>
        <Header as="h1" floated="left">
          Franchisees
        </Header>
      </Segment>
    );
  };

  renderLoadMoreButton = () => {
    const {
      organizations: { last },
    } = this.props;

    return (
      last && (
        <Segment basic textAlign="center">
          <Button
            primary
            fluid
            basic
            loading={this.state.pageLoading}
            disabled={this.state.pageLoading}
            content="Load More"
            size="large"
            onClick={() => {
              this.getData(10);
            }}
          />
        </Segment>
      )
    );
  };

  renderSlidingForms = () => {
    return (
      <div>
        <SlidingOrganizationAddForm
          isOpen={this.state.isAddOpen}
          onClose={() => this.setState({ isAddOpen: false })}
        />
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderPageTitle()}
        {this.renderOrganizationsList()}
        {this.renderLoadMoreButton()}
        {this.renderSlidingForms()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  organizations: state.organizations,
});

export default withRouter(
  connect(
    mapStateToProps,
    { getMoxitOrganizations, switchOrganization }
  )(AllOrganizationsList)
);
