import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Card, Icon, Segment, Header } from 'semantic-ui-react';

// Import actions
import { roomSelected } from '../../redux/actions/roomActions';

// Import Components
import RoomCard from './RoomCard';
import RoomForm from '../Forms/RoomForm';
import withSlidingPanel from '../Shared/withSlidingPanel';

const SlidingRoomFormAdd = withSlidingPanel(RoomForm, {
  title: 'Add A Room',
});

const SlidingRoomFormEdit = withSlidingPanel(RoomForm, {
  title: 'Edit Room',
});

class RoomsList extends Component {
  state = {
    isAddOpen: false,
    isEditOpen: false,
  };

  renderPageTitle = () => {
    const {
      rooms: { list },
    } = this.props;

    if (list) {
      return (
        <Segment basic clearing>
          <Header as="h4" floated="right">
            <Icon.Group>
              <Icon name="filter" />
            </Icon.Group>
            Filter
          </Header>
          <Header as="h1" floated="left">
            Rooms ({list.length})
          </Header>
        </Segment>
      );
    }

    return (
      <Segment basic clearing>
        <Header as="h1" floated="left">
          Rooms
        </Header>
      </Segment>
    );
  };

  renderRoomList = () => {
    const {
      rooms: { list },
      itemsPerRow,
    } = this.props;

    return (
      <Card.Group stackable itemsPerRow={itemsPerRow || 4}>
        <Card
          className="add-new"
          onClick={() => this.setState({ isAddOpen: true })}
        >
          <Card.Content>
            <div className={'center'}>
              <Card.Header as={'h4'}>
                <Icon name="plus" />
              </Card.Header>
              <Card.Description>Add new room</Card.Description>
            </div>
          </Card.Content>
        </Card>

        {list.map(room => (
          <RoomCard
            key={room.id}
            room={room}
            onClick={() => {
              this.props.roomSelected(room);
              this.setState({ isEditOpen: true });
            }}
          />
        ))}
      </Card.Group>
    );
  };

  renderSlidingForms = () => {
    return (
      <div>
        <SlidingRoomFormAdd
          isOpen={this.state.isAddOpen}
          onClose={() => this.setState({ isAddOpen: false })}
        />
        <SlidingRoomFormEdit
          isOpen={this.state.isEditOpen}
          onClose={() => {
            this.setState({ isEditOpen: false });
          }}
        />
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderPageTitle()}
        {this.renderRoomList()}
        {this.renderSlidingForms()}
      </div>
    );
  }
}

RoomsList.defaultProps = {
  itemsPerRow: 4,
};

RoomsList.propTypes = {
  rooms: PropTypes.shape({
    selectedRoom: PropTypes.shape({
      id: PropTypes.string,
    }),
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
  }),
  roomSelected: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  rooms: state.rooms,
  currentOrganization: state.organizations.currentOrganization,
});

export default connect(
  mapStateToProps,
  { roomSelected }
)(RoomsList);
