import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Form } from 'semantic-ui-react';
import { getRooms } from '../../redux/actions/roomActions';

class RoomPicker extends React.Component {
  state = {
    loading: false,
    options: [],
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.location && prevProps.location !== this.props.location)
      this.getData();
  };

  getData = () => {
    const { location, dependent } = this.props;

    let rooms = getRooms();
    if (_.isEmpty(rooms)) return;

    if (dependent && !location) {
      this.setState({ options: [] });
      return;
    }

    if (location) rooms = _.filter(rooms, { location });

    this.setState({ loading: true });
    const options = rooms.map(room => ({
      text: room.name,
      value: room.id,
    }));

    this.setState({ loading: false, options });
  };

  render() {
    const {
      staticContext,
      currentOrganization,
      control,
      children,
      dispatch,
      location,
      dependent,
      ...rest
    } = this.props;

    return (
      <Form.Dropdown
        {...rest}
        loading={this.state.loading}
        options={this.state.options}
        selectOnBlur={false}
        clearable
        renderLabel={label => ({
          color: 'green',
          content: label.text,
          // icon: 'check',
        })}
      />
    );
  }
}

const mapStateToProps = state => ({
  currentOrganization: state.organizations.currentOrganization,
});

export default connect(mapStateToProps)(RoomPicker);
