import React from 'react';
import { Container, Header, Segment } from 'semantic-ui-react';

const Masthead = props => (
  <Segment basic className="masthead">
    <Container>
      <Header as="h1">
        <a href="/">Unfurlough.us</a>
      </Header>
      <Header as="h2" className="no-padding no-margin">
        Freelance gigs for furloughed govvies
      </Header>
    </Container>
  </Segment>
);

export default Masthead;
