import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Header, Form, Segment } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import _ from 'lodash';

// Import components
import InlineError from '../Messages/InlineError';
import ShowSuccess from '../Messages/ShowSuccess';
import ShowWaiting from '../Messages/ShowWaiting';
import ShowErrors from '../Messages/ShowError';

// Import actions.
import {
  verifyResetCode,
  confirmPassword,
} from '../../redux/actions/userActions';

class ResetPassword extends Component {
  state = {
    data: {
      email: '',
      password: '',
      confirmPassword: '',
    },
    loading: false,
    verifying: true,
    verified: false,
    resetComplete: false,
    successMessage: '',
    errors: {},
  };

  componentDidMount() {
    this.props
      .verifyResetCode(this.props.actionCode)
      .then(email =>
        this.setState({
          data: { ...this.state.data, email },
          verifying: false,
          verified: true,
        })
      )
      .catch(error =>
        this.setState({
          verifying: false,
          verified: false,
          errors: { 'Unable to verify link': error.message },
        })
      );
  }

  onChange = (e, { name, value }) => {
    this.setState({
      data: { ...this.state.data, [name]: value },
      errors: _.omit(this.state.errors, name),
    });
  };

  validate = data => {
    const errors = {};

    if (data.password.length <= 8) {
      errors.password = 'Password is too short (minimum is 8 characters)';
    }
    if (data.confirmPassword.length <= 8) {
      errors.confirmPassword =
        'Confirmation Password is too short (minimum is 8 characters)';
    }

    if (data.password !== data.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }

    return errors;
  };

  onSubmit = e => {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });

    if (_.isEmpty(errors)) {
      const { actionCode } = this.props;
      const {
        data: { password },
      } = this.state;
      this.setState({ loading: true });
      this.props
        .confirmPassword(actionCode, password)
        .then(() => {
          this.setState({
            loading: false,
            resetComplete: true,
            successMessage: 'Password reset successfully',
          });
        })
        .catch(error =>
          this.setState({
            loading: false,
            errors: { 'Unable to reset password': error.message },
          })
        );
    }
  };

  renderMessageAlertMessages = () => (
    <div>
      {this.state.verifying && (
        <ShowWaiting
          header="Just a moment"
          content="We are verifying the link"
          show={this.state.verifying}
        />
      )}
      {this.state.errors && <ShowErrors errors={this.state.errors} />}
      {this.state.successMessage && (
        <ShowSuccess content={this.state.successMessage} />
      )}
    </div>
  );

  renderMessages = () => (
    <div>
      {this.props.continueUrl ? (
        this.state.verified && !this.state.resetComplete ? (
          <div className="InvitedUser">
            <Header as="h2" textAlign="center">
              Welcome!
            </Header>
            <p>
              Get started by choosing a secure password at least 8 characters
              long with both letters and numbers.
            </p>
          </div>
        ) : (
          !this.state.verifying &&
          !this.state.resetComplete && (
            <div className="InvitedUser">
              <h1>Ouch!</h1>
              <p className="error">
                Unfortunately, this invitation has expired or has already been
                used. Please reach out to the center administrator to send you a
                new invitation.
              </p>
              <p>
                <img
                  src="https://media.giphy.com/media/9Y5BbDSkSTiY8/giphy.gif"
                  alt="Sad"
                />
              </p>
            </div>
          )
        )
      ) : this.state.verified && !this.state.resetComplete ? (
        <div className="ResetPassword">
          <Header as="h2" textAlign="center">
            Reset your password
          </Header>
        </div>
      ) : (
        !this.state.verifying &&
        !this.state.resetComplete && (
          <div className="ResetPassword">
            <Header as="h2" textAlign="center">
              Uh! Oh!
            </Header>
            <p className="error">
              Having problem with the link.{' '}
              <NavLink to="/reset">Try resetting your password again</NavLink>
            </p>
            <p>
              <img
                src="https://media.giphy.com/media/9Y5BbDSkSTiY8/giphy.gif"
                alt="Sad"
              />
            </p>
          </div>
        )
      )}
    </div>
  );

  renderActionMessages = () => (
    <div>
      {this.props.continueUrl && this.state.resetComplete && (
        <div className="InvitedUser">
          <Header as="h2" textAlign="center">
            Yay!
          </Header>
          <p>
            Your account has been created successfully.{' '}
            <NavLink to="/signin">You can go ahead and sign in.</NavLink>
          </p>
          <p>
            <img
              src="https://media.giphy.com/media/TztOD2c0znrtm/giphy.gif"
              alt="Happy Dance"
            />
          </p>
        </div>
      )}
      {!this.props.continueUrl && this.state.resetComplete && (
        <div className="ResetPassword">
          <Header as="h2" textAlign="center">
            Password changed
          </Header>
          <p>
            You can now <NavLink to="/signin">sign in </NavLink>
            with your new password
          </p>
        </div>
      )}
    </div>
  );

  render() {
    const {
      data,
      errors,
      loading,
      verified,
      verifying,
      resetComplete,
    } = this.state;

    return (
      <Segment basic textAlign="left">
        {this.renderMessageAlertMessages()}
        <div>
          {this.renderMessages()}
          {this.renderActionMessages()}
        </div>
        {!resetComplete && verified && (
          <Form onSubmit={this.onSubmit} loading={loading} noValidate>
            <Form.Field error={!!errors.password}>
              <Form.Input
                fluid
                type="password"
                id="password"
                name="password"
                label="Password"
                placeholder="Combine letters, numbers and symbols"
                value={data.password}
                onChange={this.onChange}
              />
              {errors.password && <InlineError text={errors.password} />}
            </Form.Field>

            <Form.Field error={!!errors.confirmPassword}>
              <Form.Input
                fluid
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                label="Re-enter Password"
                placeholder="Combine letters, numbers and symbols"
                value={data.confirmPassword}
                onChange={this.onChange}
              />
              {errors.confirmPassword && (
                <InlineError text={errors.confirmPassword} />
              )}
            </Form.Field>

            <Form.Button
              disabled={verifying}
              fluid
              size="large"
              primary
              content="Set my password"
            />
          </Form>
        )}
      </Segment>
    );
  }
}

ResetPassword.propTypes = {
  verifyResetCode: PropTypes.func.isRequired,
};

export default connect(
  null,
  { verifyResetCode, confirmPassword }
)(ResetPassword);
