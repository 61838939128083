import { firestore, functions } from '.';
import { getDocuments, setDocument } from './firestore';

export const organizationStudentsOnListen = (
  organizationId,
  next,
  error,
  complete
) =>
  firestore()
    .collection(`organizations/${organizationId}/students`)
    .orderBy('firstName', 'asc')
    .onSnapshot(
      querySnapshot =>
        next(
          querySnapshot
            ? {
                count: querySnapshot.size,
                students: querySnapshot.docs.map(doc => ({
                  id: doc.id,
                  ...doc.data(),
                })),
              }
            : {}
        ),
      error,
      complete
    );

export const getAllGigs = () =>
  getDocuments({
    path: 'students',
  });

export const newOrganizationStudent = organizationId => {
  if (!organizationId) throw new Error('Organization Id is required');
  return firestore()
    .collection(`organizations/${organizationId}/students`)
    .doc();
};

export const addOrganizationStudent = (organizationId, studentData) =>
  firestore()
    .collection(`organizations/${organizationId}/students`)
    .add(studentData);

export const updateOrganizationStudent = async (
  organizationId,
  { id, ...studentData }
) => {
  try {
    await setDocument({
      path: `organizations/${organizationId}/students/${id}`,
      data: studentData,
    });
  } catch (error) {
    return error;
  }
};

export const createFamilyMemberAccount = (studentId, data) =>
  functions().httpsCallable('callables-students-addFamilyMember')({
    studentId,
    ...data,
  });

export const updateFamilyMemberAccount = async (studentId, data) => {
  if (!data || !data.currentOrganization || !data.uid || !studentId) return;

  const { currentOrganization, ...rest } = data;

  try {
    // Update student.family map.
    await setDocument({
      path: `organizations/${currentOrganization}/students/${studentId}`,
      data: { family: { [data.uid]: rest } },
    });

    // Update user information inside users sub-collection.
    await setDocument({
      path: `organizations/${currentOrganization}/users/${data.uid}`,
      data: rest,
    });
  } catch (error) {
    return error;
  }
};
