import React from 'react';
import { Table } from 'semantic-ui-react';

const StudentRatio = () => (
  <Table singleLine basic="very">
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell />
        <Table.HeaderCell>Kids</Table.HeaderCell>
        <Table.HeaderCell>Staff</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      <Table.Row>
        <Table.Cell>Bunnies</Table.Cell>
        <Table.Cell>4</Table.Cell>
        <Table.Cell>6</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Butterflies</Table.Cell>
        <Table.Cell>6</Table.Cell>
        <Table.Cell>3</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Caterpillars</Table.Cell>
        <Table.Cell>5</Table.Cell>
        <Table.Cell>7</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Elephants</Table.Cell>
        <Table.Cell>5</Table.Cell>
        <Table.Cell>7</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Frogs</Table.Cell>
        <Table.Cell>5</Table.Cell>
        <Table.Cell>7</Table.Cell>
      </Table.Row>
    </Table.Body>
  </Table>
);

export default StudentRatio;
