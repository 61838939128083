import React from 'react';
import { Container, Header, Segment, Grid } from 'semantic-ui-react';

const Footer = props => (
  <Segment vertical style={{ padding: '3em 0em' }} className="footer">
    <Container>
      <Grid divided inverted stackable>
        <Grid.Row>
          <Grid.Column width={10}>
            <Header as="h4" inverted>
              About
            </Header>
            <p id="blencorp">
              Unfurlough.us is a{' '}
              <a
                href="http://en.wikipedia.org/wiki/Open_source"
                target="_blank"
                rel="noopener noreferrer"
              >
                React{' '}
              </a>
              app on top of <a href="http://firebase.google.com">Firebase</a>{' '}
              built by{' '}
              <a
                href="http://www.moxit.com/?source=unfurlough.us"
                target="_blank"
                rel="noopener noreferrer"
              >
                Moxit
              </a>
              &amp;{' '}
              <a
                href="http://www.blencorp.com/?source=unfurlough.us"
                target="_blank"
                rel="noopener noreferrer"
              >
                BLEN Corp
              </a>{' '}
              .
            </p>
            <p>
              Background picture by{' '}
              <a
                href="https://www.instagram.com/ethiogfx/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Bereket Essayas
              </a>
              .
            </p>
            <p>
              Media inquires or questions? Please{' '}
              <a href="http://www.blencorp.com/contact/">contact us</a>.
            </p>
          </Grid.Column>
          <Grid.Column width={6}>
            <Header as="h4" inverted>
              Notice
            </Header>
            <p>
              For federal workers, it is important to note that they should
              check the ethics guidance for their individual agency before
              engaging in outside work. Furlough or not, they are still
              employees of the Federal government. More information can be found
              at{' '}
              <a
                href="https://www.oge.gov"
                target="_blank"
                rel="noopener noreferrer"
                rel="noopener noreferrer"
              >
                OGE.gov
              </a>
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  </Segment>
);

export default Footer;
