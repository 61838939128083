import { PERMISSION_CHANGED } from '../actions/types';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case PERMISSION_CHANGED:
      return { ...action.permissions };
    default:
      return state;
  }
};
