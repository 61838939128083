import React from 'react';
import { Grid, Header, Segment } from 'semantic-ui-react';

// import StaffCounter from './StaffCounter';

const Stats = props => {
  const signedInStudents = Math.floor(Math.random() * 100);
  const clockedInStaff = Math.floor(signedInStudents / 6);
  const unreadMessages = Math.floor(Math.random() * 100);
  const waitingList = Math.floor(Math.random() * 100);
  const reminders = Math.floor(Math.random() * 25) + 3;

  const items = [
    {
      key: 'signedInStudents',
      label: 'Signed In Students',
      value: `${signedInStudents}`,
      color: 'teal',
    },
    {
      key: 'clockedInStaff',
      label: 'Clocked-in Staff',
      value: `${clockedInStaff}`,
      color: 'purple',
    },
    {
      key: 'unreadMessages',
      label: 'Unread Messages',
      value: `${unreadMessages}`,
      color: 'red',
    },
    {
      key: 'waitingList',
      label: 'Waiting List',
      value: `${waitingList}`,
      color: 'yellow',
    },
    {
      key: 'reminders',
      label: 'Reminders',
      value: `${reminders}`,
      color: 'green',
    },
  ];

  // return <Statistic.Group widths="five" items={items} />;
  const stats = items.map((item, key) => (
    <Grid.Column key={key}>
      <Segment inverted color={item.color}>
        <div>{item.label}</div>
        <Header
          size="huge"
          style={{
            fontSize: '3em',
            fontWeight: 'normal',
            letterSpacing: '-1px',
          }}
        >
          {item.value}
        </Header>
      </Segment>
    </Grid.Column>
  ));

  // return <Statistic.Group widths="four" items={items} />;
  return (
    <Grid container stackable>
      <Grid.Row columns={items.length}>{stats}</Grid.Row>
    </Grid>
  );
};

export default Stats;
