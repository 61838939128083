import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';

// Import store
import store from './redux/store';

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

/***
 *
 * This is important for firebase hosting to work properly.
 * https://www.reddit.com/r/Firebase/comments/6u9n3a/service_worker_not_fetching_new_content_from/
 * "headers": [ { "source": "service-worker.js", "headers": [ { "key" : "Cache-Control", "value" : "no-cache" } ] }]
 */

// registerServiceWorker();
unregister();
