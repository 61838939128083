import {
  ORGANIZATION_SELECTED,
  MOXIT_ORGANIZATIONS_FETCHED,
} from '../actions/types';

const initialState = {
  currentOrganization: {
    setup: {
      firstLocationCreated: false,
      firstRoomCreated: false,
      firstStaffCreated: false,
      firstStudentCreated: false,
    },
    setupComplete: null,
  },
  list: {},
  last: {},
  count: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ORGANIZATION_SELECTED:
      return {
        ...state,
        currentOrganization: {
          ...state.currentOrganization,
          ...action.organization,
        },
      };

    case MOXIT_ORGANIZATIONS_FETCHED: {
      const list = { ...state.list, ...action.organizations.data };
      return {
        ...state,
        list,
        last: action.organizations.lastDocument,
        count: Object.keys(list).length,
      };
    }

    default:
      return state;
  }
};
