import _ from 'lodash';
import {
  ROOMS_LISTENER_STARTED,
  ROOMS_FETCHED,
  ROOM_SELECTED,
  ROOM_SELECTION_CLEARED,
  ROOM_UPDATED,
} from '../actions/types';

const initialState = {
  selectedRoom: {},
  count: 0,
  list: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ROOMS_LISTENER_STARTED:
      return { ...state };

    case ROOMS_FETCHED:
      return {
        ...state,
        count: action.count,
        list: _.orderBy(action.rooms, room => room.name.toLowerCase()),
      };

    case ROOM_SELECTED:
      return { ...state, selectedRoom: action.room };

    // Replace selected with the newly updated data.
    // New data is fetched by the listener as soon as listener receives the update.
    case ROOM_UPDATED: {
      return {
        ...state,
        selectedRoom: _.find(state.list, { id: state.selectedRoom.id }),
      };
    }

    case ROOM_SELECTION_CLEARED:
      return { ...state, selectedRoom: {} };

    default:
      return state;
  }
};
