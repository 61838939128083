// Import pages
import Dashboard from '../../Pages/Dashboard';
import Students from '../../Pages/Students';
import Rooms from '../../Pages/Rooms';
import Staff from '../../Pages/Staff';
import Devices from '../../Pages/Devices';
import Locations from '../../Pages/Locations';
import Organizations from '../../Pages/Organizations';
import OrganizationDetail from '../../Pages/OrganizationDetail';
import Profile from '../../Pages/Profile';
import Settings from '../../Pages/Settings';
import Search from '../../Pages/Search';
import Setup from '../Setup';

export default [
  // {
  //   to: '/organizations',
  //   name: 'Franchisees',
  //   exact: true,
  //   enabled: true,
  //   hasMenu: true,
  //   restrictedRoute: true,
  //   component: Organizations,
  //   permission: 'can_list_moxit_organizations',
  // },
  // // {
  // //   to: '/',
  // //   name: 'Dashboard',
  // //   exact: true,
  // //   enabled: true,
  // //   hasMenu: true,
  // //   component: Dashboard,
  // //   permission: '*',
  // // },
  // {
  //   to: '/students',
  //   name: 'Students',
  //   exact: true,
  //   enabled: true,
  //   hasMenu: true,
  //   component: Students,
  //   permission: 'can_list_students',
  // },
  // {
  //   to: '/rooms',
  //   name: 'Rooms',
  //   exact: true,
  //   enabled: true,
  //   hasMenu: true,
  //   restrictedRoute: true,
  //   component: Rooms,
  //   permission: 'can_list_rooms',
  // },
  // {
  //   to: '/staff',
  //   name: 'Staff',
  //   exact: true,
  //   enabled: true,
  //   hasMenu: true,
  //   restrictedRoute: true,
  //   component: Staff,
  //   permission: 'can_list_staff',
  // },
  // {
  //   to: '/locations',
  //   name: 'Locations',
  //   exact: true,
  //   enabled: true,
  //   hasMenu: true,
  //   restrictedRoute: true,
  //   component: Locations,
  //   permission: 'can_list_locations',
  // },
  // {
  //   to: '/devices',
  //   name: 'Devices',
  //   exact: true,
  //   enabled: true,
  //   hasMenu: true,
  //   restrictedRoute: true,
  //   component: Devices,
  //   permission: 'can_list_devices',
  // },
  // {
  //   to: '/organizations/:organizationId',
  //   name: 'OrganizationDetail',
  //   exact: false,
  //   enabled: true,
  //   hasMenu: false,
  //   restrictedRoute: true,
  //   component: OrganizationDetail,
  //   permission: 'can_list_moxit_organizations',
  // },
  // {
  //   to: '/profile',
  //   name: 'Profile',
  //   exact: true,
  //   enabled: true,
  //   hasMenu: false,
  //   restrictedRoute: true,
  //   component: Profile,
  //   permission: '*',
  // },
  // {
  //   to: '/settings',
  //   name: 'Settings',
  //   exact: true,
  //   enabled: true,
  //   hasMenu: false,
  //   restrictedRoute: true,
  //   component: Settings,
  //   permission: '*',
  // },
  // {
  //   to: '/setup',
  //   name: 'Setup',
  //   exact: true,
  //   enabled: true,
  //   hasMenu: false,
  //   restrictedRoute: true,
  //   component: Setup,
  //   permission: '*',
  // },
  // {
  //   to: '/search',
  //   name: 'Search',
  //   exact: true,
  //   enabled: true,
  //   hasMenu: false,
  //   restrictedRoute: true,
  //   component: Search,
  //   permission: '*',
  // },
];
