import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';
import _ from 'lodash';

const ShowSuccess = ({ iconName = 'check', header = 'success', content }) =>
  !_.isEmpty(content) ? (
    <Message positive icon={iconName} header={header} content={content} />
  ) : null;

ShowSuccess.propTypes = {
  icon: PropTypes.string,
  header: PropTypes.string,
  content: PropTypes.string,
};

export default ShowSuccess;
