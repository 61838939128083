import _ from 'lodash';
import {
  LOCATIONS_LISTENER_STARTED,
  LOCATIONS_FETCHED,
  LOCATION_ADDED,
  LOCATION_SELECTED,
  LOCATION_SELECTION_CLEARED,
  LOCATION_UPDATED,
} from './types';
import {
  organizationLocationsOnListen,
  addOrganizationLocation,
  updateOrganizationLocation,
} from '../../api/firebase/locations';
import store from '../store';

export const locationsListenerStarted = () => ({
  type: LOCATIONS_LISTENER_STARTED,
});

export const locationsFetched = ({ count, locations }) => ({
  type: LOCATIONS_FETCHED,
  count,
  locations,
});

export const locationAdded = location => ({
  type: LOCATION_ADDED,
  location,
});

export const locationUpdated = () => ({
  type: LOCATION_UPDATED,
});

export const locationSelected = location => ({
  type: LOCATION_SELECTED,
  location,
});

export const locationSelectionCleared = () => ({
  type: LOCATION_SELECTION_CLEARED,
});

export const getLocations = () => {
  const locations = store.getState().locations.list;
  if (_.isEmpty(locations)) return null;
  return locations || null;
};

export const startOrganizationLocationsListener = organizationId => dispatch => {
  return organizationLocationsOnListen(
    organizationId,
    data => {
      dispatch(locationsListenerStarted());
      if (data) dispatch(locationsFetched(data));
    },
    error => console.log(error.message)
  );
};

export const organizationAddLocation = (
  organizationId,
  locationData
) => dispatch =>
  addOrganizationLocation(organizationId, locationData).then(location =>
    dispatch(locationAdded(location))
  );

export const organizationUpdateLocation = (
  organizationId,
  locationData
) => dispatch =>
  updateOrganizationLocation(organizationId, locationData).then(location =>
    dispatch(locationUpdated())
  );
