import React, { Component } from 'react';
import {
  InstantSearch,
  SearchBox,
  Hits,
  Highlight,
  Stats,
  SortBy,
  Pagination,
  RefinementList,
  Menu,
} from 'react-instantsearch/dom';
import { Grid, Segment } from 'semantic-ui-react';

const SearchSidebar = () => (
  <div className="search-sidebar">
    <h5>Category</h5>
    <RefinementList attribute="categories" />
    <h5>Brand</h5>
    <RefinementList attribute="brand" withSearchBox />
    <h5>Type</h5>
    <Menu attribute="type" />
  </div>
);
const Hit = ({ hit }) => (
  <div className="hit">
    <div className="hit-image">
      <img src={hit.image} alt="search" />
    </div>
    <div className="hit-content">
      <div className="hit-price"> ${hit.price}</div>
      <div className="hit-name">
        <Highlight attribute="name" hit={hit} />
      </div>
      <div className="hit-description">
        <Highlight attribute="description" hit={hit} />
      </div>
    </div>
  </div>
);

const SearchContent = () => (
  <div className="search-content">
    <div className="search-info">
      <Stats />
      <SortBy
        defaultRefinement="instant_search"
        items={[
          { value: 'instant_search', label: 'Most Relevant' },
          { value: 'instant_search_price_asc', label: 'Lowest Price' },
          { value: 'instant_search_price_desc', label: 'Highest Price' },
        ]}
      />
    </div>
    <Hits hitComponent={Hit} />
    <div className="search-pagination">
      <Pagination showLast />
    </div>
  </div>
);

class FacetedSearch extends Component {
  render() {
    return (
      <InstantSearch
        appId="latency"
        apiKey="6be0576ff61c053d5f9a3225e2a90f76"
        indexName="instant_search"
      >
        <Segment basic>
          <SearchBox
            translations={{ placeholder: 'Search your organization' }}
          />
        </Segment>

        <Segment basic>
          <Grid>
            <Grid.Column width={3}>
              <SearchSidebar />
            </Grid.Column>
            <Grid.Column width={13}>
              <SearchContent />
            </Grid.Column>
          </Grid>
        </Segment>
      </InstantSearch>
    );
  }
}

FacetedSearch.propTypes = {};

export default FacetedSearch;
