import React from 'react';
import PropTypes from 'prop-types';
import { Message, Icon } from 'semantic-ui-react';

const ShowWaiting = ({
  iconName = 'spinner',
  header = 'Just one second',
  content,
  show = false,
}) =>
  show ? (
    <Message icon>
      <Icon name={iconName} loading={show} />
      <Message.Content>
        <Message.Header>{header}</Message.Header>
        {content}
      </Message.Content>
    </Message>
  ) : null;

ShowWaiting.propTypes = {
  icon: PropTypes.string,
  header: PropTypes.string,
  content: PropTypes.string,
};

export default ShowWaiting;
