import {
  STUDENTS_LISTENER_STARTED,
  ALL_STUDENTS_FETCHED,
  STUDENTS_FETCHED,
  STUDENT_ADDED,
  STUDENT_SELECTED,
  STUDENT_SELECTION_CLEARED,
  STUDENT_UPDATED,
  STUDENT_FAMILY_SELECTION_CLEARED,
  STUDENT_FAMILY_MEMBER_ADDED,
  USER_EMAIL_RESET_LINK_SENT,
  STUDENT_FAMILY_MEMBER_SELECTED,
} from './types';
import {
  getAllGigs,
  organizationStudentsOnListen,
  addOrganizationStudent,
  updateOrganizationStudent,
  createFamilyMemberAccount,
  updateFamilyMemberAccount,
} from '../../api/firebase/students';

import { sendPasswordResetEmail } from '../../api/firebase/account';

export const studentsListenerStarted = () => ({
  type: STUDENTS_LISTENER_STARTED,
});

export const allStudentsFetched = students => ({
  type: ALL_STUDENTS_FETCHED,
  students,
});

export const studentsFetched = ({ count, students }) => ({
  type: STUDENTS_FETCHED,
  count,
  students,
});

export const studentAdded = student => ({
  type: STUDENT_ADDED,
  student,
});

export const studentUpdated = () => ({
  type: STUDENT_UPDATED,
});

export const studentSelected = student => ({
  type: STUDENT_SELECTED,
  student,
});

export const studentSelectionCleared = () => ({
  type: STUDENT_SELECTION_CLEARED,
});

export const emailResetLinkSent = () => ({
  type: USER_EMAIL_RESET_LINK_SENT,
});

export const familyMemberSelected = familyMemberId => ({
  type: STUDENT_FAMILY_MEMBER_SELECTED,
  familyMemberId,
});

export const familySelectionCleared = () => ({
  type: STUDENT_FAMILY_SELECTION_CLEARED,
});

export const familyMemberAdded = (studentId, memberData) => ({
  type: STUDENT_FAMILY_MEMBER_ADDED,
  studentId,
  memberData,
});

export const startOrganizationStudentsListener = organizationId => dispatch => {
  return organizationStudentsOnListen(
    organizationId,
    data => {
      dispatch(studentsListenerStarted());
      if (data) dispatch(studentsFetched(data));
    },
    error => console.log(error.message)
  );
};

export const getGigs = type => async dispatch => {
  try {
    const gigs = await getAllGigs();

    return dispatch(allStudentsFetched(gigs));
  } catch (error) {
    throw Error(error);
  }
  // return getAllGigs(
  //   data => {
  //     dispatch(studentsListenerStarted());
  //     if (data) dispatch(studentsFetched(data));
  //   },
  //   error => console.log(error.message)
  // );
};

export const organizationAddStudent = (
  organizationId,
  studentData
) => dispatch =>
  addOrganizationStudent(organizationId, studentData).then(student =>
    dispatch(studentAdded(student))
  );

export const organizationUpdateStudent = (
  organizationId,
  studentData
) => async dispatch => {
  await updateOrganizationStudent(organizationId, studentData);
  return dispatch(studentUpdated());
};

export const addFamilyMember = (studentId, data) => dispatch =>
  createFamilyMemberAccount(studentId, data).then(response => {
    const { data } = response;

    // Skip bogus emails
    if (data && data.uid && data.email)
      if (data.email.indexOf('moxit_') !== -1)
        dispatch(resetFamilyMemberPassword(data.email));

    return dispatch(familyMemberAdded(studentId, data));
  });

export const updateFamilyMemberData = (studentId, data) => async dispatch => {
  await updateFamilyMemberAccount(studentId, data);
  return dispatch(studentUpdated());
};

export const resetFamilyMemberPassword = email => dispatch =>
  sendPasswordResetEmail(email, {
    url: `${process.env.REACT_APP_PATH}/invited`,
    handleCodeInApp: true,
  }).then(() => dispatch(emailResetLinkSent()));
