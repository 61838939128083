import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';
import _ from 'lodash';

const ShowErrors = ({
  errors,
  content = '',
  icon = 'cancel',
  header = 'Something went wrong!',
}) =>
  !_.isEmpty(errors) || !_.isEmpty(content) ? (
    <Message
      negative
      icon={icon}
      header={header}
      content={content}
      list={
        errors &&
        Object.keys(errors).map(errorKey => (
          <li key={errorKey}>
            <b>{errorKey}: </b>
            {errors[errorKey]}
          </li>
        ))
      }
    />
  ) : null;

ShowErrors.propTypes = {
  errors: PropTypes.object,
  icon: PropTypes.string,
  header: PropTypes.string,
};

export default ShowErrors;
