import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Segment } from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment';
import DatePicker from 'react-datepicker';

// Import components
import InlineError from '../../Messages/InlineError';
import ShowError from '../../Messages/ShowError';
import RoomPicker from '../../Rooms/RoomPicker';

// Import actions
import {
  organizationAddStudent,
  organizationUpdateStudent,
  studentSelectionCleared,
} from '../../../redux/actions/studentActions';

class StudentEnrollmentForm extends Component {
  state = {
    data: {
      id: '',
      enrollmentStatus: true,
      rooms: [],
      tourDate: null,
      enrollmentDate: null,
      graduationDate: null,
      photosAllowed: false,
    },
    loading: false,
    errors: {},
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      students: { selectedStudent },
    } = nextProps;

    if (
      !_.isEmpty(selectedStudent.id) &&
      prevState.data.id !== selectedStudent.id
    ) {
      const {
        tourDate,
        enrollmentDate,
        graduationDate,
        ...rest
      } = selectedStudent;

      return {
        data: {
          tourDate: tourDate ? moment(tourDate, 'x') : null,
          enrollmentDate: enrollmentDate ? moment(enrollmentDate, 'x') : null,
          graduationDate: graduationDate ? moment(graduationDate, 'x') : null,
          ...rest,
        },
      };
    }
    return prevState;
  }

  componentWillUnmount() {
    if (!this.props.clearOnUnmount) return;
    // Clear selection from store.
    this.props.studentSelectionCleared();
  }

  onChange = (e, { name, value, checked }) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: !_.isUndefined(checked) ? checked : value,
      },
      errors: _.omit(this.state.errors, name),
    });
  };

  handleTourDate = tourDate => {
    this.setState({
      data: {
        ...this.state.data,
        tourDate,
      },
      errors: _.omit(this.state.errors, tourDate),
    });
  };

  handleEnrollmentDate = enrollmentDate => {
    this.setState({
      data: {
        ...this.state.data,
        enrollmentDate,
      },
      errors: _.omit(this.state.errors, enrollmentDate),
    });
  };

  handleGraduationDate = graduationDate => {
    this.setState({
      data: {
        ...this.state.data,
        graduationDate,
      },
      errors: _.omit(this.state.errors, graduationDate),
    });
  };

  validate = data => {
    const errors = {};

    return errors;
  };

  onSubmit = e => {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });

    if (_.isEmpty(errors)) {
      const {
        data: { id, tourDate, graduationDate, enrollmentDate, ...rest },
      } = this.state;
      const { currentOrganization } = this.props;

      this.setState({ loading: true });

      const formData = {
        tourDate: tourDate ? tourDate.utc().valueOf() : null,
        graduationDate: graduationDate ? graduationDate.utc().valueOf() : null,
        enrollmentDate: enrollmentDate ? enrollmentDate.utc().valueOf() : null,
        ...rest,
      };

      if (currentOrganization && currentOrganization.id)
        if (id) {
          // Update.
          this.props
            .organizationUpdateStudent(currentOrganization.id, {
              id,
              organization: currentOrganization.id,
              ...formData,
            })
            .then(() => {
              // Used to close wrapper HOC. e.g Sliding panel, modal...
              if (this.props.onClose) {
                this.props.onClose('updated');
                return;
              }
              this.setState({ loading: false });
            })
            .catch(error =>
              this.setState({
                loading: false,
                errors: { 'Unable to Update': error.message },
              })
            );
        } else {
          // New entry.
          this.props
            .organizationAddStudent(currentOrganization.id, {
              organization: currentOrganization.id,
              ...formData,
            })
            .then(() => {
              // Used to close wrapper HOC. e.g Sliding panel, modal...
              if (this.props.onClose) {
                this.props.onClose('added');
                return;
              }
              this.setState({ loading: false });
            })
            .catch(error =>
              this.setState({
                loading: false,
                errors: { 'Unable to Add': error.message },
              })
            );
        }
    }
  };

  render() {
    const { data, loading, errors } = this.state;
    return (
      <Segment basic textAlign="left">
        <ShowError errors={errors} />
        <Form
          id="student-enrollment-information-form"
          onSubmit={this.onSubmit}
          loading={loading}
          noValidate
        >
          <Form.Field error={!!errors.enrollmentStatus}>
            <label>Enrollment Status</label>
            <Form.Radio
              toggle
              id="enrollmentStatus"
              name="enrollmentStatus"
              onChange={this.onChange}
              checked={data.enrollmentStatus}
            />
            {errors.enrollmentStatus && (
              <InlineError text={errors.enrollmentStatus} />
            )}
          </Form.Field>

          <RoomPicker
            error={!!errors.rooms}
            id="rooms"
            name="rooms"
            label="Rooms"
            placeholder="Room"
            value={data.rooms}
            selection
            multiple
            search
            onChange={this.onChange}
          >
            {errors.rooms && <InlineError text={errors.rooms} />}
          </RoomPicker>

          <Form.Field
            fluid
            isClearable
            id="tourDate"
            name="tourDate"
            label="Tour Date"
            placeholder="Select Tour Date"
            dateFormat="YYYY-MM-DD"
            minDate={moment()}
            control={DatePicker}
            selected={data.tourDate}
            onChange={this.handleTourDate}
            error={!!errors.tourDate}
          >
            {errors.tourDate && <InlineError text={errors.tourDate} />}
          </Form.Field>

          <Form.Field
            fluid
            isClearable
            id="enrollmentDate"
            name="enrollmentDate"
            label="Enrollment Date"
            placeholder="Select Enrollment Date"
            dateFormat="YYYY-MM-DD"
            minDate={moment()}
            control={DatePicker}
            selected={data.enrollmentDate}
            onChange={this.handleEnrollmentDate}
            error={!!errors.enrollmentDate}
          >
            {errors.enrollmentDate && (
              <InlineError text={errors.enrollmentDate} />
            )}
          </Form.Field>

          <Form.Field
            fluid
            isClearable
            id="graduationDate"
            name="graduationDate"
            label="Graduation Date"
            placeholder="Select Graduation Date"
            dateFormat="YYYY-MM-DD"
            minDate={moment()}
            control={DatePicker}
            selected={data.graduationDate}
            onChange={this.handleGraduationDate}
            error={!!errors.graduationDate}
          >
            {errors.graduationDate && (
              <InlineError text={errors.graduationDate} />
            )}
          </Form.Field>

          <Form.Field error={!!errors.enrollmentStatus}>
            <label>Photos Allowed</label>
            <Form.Radio
              toggle
              id="photosAllowed"
              name="photosAllowed"
              onChange={this.onChange}
              checked={data.photosAllowed}
            />
            {errors.photosAllowed && (
              <InlineError text={errors.photosAllowed} />
            )}
          </Form.Field>
          <br />
          <br />

          <Form.Group>
            <Form.Button primary content={data.id ? 'Update' : 'Save'} />
            {!this.props.hideCancel && (
              <Form.Button
                basic
                content="Cancel"
                onClick={e => {
                  if (e) e.preventDefault();
                  if (this.props.onClose) this.props.onClose();
                }}
              />
            )}
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

StudentEnrollmentForm.propTypes = {
  currentOrganization: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  students: PropTypes.shape({
    selectedStudent: PropTypes.shape({
      id: PropTypes.string,
    }),
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
  }),
  organizationAddStudent: PropTypes.func,
  organizationUpdateStudent: PropTypes.func,
  studentSelectionCleared: PropTypes.func,
};

const mapStateToProps = state => ({
  currentOrganization: state.organizations.currentOrganization,
  students: state.students,
});

export default connect(
  mapStateToProps,
  {
    organizationAddStudent,
    organizationUpdateStudent,
    studentSelectionCleared,
  }
)(StudentEnrollmentForm);
