import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Form } from 'semantic-ui-react';
import { getLocations } from '../../redux/actions/locationActions';

class LocationPicker extends React.Component {
  state = {
    loading: false,
    options: [],
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const locations = getLocations();
    if (_.isEmpty(locations)) return;

    this.setState({ loading: true });
    const options = locations.map(location => ({
      text: location.name,
      value: location.id,
    }));
    this.setState({ loading: false, options });
  };

  render() {
    const {
      staticContext,
      currentOrganization,
      control,
      children,
      dispatch,
      ...rest
    } = this.props;

    return (
      <Form.Dropdown
        {...rest}
        loading={this.state.loading}
        options={this.state.options}
        selectOnBlur={false}
        clearable
        renderLabel={label => ({
          color: 'yellow',
          content: label.text,
          // icon: 'check',
        })}
      />
    );
  }
}

const mapStateToProps = state => ({
  currentOrganization: state.organizations.currentOrganization,
});

export default connect(mapStateToProps)(LocationPicker);
