import React from 'react';
import PropTypes from 'prop-types';
import { Card, Header, List, Table } from 'semantic-ui-react';
import Avatar from 'react-avatar';

const FreelancerCell = ({ student, onClick }) => {
  return (
    <Table.Row key={student.id} onClick={onClick} className="cell">
      <Table.Cell>
        <a href="#">{student.fullName}</a>
      </Table.Cell>
      <Table.Cell>{student.skills}</Table.Cell>
      <Table.Cell>{student.location}</Table.Cell>
    </Table.Row>
  );
};

FreelancerCell.propTypes = {
  students: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default FreelancerCell;
