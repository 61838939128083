import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import _ from 'lodash';

// Import styles.
import 'semantic-ui-css/semantic.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'instantsearch.css/themes/algolia.css';
import 'uppy/dist/uppy.min.css';
import './styles/css/index.css';

// Import actions.
import {
  addAuthListener,
  startUserProfileListener,
} from './redux/actions/userActions';
import { startOrganizationListener } from './redux/actions/organizationActions';
import { startOrganizationPermissionsListener } from './redux/actions/permissionActions';
import { startOrganizationRolesListener } from './redux/actions/roleActions';
import { startOrganizationRoomsListener } from './redux/actions/roomActions';
import { startOrganizationLocationsListener } from './redux/actions/locationActions';

// Import components
import RestrictedRoute from './Components/Routes/RestrictedRoute';
import GuestRoute from './Components/Routes/GuestRoute';
import VerifyRoute from './Components/Routes/VerifyRoute';

// Import pages
import ErrorPage from './Pages/Error';
import Signin from './Pages/Signin';
import Signup from './Pages/Signup';
import ForgotPassword from './Pages/ForgotPassword';
import Action from './Pages/Action';
import EmailSent from './Pages/EmailSent';
import Unauthorized from './Pages/Unauthorized';

// Import API
import { isUserEmailVerified } from './api/firebase/account';

// Set TimeZone to Eastern Time
// moment.tz.setDefault('America/New_York');

// Import app routes.
import routeList from './Components/Routes';

// Import layouts
import ResponsiveContainer from './Components/Layout/ResponsiveContainer';
import Gigs from './Pages/Gigs';
import Freelancers from './Pages/Freelancers';

class App extends Component {
  state = { currentUserId: '', currentOrganizationId: '' };

  componentDidMount = () => {
    this.unsubscribeAuthListener = this.props.addAuthListener();
  };

  // FIXME: Unmount is never called.
  componentWillUnmount = () => {
    if (this.unsubscribeAuthListener) this.unsubscribeAuthListener();
    this.unsubscribeListeners();
  };

  componentDidUpdate = prevProps => {
    const { user, currentOrganization } = this.props;

    if (!isUserEmailVerified()) {
      this.unsubscribeListeners();
      return;
    }

    if (prevProps.user.uid !== user.uid) {
      if (user.uid) this.startProfileListener(user.uid);
    }

    if (
      _.isEmpty(prevProps.currentOrganization) ||
      prevProps.currentOrganization.id !== currentOrganization.id
    ) {
      if (currentOrganization.id) {
        // Unsubscribe active listeners if any
        this.unsubscribeOrganizationListeners();

        this.startOrganizationListener(currentOrganization.id);
        this.startOrganizationPermissionsListener(currentOrganization.id);
        this.startOrganizationRolesListener(currentOrganization.id);
        // this.startOrganizationLocationsListener(currentOrganization.id);
        // this.startOrganizationRoomsListener(currentOrganization.id);
      }
    }
  };

  startProfileListener = uid => {
    this.unsubscribeUserProfileListener = this.props.startUserProfileListener(
      uid
    );
  };

  startOrganizationListener = id => {
    this.unsubscribeOrganizationListener = this.props.startOrganizationListener(
      id
    );
  };

  startOrganizationPermissionsListener = id => {
    this.unsubscribeOrganizationPermissionsListener = this.props.startOrganizationPermissionsListener(
      id
    );
  };

  startOrganizationRolesListener = id => {
    this.unsubscribeOrganizationRoleListener = this.props.startOrganizationRolesListener(
      id
    );
  };

  startOrganizationLocationsListener = id => {
    this.unSubscribeOrganizationLocationsListener = this.props.startOrganizationLocationsListener(
      id
    );
  };

  startOrganizationRoomsListener = id => {
    this.unSubscribeOrganizationRoomsListener = this.props.startOrganizationRoomsListener(
      id
    );
  };

  // All Listeners
  unsubscribeListeners = () => {
    if (this.unsubscribeUserProfileListener)
      this.unsubscribeUserProfileListener();
    if (this.unsubscribeOrganizationListener)
      this.unsubscribeOrganizationListener();
    if (this.unsubscribeOrganizationPermissionsListener)
      this.unsubscribeOrganizationPermissionsListener();
    if (this.unsubscribeOrganizationRoleListener)
      this.unsubscribeOrganizationRoleListener();
    if (this.unSubscribeOrganizationLocationsListener)
      this.unSubscribeOrganizationLocationsListener();
    if (this.unSubscribeOrganizationRoomsListener)
      this.unSubscribeOrganizationRoomsListener();
  };

  // Only Organization Listeners
  unsubscribeOrganizationListeners = () => {
    if (this.unsubscribeOrganizationListener)
      this.unsubscribeOrganizationListener();
    if (this.unsubscribeOrganizationPermissionsListener)
      this.unsubscribeOrganizationPermissionsListener();
    if (this.unsubscribeOrganizationRoleListener)
      this.unsubscribeOrganizationRoleListener();
    if (this.unSubscribeOrganizationLocationsListener)
      this.unSubscribeOrganizationLocationsListener();
    if (this.unSubscribeOrganizationRoomsListener)
      this.unSubscribeOrganizationRoomsListener();
  };

  getRoutes = () => {
    return routeList.map((route, index) => {
      return (
        route.enabled && (
          <RestrictedRoute
            key={index}
            path={route.to}
            component={route.component}
            exact={route.exact}
            routePermission={route.permission}
          />
        )
      );
    });
  };

  render() {
    return (
      <Router>
        <ResponsiveContainer layout={this.props.layout}>
          <Switch>
            {this.getRoutes()}

            <GuestRoute path="/" component={Gigs} exact />
            <GuestRoute path="/gigs" component={Gigs} exact />
            <GuestRoute path="/freelancers" component={Freelancers} exact />
            <GuestRoute path="/setup" component={Gigs} exact />

            <GuestRoute
              path="/signin"
              component={Signin}
              authenticatedRedirect="/profile"
            />
            <GuestRoute
              path="/signup"
              component={Signup}
              authenticatedRedirect="/profile"
            />
            <GuestRoute
              path="/forgot-password"
              component={ForgotPassword}
              authenticatedRedirect="/profile"
            />
            <VerifyRoute path="/verify" component={EmailSent} exact />
            <VerifyRoute path="/action" component={Action} exact />
            <Route path="/401" component={Unauthorized} />
            <Route path="/404" component={ErrorPage} />
            <Route component={ErrorPage} />
          </Switch>
        </ResponsiveContainer>
      </Router>
    );
  }
}

App.propTypes = {
  user: PropTypes.shape({
    uid: PropTypes.string,
  }),
  currentOrganization: PropTypes.shape({
    id: PropTypes.string,
    setup: PropTypes.shape({
      firstLocationCreated: PropTypes.bool,
      firstRoomCreated: PropTypes.bool,
      firstStaffCreated: PropTypes.bool,
      firstStudentCreated: PropTypes.bool,
    }),
    setupComplete: PropTypes.bool,
  }),
  startUserProfileListener: PropTypes.func.isRequired,
  startOrganizationListener: PropTypes.func.isRequired,
  startOrganizationPermissionsListener: PropTypes.func.isRequired,
  startOrganizationRolesListener: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
  currentOrganization: state.organizations.currentOrganization,
  layout: state.layout,
});

export default connect(
  mapStateToProps,
  {
    addAuthListener,
    startUserProfileListener,
    startOrganizationListener,
    startOrganizationPermissionsListener,
    startOrganizationLocationsListener,
    startOrganizationRoomsListener,
    startOrganizationRolesListener,
  }
)(App);
