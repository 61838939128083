import { firestore } from '.';
import { getDocuments, getDocument } from './firestore';

export const addOrganizationLocation = (organizationId, locationData) =>
  firestore()
    .collection(`organizations/${organizationId}/locations`)
    .add(locationData);

export const updateOrganizationLocation = (
  organizationId,
  { id, ...locationData }
) =>
  firestore()
    .doc(`organizations/${organizationId}/locations/${id}`)
    .update(locationData);

export const organizationLocationsOnListen = (
  organizationId,
  next,
  error,
  complete
) =>
  firestore()
    .collection(`organizations/${organizationId}/locations`)
    .orderBy('name', 'asc')
    .onSnapshot(
      querySnapshot =>
        next(
          querySnapshot
            ? {
                count: querySnapshot.size,
                locations: querySnapshot.docs.map(doc => ({
                  id: doc.id,
                  ...doc.data(),
                })),
              }
            : {}
        ),
      error,
      complete
    );

export const fetchLocations = organizationId => {
  let conditions = [];

  return getDocuments({
    path: `organizations/${organizationId}/locations`,
    conditions,
    orderBy: [{ field: 'name', direction: 'asc' }],
  });
};

export const getLocation = (organizationId, locationId) => {
  return getDocument({
    path: `organizations/${organizationId}/locations/${locationId}`,
  });
};
