import React from 'react';
import { Feed } from 'semantic-ui-react';

const ActivityFeed = () => (
  <Feed>
    <Feed.Event>
      <Feed.Label image="https://react.semantic-ui.com/images/avatar/small/jenny.jpg" />
      <Feed.Content>
        <Feed.Date content="1 day ago" />
        <Feed.Summary>
          You added <a href="/">Jenny Hess</a> to your <a href="/">coworker</a>{' '}
          group.
        </Feed.Summary>
      </Feed.Content>
    </Feed.Event>

    <Feed.Event>
      <Feed.Label image="https://react.semantic-ui.com/images/avatar/small/molly.png" />
      <Feed.Content>
        <Feed.Date content="3 days ago" />
        <Feed.Summary>
          You added <a href="/">Molly Malone</a> as a friend.
        </Feed.Summary>
      </Feed.Content>
    </Feed.Event>

    <Feed.Event>
      <Feed.Label image="https://react.semantic-ui.com/images/avatar/small/elliot.jpg" />
      <Feed.Content>
        <Feed.Date content="4 days ago" />
        <Feed.Summary>
          You added <a href="/">Elliot Baker</a> to your{' '}
          <a href="/">musicians</a> group.
        </Feed.Summary>
      </Feed.Content>
    </Feed.Event>
  </Feed>
);

export default ActivityFeed;
