import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Card,
  Icon,
  Segment,
  Header,
  Button,
  Divider,
  Table,
} from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import { filter } from 'lodash';

// Import actions
import { getCurrentUser } from '../../api/firebase/account';
import {
  getGigs,
  startOrganizationStudentsListener,
  studentSelected,
} from '../../redux/actions/studentActions';

// Import Components
import StudentCard from './StudentCard';
import StudentCell from './StudentCell';
import FreelancerCell from './FreelancerCell';
import StudentPersonalInformationForm from '../Forms/Students/StudentPersonalInformationForm';
import FreelancerForm from '../Forms/Students/FreelancerForm';
import StudentDetail from '../Students/StudentDetail';
import FreelancerDetail from '../Students/FreelancerDetail';
import withSlidingPanel from '../Shared/withSlidingPanel';
import withPermission from '../Shared/withPermission';
import { newOrganizationStudent } from '../../api/firebase/students';

const SLIDER_WIDTH = '60%';

const SlidingStudentPersonalInformationFormAdd = withSlidingPanel(
  StudentPersonalInformationForm,
  {
    title: 'Add New Gig',
    width: SLIDER_WIDTH,
  }
);

const FreelancerFormAdd = withSlidingPanel(FreelancerForm, {
  title: 'Add New Freelancer',
  width: SLIDER_WIDTH,
});

const SlidingStudentDetail = withSlidingPanel(StudentDetail, {
  width: SLIDER_WIDTH,
});

const SlidingFreelancerDetail = withSlidingPanel(FreelancerDetail, {
  width: SLIDER_WIDTH,
});

const NewStudentCard = props => (
  <Card className="add-new" {...props}>
    <Card.Content>
      <div className={'center'}>
        <Card.Header as={'h4'}>
          <Icon name="plus" />
        </Card.Header>
        <Card.Description>Add a new Student</Card.Description>
      </div>
    </Card.Content>
  </Card>
);

const NewStudentButton = props => (
  <Button
    primary
    circular
    content="Add New Gig"
    floated="right"
    size="large"
    {...props}
  />
);

const NewFreelancerButton = props => (
  <Button
    primary
    circular
    content="Add your name"
    floated="right"
    size="large"
    {...props}
  />
);

const CreateNewStudent = withPermission(NewStudentButton, 'can_create_student');
const CreateNewFreelancer = withPermission(
  NewFreelancerButton,
  'can_create_student'
);

class StudentsList extends Component {
  state = {
    isAddOpen: false,
    isFreelancerAddOpen: false,
    isEditOpen: false,
    isFreelancerEditOpen: false,
  };

  componentDidMount = () => {
    // const { type } = this.props;

    // console.log({ type });

    // this.startListeners();
    this.props.getGigs();
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { currentOrganization, type } = this.props;

    if (
      !prevProps.currentOrganization ||
      prevProps.currentOrganization.id !== currentOrganization.id
    ) {
      // this.startListeners();
      this.props.getGigs();
    }
  };

  componentWillUnmount = () => {
    this.unsubscribeListeners();
  };

  startListeners = () => {
    const {
      currentOrganization: { id },
    } = this.props;

    if (id) {
      this.unsubscribeListeners();
      this.unSubscribeOrganizationStudentsListener = this.props.startOrganizationStudentsListener(
        id
      );
    }
  };

  unsubscribeListeners = () => {
    if (this.unSubscribeOrganizationStudentsListener)
      this.unSubscribeOrganizationStudentsListener();
  };

  renderPageTitle = count => {
    const {
      type,
      // user,
      // students: { list },
      currentOrganization: { id },
    } = this.props;

    const sectionTitle =
      type === 'gigs' ? 'Available Gigs' : 'Available Freelancers';

    const buttonTitle =
      type === 'gigs'
        ? 'Sign up to add a gig'
        : 'Need a job? Sign up to add your name';

    const actionButton =
      type === 'gigs' ? (
        <NewStudentButton
          onClick={() => {
            if (id) {
              const newStudent = newOrganizationStudent(id);
              // Initialize new student's id. The student id is useful to upload to firebase storage.
              this.props.studentSelected({
                id: newStudent.id,
                type: this.props.type,
              });
            }

            this.setState({ isAddOpen: true });
          }}
        />
      ) : (
        <NewFreelancerButton
          onClick={() => {
            if (id) {
              const newStudent = newOrganizationStudent(id);
              // Initialize new student's id. The student id is useful to upload to firebase storage.
              this.props.studentSelected({
                id: newStudent.id,
                type: this.props.type,
              });
            }

            this.setState({ isFreelancerAddOpen: true });
          }}
        />
      );

    const button = getCurrentUser() ? (
      actionButton
    ) : (
      <Button
        primary
        circular
        content={buttonTitle}
        as={Link}
        to="/signup"
        floated="right"
        size="large"
        icon="lock"
        labelPosition="left"
      />
    );

    return (
      <div className="page-title">
        <Segment basic clearing className="no-padding">
          <Header as="h1" floated="left">
            {sectionTitle} ({count})
          </Header>

          <div className="action-button">{button}</div>
        </Segment>
        <Divider />
      </div>
    );
  };

  renderStudentList = () => {
    const {
      students: { list },
      itemsPerRow,
      currentOrganization: { id },
    } = this.props;

    return (
      <Card.Group stackable itemsPerRow={itemsPerRow || 4}>
        {list.map(student => (
          <StudentCard
            key={student.id}
            student={student}
            onClick={() => {
              this.props.studentSelected(student);
              this.setState({ isEditOpen: true });
            }}
          />
        ))}
      </Card.Group>
    );
  };

  renderStudentTable = () => {
    const {
      students: { list },
    } = this.props;

    const count = filter(list, l => l.type === 'gigs');

    return (
      <div>
        {this.renderPageTitle(count && count.length)}

        <Table striped selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Type of Work</Table.HeaderCell>
              {/* <Table.HeaderCell>
                Project / Position Description
              </Table.HeaderCell> */}
              <Table.HeaderCell>Company</Table.HeaderCell>
              <Table.HeaderCell>Sector</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {list &&
              list.map(student => {
                if (student.type === 'gigs')
                  return (
                    <StudentCell
                      key={student.id}
                      student={student}
                      onClick={() => {
                        this.props.studentSelected(student);
                        this.setState({ isEditOpen: true });
                      }}
                    />
                  );

                return null;
              })}
          </Table.Body>
        </Table>
      </div>
    );
  };

  renderFreelancerTable = () => {
    const {
      students: { list },
    } = this.props;

    const count = filter(list, l => l.type === 'gigs');

    if (list) {
      return (
        <div>
          {this.renderPageTitle(count && count.length)}

          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Skills/Interst</Table.HeaderCell>
                <Table.HeaderCell>Location</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {list.map(student => {
                if (student.type === 'freelancers')
                  return (
                    <FreelancerCell
                      key={student.id}
                      student={student}
                      onClick={() => {
                        this.props.studentSelected(student);
                        this.setState({ isFreelancerEditOpen: true });
                      }}
                    />
                  );

                return null;
              })}
            </Table.Body>
          </Table>
        </div>
      );
    }

    return null;
  };

  renderSlidingForms = () => {
    const {
      type,
      students: { selectedStudent },
    } = this.props;

    return (
      <div>
        <SlidingStudentPersonalInformationFormAdd
          type={type}
          // image={
          //   selectedStudent && selectedStudent.picture
          //     ? selectedStudent.picture
          //     : null
          // }
          // clearOnUnmount
          isOpen={this.state.isAddOpen}
          onClose={() => {
            this.props.getGigs();
            this.setState({ isAddOpen: false });
          }}
        />
        <FreelancerFormAdd
          type={type}
          // image={
          //   selectedStudent && selectedStudent.picture
          //     ? selectedStudent.picture
          //     : null
          // }
          // clearOnUnmount
          isOpen={this.state.isFreelancerAddOpen}
          onClose={() => {
            this.props.getGigs();
            this.setState({ isFreelancerAddOpen: false });
          }}
        />
        <SlidingStudentDetail
          title={selectedStudent && selectedStudent.typeOfWork}
          // image={selectedStudent.picture}
          isOpen={this.state.isEditOpen}
          onClose={() => {
            this.props.getGigs();
            this.setState({ isEditOpen: false });
          }}
        />
        <SlidingFreelancerDetail
          title={selectedStudent && selectedStudent.fullName}
          // image={selectedStudent.picture}
          isOpen={this.state.isFreelancerEditOpen}
          onClose={() => {
            this.props.getGigs();
            this.setState({ isFreelancerEditOpen: false });
          }}
        />
      </div>
    );
  };

  render() {
    const { type } = this.props;
    return (
      <div>
        {type === 'gigs'
          ? this.renderStudentTable()
          : this.renderFreelancerTable()}
        {this.renderSlidingForms()}
      </div>
    );
  }
}

StudentsList.defaultProps = {
  itemsPerRow: 4,
};

StudentsList.propTypes = {
  students: PropTypes.shape({
    selectedStudent: PropTypes.shape({
      id: PropTypes.string,
    }),
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
  }),
  startOrganizationStudentsListener: PropTypes.func.isRequired,
  studentSelected: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  user: state.user,
  students: state.students,
  currentOrganization: state.organizations.currentOrganization,
});

export default connect(
  mapStateToProps,
  { getGigs, startOrganizationStudentsListener, studentSelected }
)(StudentsList);
