import React from 'react';
import { Dropdown } from 'semantic-ui-react';

const options = [
  {
    text: 'Child Care Center',
    value: 'childCareCenter',
    key: 'childCareCenter',
  },
  {
    text: 'Home Care',
    value: 'homeCare',
    key: 'homeCare',
  },
  {
    text: 'Sitters at Home',
    value: 'nanny',
    key: 'nanny',
  },
  {
    text: 'After School',
    value: 'afterSchool',
    key: 'afterSchool',
  },
];

const LocationTypePicker = ({ staticContext, control, children, ...rest }) => {
  return <Dropdown {...rest} options={options} selectOnBlur={false} />;
};

export default LocationTypePicker;
