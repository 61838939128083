import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Segment, Header, Grid } from 'semantic-ui-react';

import OrganizationMenu from './OrganizationMenu';

// const OrganizationBanner = props => {
class OrganizationBanner extends Component {
  renderPageTitle = () => {
    const {
      organizations: { currentOrganization },
    } = this.props;

    return (
      <Segment basic clearing>
        <Header as="h1" floated="left">
          {currentOrganization.name}
        </Header>
      </Segment>
    );
  };

  render() {
    const {
      organizations: { currentOrganization },
    } = this.props;

    return (
      <Grid container stackable>
        <Grid.Row columns={2} verticalAlign="middle" className="top">
          <Grid.Column width={10}>
            <Header as="h2">
              {/* <Avatar
                name={currentOrganization.name}
                round={4}
                size={48}
                // className="right floated"
              />{' '} */}
              {currentOrganization.name}
              <Header.Subheader>
                {`${currentOrganization.city ||
                  ''}, ${currentOrganization.state || ''}`}
              </Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column width={6} textAlign="right">
            <Button
              color="green"
              labelPosition="right"
              icon="right chevron"
              content="See Details"
              onClick={() => this.props.history.replace('/')}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1} className="no-margin no-padding text-center">
          <Grid.Column>
            <OrganizationMenu />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  organizations: state.organizations,
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(OrganizationBanner)
);
