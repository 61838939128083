import { firestore, functions } from '.';
import { updateDocument } from './firestore';

export const organizationStaffOnListen = (
  organizationId,
  next,
  error,
  complete
) =>
  firestore()
    .collection(`organizations/${organizationId}/users`)
    .where('staff', '==', true)
    .orderBy('firstName', 'asc')
    .limit(50)
    .onSnapshot(
      querySnapshot =>
        next(
          querySnapshot
            ? {
                count: querySnapshot.size,
                staff: querySnapshot.docs.map(doc => ({
                  uid: doc.id,
                  ...doc.data(),
                })),
              }
            : {}
        ),
      error,
      complete
    );

export const addOrganizationStaff = (organizationId, uid, staffData) => {
  if (!organizationId || !uid) {
    console.error('Missing organizationId or uid');
    return null;
  }
  return firestore()
    .doc(`organizations/${organizationId}/users/${uid}`)
    .set(staffData, { merge: true });
};

export const updateOrganizationStaff = (
  organizationId,
  { uid, ...staffData }
) => {
  return updateDocument({
    path: `organizations/${organizationId}/users/${uid}`,
    data: staffData,
  });
};
export const createStaffAccount = staffData =>
  functions().httpsCallable('callables-staff-addStaff')(staffData);

/**
 * Cool implementation but there's now way to creat a user account from client
 * signup is used to create own account.
 */

// export const createStaffAccount = async staffData => {
//   try {
//     let userRecord = {};

//     const email =
//       staffData.email ||
//       `moxit_${Date.now()}_${_.snakeCase(
//         staffData.displayName
//       ).toLowerCase()}@moxit.com`;

//     try {
//       userRecord = await signUpWithEmail({
//         email,
//         password: generator.generate({
//           length: 10,
//           numbers: true,
//         }),
//       });
//       // console.log(userRecord);
//     } catch (error) {
//       console.log('Email already exists');
//       // Email exists, therefore search it in the organization's user collection.
//       if (error && error.code && error.code === 'auth/email-already-in-use') {
//         if (staffData.currentOrganization) {
//           const userRecord = await getOrganizationUsersByEmail(
//             staffData.currentOrganization,
//             email,
//             staffData
//           );
//           console.log(userRecord);
//         }
//       }
//     }

//     if (staffData.currentOrganization && userRecord && userRecord.uid) {
//       const organizationId = staffData.currentOrganization;
//       const uid = userRecord.uid;

//       await firestore()
//         .doc(`organizations/${organizationId}/users/${uid}`)
//         .set(
//           {
//             ...staffData,
//             defaultOrganization: organizationId,
//             organizations: { [organizationId]: true },
//           },
//           { merge: true }
//         );

//       return userRecord;
//     }
//     return null;
//   } catch (error) {
//     console.log(error);
//   }
// };
