import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Header,
  Modal,
  Segment,
  Container,
} from 'semantic-ui-react';

// Import components
import StudentPersonalInformationForm from '../Forms/Students/StudentPersonalInformationForm';
import FreelancerForm from '../Forms/Students/FreelancerForm';
import StudentEnrollmentForm from '../Forms/Students/StudentEnrollmentForm';
import StudentFamilyForm from '../Forms/Students/StudentFamilyForm';
import FreelancerDetailInformation from './FreelancerDetailInformation';
import StudentEnrollment from './StudentEnrollment';
import FamilyList from './FamilyList';
import DocumentUploader from '../Upload/DocumentUploader';
import { userHasPermission } from '../../api/firebase/account';
import StudentDocumentList from './StudentDocumentList';

import {
  DOC,
  DOCX,
  XLS,
  XLSX,
  PDF,
  TXT,
  PNG,
  JPEG,
  CSV,
} from '../../helpers/mimeTypes';

// Import actions.
import {
  studentSelectionCleared,
  organizationUpdateStudent,
} from '../../redux/actions/studentActions';

class FreelancerDetail extends Component {
  state = { editPersonalInfo: false, editEnrollment: false, addFamily: false };

  componentWillUnmount() {
    this.props.studentSelectionCleared();
  }

  processUploadedDocuments = successful => {
    if (successful && successful.length) {
      const {
        students: { selectedStudent },
        currentOrganization,
        user: { uid, email, firstName, lastName },
      } = this.props;

      const documentUploadPath = `organizations/${
        currentOrganization.id
      }/students/${selectedStudent.id}/documents`;

      let documents = {};

      successful.forEach(uploaded => {
        const name = uploaded.name ? uploaded.name.split('.')[0] : 'Unknown';

        documents[uploaded.meta.refId] = {
          name,
          documentId: uploaded.meta.refId,
          path: documentUploadPath,
          type: uploaded.type,
          size: uploaded.size,
          extension: uploaded.extension,
          progress: uploaded.progress,
          meta: uploaded.meta,
          downloadUrl: uploaded.downloadUrl,
          uploadedBy: { uid, email, name: `${firstName} ${lastName}` },
          uploadedDate: Date.now(),
          tags: [],
        };
      });

      this.setState({ documents }, () => this.saveChanges());
    }
  };

  saveChanges = () => {
    const {
      students: { selectedStudent },
      currentOrganization: { id },
    } = this.props;
    const { documents } = this.state;

    if (selectedStudent && selectedStudent.id && id) {
      const studentData = { ...selectedStudent, documents };
      this.props.organizationUpdateStudent(id, studentData);
    }
  };

  renderPersonalInfoBlock = () => {
    const { editPersonalInfo } = this.state;
    return (
      <div>
        <Segment basic clearing className="no-padding">
          {userHasPermission('can_edit_student') && (
            <Button
              primary
              floated="right"
              content={!this.state.editPersonalInfo ? 'Edit' : 'Cancel'}
              size="tiny"
              className="round"
              onClick={() =>
                this.setState({
                  editPersonalInfo: !this.state.editPersonalInfo,
                })
              }
            />
          )}

          <Header as="h2" floated="left" content="Personal Info" />
        </Segment>

        <Segment className="no-shadow no-border top-border">
          {editPersonalInfo ? (
            <FreelancerDetailInformation hideCancel clearOnUnmount={false} />
          ) : (
            <Segment basic clearing>
              <FreelancerDetailInformation />
            </Segment>
          )}
        </Segment>
      </div>
    );
  };

  renderEnrollmentBlock = () => {
    const { editEnrollment } = this.state;
    return (
      <div>
        <Segment basic clearing className="no-padding">
          {userHasPermission('can_edit_student') && (
            <Button
              primary
              floated="right"
              content={!this.state.editEnrollment ? 'Edit' : 'Cancel'}
              size="tiny"
              className="round"
              onClick={() =>
                this.setState({
                  editEnrollment: !this.state.editEnrollment,
                })
              }
            />
          )}

          <Header as="h2" floated="left" content="Enrollment" />
        </Segment>

        <Segment className="no-shadow no-border top-border">
          {editEnrollment ? (
            <StudentEnrollmentForm hideCancel clearOnUnmount={false} />
          ) : (
            <Segment basic clearing>
              <StudentEnrollment />
            </Segment>
          )}
        </Segment>
      </div>
    );
  };

  renderGigDetails = () => {
    const { editPersonalInfo } = this.state;
    return (
      <div>
        <Segment basic clearing className="no-padding">
          {userHasPermission('can_edit_student') && (
            <Button
              primary
              floated="right"
              content={!this.state.editPersonalInfo ? 'Edit' : 'Cancel'}
              size="tiny"
              className="round"
              onClick={() =>
                this.setState({
                  editPersonalInfo: !this.state.editPersonalInfo,
                })
              }
            />
          )}

          {/* <Header as="h2" floated="left" content="Personal Info" /> */}
        </Segment>

        <Segment className="no-shadow no-border">
          {editPersonalInfo ? (
            <FreelancerForm hideCancel clearOnUnmount={false} />
          ) : (
            <Segment basic clearing>
              <FreelancerDetailInformation />
            </Segment>
          )}
        </Segment>
      </div>
    );
  };

  render() {
    return (
      <Segment basic>
        <Container fluid className="no-padding no-margin">
          <Grid stackable columns="equal">
            <Grid.Row stretched>
              <Grid.Column>{this.renderGigDetails()}</Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    );
  }

  renderOld() {
    const {
      currentOrganization,
      students: { selectedStudent },
    } = this.props;

    return (
      <Container fluid className="no-padding no-margin">
        <Grid stackable columns="equal">
          {/* <Grid.Row> */}
          <Grid.Column>{this.renderPersonalInfoBlock()}</Grid.Column>
          <Grid.Column>{this.renderEnrollmentBlock()}</Grid.Column>
          {/* </Grid.Row> */}
        </Grid>

        <Grid stackable columns="equal">
          <Grid.Column>
            <Modal
              open={this.state.addFamily}
              onClose={() => this.setState({ addFamily: false })}
              header="Add Family Member or Emergency Contact"
              content={
                <StudentFamilyForm
                  done={() => this.setState({ addFamily: false })}
                />
              }
            />

            <Segment basic clearing>
              <Segment basic clearing className="no-padding">
                {userHasPermission('can_edit_student') && (
                  <Button
                    primary
                    icon="add"
                    floated="right"
                    content="Add"
                    className="round"
                    onClick={() => this.setState({ addFamily: true })}
                  />
                )}

                <Header as="h2" content="Family & Emergency Contact" />
              </Segment>

              <Segment className="no-shadow no-border top-border">
                <FamilyList />
              </Segment>
            </Segment>
          </Grid.Column>
        </Grid>

        <Grid stackable columns="equal">
          <Grid.Column>
            <Segment basic clearing>
              <Segment basic clearing className="no-padding">
                {userHasPermission('can_edit_student') && (
                  <DocumentUploader
                    uploadPath={`organizations/${
                      currentOrganization.id
                    }/students/${selectedStudent.id}/documents`}
                    title="Uploading Student Documents"
                    allowedFileTypes={[
                      '*.csv', // Added because uppy doesn't recognize *.csv during drag drop.
                      CSV,
                      TXT,
                      PNG,
                      JPEG,
                      DOC,
                      DOCX,
                      XLS,
                      XLSX,
                      PDF,
                    ]}
                    onUploaded={this.processUploadedDocuments}
                  />
                )}

                <Header as="h2" floated="left" content="Files" />
              </Segment>

              <Segment className="no-shadow no-border top-border">
                <StudentDocumentList />
              </Segment>
            </Segment>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

FreelancerDetail.propTypes = {
  students: PropTypes.shape({
    selectedStudent: PropTypes.shape({
      id: PropTypes.string,
    }),
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
  }),
  studentSelectionCleared: PropTypes.func.isRequired,
  organizationUpdateStudent: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
  currentOrganization: state.organizations.currentOrganization,
  students: state.students,
});

export default connect(
  mapStateToProps,
  { studentSelectionCleared, organizationUpdateStudent }
)(FreelancerDetail);
