import React from 'react';
import PropTypes from 'prop-types';
import { Card, Header, Label } from 'semantic-ui-react';
import Avatar from 'react-avatar';

const StaffEditCard = ({ staff, onClick }) => (
  <Card className="shadow" key={staff.uid} onClick={onClick}>
    <Card.Content>
      <Avatar
        name={`${staff.firstName} ${staff.lastName}`}
        round
        size={40}
        className="right floated"
      />
      <Card.Header>
        <Header as="h3">{`${staff.firstName} ${staff.lastName}`}</Header>
      </Card.Header>
      <Card.Meta>
        {staff.email && staff.email.indexOf('moxit_') !== -1 ? '' : staff.email}
      </Card.Meta>
      <Card.Description>
        <br />
        <br />
      </Card.Description>
    </Card.Content>
    <Card.Content extra>
      <Label
        content={staff.enabled ? 'Enabled' : 'Disabled'}
        color={staff.enabled ? 'green' : 'red'}
      />
    </Card.Content>
  </Card>
);

StaffEditCard.propTypes = {
  staff: PropTypes.shape({
    uid: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default StaffEditCard;
