import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Segment, Image, Header } from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import Validator from 'validator';
import { withRouter } from 'react-router-dom';
import swal from '@sweetalert/with-react';

// Import components
import InlineError from '../../Messages/InlineError';
import ShowError from '../../Messages/ShowError';

// Import actions
import {
  organizationAddStudent,
  organizationUpdateStudent,
  studentSelectionCleared,
  studentSelected,
} from '../../../redux/actions/studentActions';

// Import utils
import { phoneNumberFormat, phoneNumberParse } from '../../../helpers/utils';
import { getRandomStudentPicture } from '../../../helpers/students';
import StatePicker from '../../Shared/StatePicker';
import { updateSetupStatus } from '../../../api/firebase/setup';
import ProfilePictureUploader from '../../Upload/ProfilePictureUploader';
import { userHasPermission } from '../../../api/firebase/account';
import RoomPicker from '../../Rooms/RoomPicker';

const commitmentOptions = [
  {
    key: 1,
    text: 'Full Time',
    value: 'Full Time',
  },
  {
    key: 2,
    text: 'Part Time',
    value: 'Part Time',
  },
];

const statusOptions = [
  {
    key: 1,
    text: 'N/A',
    value: 'N/A',
  },
  {
    key: 2,
    text: 'Open/Hiring',
    value: 'Open/Hiring',
  },
  {
    key: 3,
    text: 'Pending',
    value: 'Pending',
  },
  {
    key: 4,
    text: 'Closed',
    value: 'Closed',
  },
];

class StudentPersonalInformationForm extends Component {
  state = {
    data: {
      id: '',

      typeOfWork: '',
      companyName: '',
      description: '',
      sector: '',
      positionDescription: '',
      commitment: '',
      status: '',
      // enabled: true,
      compensation: '',
      contactName: '',
      contactEmail: '',
      comment: '',
      createdAt: '',
      updatedAt: '',

      // firstName: '',
      // middleName: '',
      // lastName: '',
      // birthday: null,
      // gender: '',
      // allergies: '',
      // medications: '',
      // doctorName: '',
      // doctorPhone: '',
      // notes: '',
      // address1: '',
      // address2: '',
      // city: '',
      // state: '',
      // zipcode: '',
      // organization: '',
      // picture: '',
      // rooms: [],
      // enrollmentStatus: true,
      // photosAllowed: false,
    },
    loading: false,
    errors: {},
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      students: { selectedStudent },
    } = nextProps;

    if (
      selectedStudent &&
      (!_.isEmpty(selectedStudent.id) &&
        prevState.data.id !== selectedStudent.id)
    ) {
      const { ...rest } = selectedStudent;
      return {
        data: {
          ...prevState.data,
          // doctorPhone: phoneNumberParse(doctorPhone),
          // birthday: birthday ? moment(`${birthday}`, 'x') : null,
          ...rest,
        },
      };
    }
    return prevState;
  }

  componentWillUnmount() {
    if (!this.props.clearOnUnmount) return;
    // Clear selection from store.
    this.props.studentSelectionCleared();
  }

  onChange = (e, { name, value, checked }) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: !_.isUndefined(checked) ? checked : value,
        // picture:
        //   !_.isUndefined(checked) && !checked ? '' : this.state.data.picture, // Clear student photo when allow photo is switched off.
      },
      errors: _.omit(this.state.errors, name),
    });
  };

  maskedOnChange = e => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
      errors: _.omit(this.state.errors, e.target.name),
    });
  };

  handleBirthdayChange = birthday => {
    this.setState({
      data: {
        ...this.state.data,
        birthday,
      },
      errors: _.omit(this.state.errors, birthday),
    });
  };

  validate = data => {
    const errors = {};

    if (!data.typeOfWork) errors.typeOfWork = 'Type of work is required';
    if (!data.companyName) errors.companyName = 'Company name is required';
    if (!data.sector) errors.sector = 'Sector is required';
    if (!data.positionDescription)
      errors.positionDescription = 'Project / Position Description is required';
    if (!data.status) errors.status = 'Project/ Position Status is required';
    if (!data.compensation) errors.compensation = 'Compensation is required';
    if (!data.contactName) errors.contactName = 'Contact Name is required';
    if (!data.contactEmail) errors.contactEmail = 'Contact Email is required';
    return errors;
  };

  validateOld = data => {
    const errors = {};

    if (!data.firstName) errors.firstName = 'First Name is required';
    if (!data.lastName) errors.lastName = 'Last Name is required';
    if (data.doctorPhone)
      if (!Validator.isMobilePhone(data.doctorPhone, 'en-US'))
        errors.doctorPhone = 'Phone is invalid';

    return errors;
  };

  isSetupRoute = () => {
    const { match } = this.props;
    return match.path === '/setup';
  };

  saveChanges = async () => {
    const {
      data,
      data: {
        id,
        // doctorPhone, picture, birthday, displayName,
        ...rest
      },
    } = this.state;
    const { currentOrganization } = this.props;

    if (currentOrganization && currentOrganization.id) {
      this.setState({ loading: true });

      const formData = {
        id,
        // birthday: birthday ? birthday.utc().valueOf() : null,
        // displayName: `${rest.firstName} ${rest.middleName} ${rest.lastName}`,
        // doctorPhone: phoneNumberFormat(doctorPhone),
        // picture: picture ? picture : getRandomStudentPicture(),
        ...rest,
      };

      try {
        await this.props.organizationUpdateStudent(
          currentOrganization.id,
          data
          // formData
        );
        // Used to close wrapper HOC. e.g Sliding panel, modal...
        // if (this.props.onClose) {
        //   this.props.onClose('saved');
        //   return;
        // }
        this.setState({ loading: false });
      } catch (error) {
        this.setState({
          loading: false,
          errors: { 'Unable save data': error.message },
        });
      }
    }
  };

  onSubmit = e => {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });

    if (_.isEmpty(errors)) {
      const {
        data: {
          id,
          createdAt,
          updatedAt,
          // doctorPhone, picture, birthday, displayName,
          ...rest
        },
      } = this.state;
      const { currentOrganization } = this.props;

      this.setState({ loading: true });

      const currentTime = Date.now();

      const formData = {
        // createdAt: createdAt ? currentTime : createdAt,
        ...rest,
      };

      console.log({ formData });

      if (currentOrganization && currentOrganization.id)
        if (id) {
          // Update.
          this.props
            .organizationUpdateStudent(currentOrganization.id, {
              id,
              organization: currentOrganization.id,
              updatedAt: currentTime,
              ...formData,
            })
            .then(() => {
              swal('Sweet!', 'Thanks for adding the gig', 'success').then(
                () => {
                  // Used to close wrapper HOC. e.g Sliding panel, modal...
                  if (this.props.onClose) {
                    this.props.onClose('updated');
                    return;
                  }
                  this.setState({ loading: false });
                }
              );
            })
            .catch(error =>
              this.setState({
                loading: false,
                errors: { 'Unable to Update': error.message },
              })
            );
        } else {
          // New entry.
          this.props
            .organizationAddStudent(currentOrganization.id, {
              organization: currentOrganization.id,
              createdAt: currentTime,
              updatedAt: currentTime,
              ...formData,
            })
            .then(() => {
              swal('Bravo!', 'Thanks for updating the gig', 'success').then(
                () => {
                  // Update setup flags.
                  // updateSetupStatus(currentOrganization.id, 'firstStudentCreated');

                  // Used to close wrapper HOC. e.g Sliding panel, modal...
                  if (this.props.onClose) {
                    this.props.onClose('added');
                    return;
                  }
                  this.setState({ loading: false });
                }
              );
            })
            .catch(error =>
              this.setState({
                loading: false,
                errors: { 'Unable to Add': error.message },
              })
            );
        }
    }
  };

  render() {
    const { data, loading, errors } = this.state;
    return (
      <Segment basic textAlign="left">
        <ShowError errors={errors} />
        <Form
          id="student-personal-information-form"
          onSubmit={this.onSubmit}
          loading={loading}
          noValidate
        >
          <Form.Field error={!!errors.typeOfWork}>
            <Form.Input
              size="large"
              required
              type="text"
              id="typeOfWork"
              name="typeOfWork"
              label="Type of Work"
              placeholder="Add a type of work (ex: iOS Development, Blog Writer, Freelance Photography)"
              value={data.typeOfWork}
              onChange={this.onChange}
            />
            {errors.typeOfWork && <InlineError text={errors.typeOfWork} />}
          </Form.Field>

          <Form.Field error={!!errors.companyName}>
            <Form.Input
              size="large"
              required
              type="text"
              id="companyName"
              name="companyName"
              label="Company Name"
              placeholder="Add a Company Name (ex: Moxit, Inc.)"
              value={data.companyName}
              onChange={this.onChange}
            />
            {errors.companyName && <InlineError text={errors.companyName} />}
          </Form.Field>

          <Form.Field error={!!errors.description}>
            <Form.TextArea
              size="large"
              type="text"
              id="description"
              name="description"
              label="Company Description"
              placeholder="Add a company description"
              value={data.description}
              onChange={this.onChange}
            />
            {errors.description && <InlineError text={errors.description} />}
          </Form.Field>

          <Form.Field error={!!errors.sector}>
            <Form.Input
              size="large"
              required
              type="text"
              id="sector"
              name="sector"
              label="Sector"
              placeholder="Add a sector"
              value={data.sector}
              onChange={this.onChange}
            />
            {errors.sector && <InlineError text={errors.sector} />}
          </Form.Field>

          <Form.Field error={!!errors.positionDescription}>
            <Form.TextArea
              size="large"
              required
              type="text"
              id="positionDescription"
              name="positionDescription"
              label="Project / Position Description"
              placeholder="Add a project or position Description"
              value={data.positionDescription}
              onChange={this.onChange}
            />
            {errors.positionDescription && (
              <InlineError text={errors.positionDescription} />
            )}
          </Form.Field>

          {/* <Form.Field error={!!errors.enabled}>
            <label>Post Publicly?</label>
            <Form.Radio
              toggle
              id="enabled"
              name="enabled"
              onChange={this.onChange}
              checked={data.enabled}
            />
            {errors.enabled && <InlineError text={errors.enabled} />}
          </Form.Field> */}

          <Form.Group widths="equal">
            <Form.Field error={!!errors.status}>
              <Form.Dropdown
                required
                id="status"
                name="status"
                label="Project/ Position Status"
                placeholder="Project/ Position Status"
                options={statusOptions}
                value={data.status}
                selection
                onChange={this.onChange}
              />
              {errors.status && <InlineError text={errors.status} />}
            </Form.Field>

            <Form.Field error={!!errors.commitment}>
              <Form.Dropdown
                required
                id="commitment"
                name="commitment"
                label="Time Commitment"
                placeholder="Time Commitment"
                options={commitmentOptions}
                value={data.commitment}
                selection
                onChange={this.onChange}
              />
              {errors.commitment && <InlineError text={errors.commitment} />}
            </Form.Field>
          </Form.Group>

          <Form.Field error={!!errors.compensation}>
            <Form.Input
              size="large"
              required
              type="text"
              id="compensation"
              name="compensation"
              label="Compensation"
              placeholder="What is the compensation?"
              value={data.compensation}
              onChange={this.onChange}
            />
            {errors.compensation && <InlineError text={errors.compensation} />}
          </Form.Field>

          <Form.Group widths="equal">
            <Form.Field error={!!errors.contactName}>
              <Form.Input
                size="large"
                required
                type="text"
                id="contactName"
                name="contactName"
                label="Contact Name"
                placeholder="Add a contact name"
                value={data.contactName}
                onChange={this.onChange}
              />
              {errors.contactName && <InlineError text={errors.contactName} />}
            </Form.Field>

            <Form.Field error={!!errors.contactEmail}>
              <Form.Input
                size="large"
                required
                type="text"
                id="contactEmail"
                name="contactEmail"
                label="Contact Email"
                placeholder="Add a contact email"
                value={data.contactEmail}
                onChange={this.onChange}
              />
              {errors.contactEmail && (
                <InlineError text={errors.contactEmail} />
              )}
            </Form.Field>
          </Form.Group>

          <Form.Field error={!!errors.comment}>
            <Form.Input
              size="large"
              type="text"
              id="comment"
              name="comment"
              label="How to Apply or Be Considered"
              placeholder="Add link to the job post or additional instructions"
              value={data.comment}
              onChange={this.onChange}
            />
            {errors.comment && <InlineError text={errors.comment} />}
          </Form.Field>

          <Form.Group>
            <Form.Button primary content={'Save'} size="large" />
          </Form.Group>
        </Form>
      </Segment>
    );
  }

  renderOld() {
    const { data, loading, errors } = this.state;
    return (
      <Segment basic textAlign="left">
        <ShowError errors={errors} />
        <Form
          id="student-personal-information-form"
          onSubmit={this.onSubmit}
          loading={loading}
          noValidate
        >
          <Form.Group widths="equal">
            <Form.Field error={!!errors.firstName}>
              <Form.Input
                required
                type="text"
                id="firstName"
                name="firstName"
                value={data.firstName}
                onChange={this.onChange}
                label="First Name"
                placeholder="First Name"
              />
              {errors.firstName && <InlineError text={errors.firstName} />}
            </Form.Field>

            <Form.Field error={!!errors.middleName}>
              <Form.Input
                type="text"
                id="middleName"
                name="middleName"
                value={data.middleName}
                onChange={this.onChange}
                label="Middle Name"
                placeholder="Middle Name"
              />
              {errors.middleName && <InlineError text={errors.middleName} />}
            </Form.Field>
          </Form.Group>

          <Form.Field error={!!errors.lastName}>
            <Form.Input
              required
              type="text"
              id="lastName"
              name="lastName"
              value={data.lastName}
              onChange={this.onChange}
              label="Last Name"
              placeholder="Last Name"
            />
            {errors.lastName && <InlineError text={errors.lastName} />}
          </Form.Field>

          <RoomPicker
            error={!!errors.rooms}
            id="rooms"
            name="rooms"
            label="Rooms"
            placeholder="Room"
            value={data.rooms}
            selection
            multiple
            search
            onChange={this.onChange}
          >
            {errors.rooms && <InlineError text={errors.rooms} />}
          </RoomPicker>

          <Form.Group widths="equal">
            <Form.Field
              fluid
              isClearable
              id="birthday"
              name="birthday"
              label="Birthday"
              placeholder="Select Birthday"
              dateFormat="YYYY-MM-DD"
              maxDate={moment()}
              control={DatePicker}
              selected={data.birthday}
              onChange={this.handleBirthdayChange}
              error={!!errors.birthday}
            >
              {errors.birthday && <InlineError text={errors.birthday} />}
            </Form.Field>
            <Form.Field error={!!errors.gender}>
              <Form.Dropdown
                id="gender"
                name="gender"
                label="Gender"
                placeholder="Gender"
                selection
                selectOnBlur={false}
                value={data.gender}
                onChange={this.onChange}
                options={[
                  { text: 'Male', value: 'Male' },
                  { text: 'Female', value: 'Female' },
                ]}
              />
              {errors.gender && <InlineError text={errors.gender} />}
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            {userHasPermission('can_edit_student') &&
              data.id &&
              this.props.currentOrganization.id && (
                <Form.Field
                  error={!!errors.picture}
                  label="Student Photo"
                  control={ProfilePictureUploader}
                  uploadPath={`organizations/${
                    this.props.currentOrganization.id
                  }/students/${data.id}`}
                  title="Uploading Student Picture"
                  onUploaded={imageUrl => {
                    this.setState(
                      {
                        data: { ...this.state.data, picture: imageUrl },
                      },
                      () => {
                        if (!this.isSetupRoute()) this.saveChanges();
                      }
                    );
                  }}
                >
                  {errors.picture && <InlineError text={errors.picture} />}
                </Form.Field>
              )}
            {data && data.picture && (
              <Segment basic>
                <Image src={data.picture} size="medium" rounded />
              </Segment>
            )}
          </Form.Group>

          <br />
          <br />

          <Form.Field error={!!errors.address1}>
            <Form.Input
              type="text"
              id="address1"
              name="address1"
              value={data.address1}
              onChange={this.onChange}
              label="Address 1"
              placeholder="Address 1"
            />
            {errors.address1 && <InlineError text={errors.address1} />}
          </Form.Field>

          <Form.Field error={!!errors.address2}>
            <Form.Input
              type="text"
              id="address2"
              name="address2"
              value={data.address2}
              onChange={this.onChange}
              label="Address 2"
              placeholder="Address 2"
            />
            {errors.address2 && <InlineError text={errors.address2} />}
          </Form.Field>

          <Form.Field error={!!errors.city}>
            <Form.Input
              type="text"
              id="city"
              name="city"
              value={data.city}
              onChange={this.onChange}
              label="City"
              placeholder="City"
            />
            {errors.city && <InlineError text={errors.city} />}
          </Form.Field>

          <Form.Group widths="equal">
            <Form.Field error={!!errors.state}>
              <Form.Field
                id="state"
                name="state"
                label="State"
                control={StatePicker}
                placeholder="State"
                value={data.state}
                selection
                search
                onChange={this.onChange}
              />
              {errors.state && <InlineError text={errors.state} />}
            </Form.Field>

            <Form.Field error={!!errors.zipcode}>
              <Form.Input
                type="text"
                id="zipcode"
                name="zipcode"
                value={data.zipcode}
                onChange={this.onChange}
                label="Zip Code"
                placeholder="Zip Code"
              />
              {errors.zipcode && <InlineError text={errors.zipcode} />}
            </Form.Field>
          </Form.Group>

          <br />
          <br />

          <Form.Field error={!!errors.allergies}>
            <Form.Input
              type="text"
              id="allergies"
              name="allergies"
              value={data.allergies}
              onChange={this.onChange}
              label="Allergies"
              placeholder="If there are any allergies, add it here"
            />
            {errors.allergies && <InlineError text={errors.allergies} />}
          </Form.Field>

          <Form.Field error={!!errors.medications}>
            <Form.Input
              type="text"
              id="medications"
              name="medications"
              value={data.medications}
              onChange={this.onChange}
              label="Medications"
              placeholder="If there are any medications, add it here"
            />
            {errors.medications && <InlineError text={errors.medications} />}
          </Form.Field>

          <Form.Group widths="equal">
            <Form.Field error={!!errors.doctorName}>
              <Form.Input
                type="text"
                id="doctorName"
                name="doctorName"
                value={data.doctorName}
                onChange={this.onChange}
                label="Doctor Name"
                placeholder="Dr. Jane Doe"
              />
              {errors.doctorName && <InlineError text={errors.doctorName} />}
            </Form.Field>

            <Form.Field error={!!errors.doctorPhone}>
              <Form.Input
                type="text"
                id="doctorPhone"
                name="doctorPhone"
                onChange={this.maskedOnChange}
                label="Phone Number"
                control={MaskedInput}
                mask={[
                  '(',
                  /[1-9]/,
                  /\d/,
                  /\d/,
                  ')',
                  ' ',
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                value={data.doctorPhone}
                guide={false}
                placeholder="(123) 456-7890"
              />
              {errors.doctorPhone && <InlineError text={errors.doctorPhone} />}
            </Form.Field>
          </Form.Group>

          <Form.Field error={!!errors.notes}>
            <Form.TextArea
              type="text"
              id="notes"
              name="notes"
              value={data.notes}
              onChange={this.onChange}
              label="Notes"
              placeholder="Add any relevant notes"
            />
            {errors.notes && <InlineError text={errors.notes} />}
          </Form.Field>
          <Form.Group>
            <Form.Button primary content="Save" />
            {!this.props.hideCancel && (
              <Form.Button
                basic
                content="Cancel"
                onClick={e => {
                  if (e) e.preventDefault();
                  if (this.props.onClose) this.props.onClose();
                }}
              />
            )}
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

StudentPersonalInformationForm.propTypes = {
  currentOrganization: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  students: PropTypes.shape({
    selectedStudent: PropTypes.shape({
      id: PropTypes.string,
    }),
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
  }),
  organizationAddStudent: PropTypes.func,
  organizationUpdateStudent: PropTypes.func,
  studentSelectionCleared: PropTypes.func,
  studentSelected: PropTypes.func,
};

const mapStateToProps = state => ({
  currentOrganization: state.organizations.currentOrganization,
  students: state.students,
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      organizationAddStudent,
      organizationUpdateStudent,
      studentSelectionCleared,
      studentSelected,
    }
  )(StudentPersonalInformationForm)
);
