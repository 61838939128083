import React from 'react';
import { Table } from 'semantic-ui-react';

const RemindersEvents = () => (
  <Table singleLine basic="very">
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Name</Table.HeaderCell>
        <Table.HeaderCell>Type</Table.HeaderCell>
        <Table.HeaderCell>Due Date</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      <Table.Row>
        <Table.Cell>John Lilki</Table.Cell>
        <Table.Cell>Immunization</Table.Cell>
        <Table.Cell>September 14, 2013</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Jamie Harington</Table.Cell>
        <Table.Cell>Immunization</Table.Cell>
        <Table.Cell>January 11, 2014</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Jill Lewis</Table.Cell>
        <Table.Cell>Immunization</Table.Cell>
        <Table.Cell>May 11, 2014</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Jill Lewis</Table.Cell>
        <Table.Cell>Immunization</Table.Cell>
        <Table.Cell>May 11, 2014</Table.Cell>
      </Table.Row>
    </Table.Body>
  </Table>
);

export default RemindersEvents;
