import React from 'react';
import PropTypes from 'prop-types';
import { Dimmer, Loader } from 'semantic-ui-react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { isUserEmailVerified } from '../../api/firebase/account';

const Verify = ({ auth, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      auth.authenticating ? (
        <Dimmer page active inverted>
          <Loader inline="centered">Verifying ...</Loader>
        </Dimmer>
      ) : auth.authenticated ? (
        isUserEmailVerified() ? (
          <Redirect to={'/'} />
        ) : (
          <Component {...props} />
        )
      ) : (
        <Component {...props} />
      )
    }
  />
);

Verify.propTypes = {
  component: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    authenticating: PropTypes.bool.isRequired,
    authenticated: PropTypes.bool.isRequired,
  }).isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Verify);
