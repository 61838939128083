import React from 'react';
import { Container, Segment } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';

// Import components.
import StudentList from '../Components/Students/StudentList';

const Students = props => {
  const renderPageHead = () => (
    <Helmet>
      <title>
        Freelancers - Unfurlough.us - Freelance gigs for furloughed govvies
      </title>
      <body class="students" />
    </Helmet>
  );

  return (
    <Container className="no-padding no-margin">
      {renderPageHead()}

      <Segment basic>
        <StudentList type="freelancers" />
      </Segment>
    </Container>
  );
};

export default Students;
