import { firestore } from '.';
import { getDocuments, setDocument } from './firestore';
import _ from 'lodash';

export const getUserProfile = uid =>
  firestore()
    .collection('users')
    .doc(uid)
    .get()
    .then(doc => doc.data());

export const userOnListen = (uid, next, error, complete) =>
  firestore()
    .collection(`users`)
    .doc(uid)
    .onSnapshot(
      doc => next(doc ? { uid: doc.id, ...doc.data() } : {}),
      error,
      complete
    );

/**
 * Cool function that searches for a user that matches the passed firstName or lastName or phone.
 * Result may not be accurate. Use at own risk ¯\_(ツ)_/¯
 */
export const getOrganizationUserByEmail = async (
  organizationId,
  email,
  userData
) => {
  const foundUsers = await getDocuments({
    path: `organizations/${organizationId}/users`,
    conditions: [{ field: 'email', operation: '==', value: email }],
  });

  const user = _.pickBy(foundUsers, foundUser => {
    return (
      (foundUser.firstName && // search by first name.
        userData.firstName &&
        foundUser.firstName
          .toLowerCase()
          .indexOf(userData.firstName.toLowerCase()) !== -1) ||
      (foundUser.lastName && // search by last name.
        userData.lastName &&
        foundUser.lastName
          .toLowerCase()
          .indexOf(userData.lastName.toLowerCase()) !== -1) ||
      (foundUser.phone &&
        userData.phone &&
        foundUser.phone.indexOf(userData.phone) !== -1) // search by phone.
    );
  });
  // return user object
  return user
    ? Object.keys(user).map(key => ({ ...user[key], uid: key }))[0]
    : {};
};

export const updateOrganizationUser = (organizationId, userId, data) => {
  if (!organizationId || !userId) return null;
  return setDocument({
    path: `organizations/${organizationId}/users/${userId}`,
    data,
  });
};

export const updateOrganizationUserPin = (
  organizationId,
  userId,
  type,
  pin
) => {
  if (!organizationId || !userId || !type || !pin) return null;
  return setDocument({
    path: `organizations/${organizationId}/users/${userId}`,
    data: { [type]: pin },
  });
};

export const updateUserProfile = (userId, data) => {
  return setDocument({
    path: `users/${userId}`,
    data,
    merge: true,
  });
};
