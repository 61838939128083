import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';
import _ from 'lodash';
import {
  isUserEmailVerified,
  userHasPermission,
} from '../../api/firebase/account';

import AuthorizationChecker from '../Shared/AuthorizationChecker';

const RestrictedRoute = ({
  auth,
  user,
  permissions,
  routePermission,
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
        auth.authenticating ? (
          <Dimmer page active inverted>
            <Loader inline="centered">Authenticating ...</Loader>
          </Dimmer>
        ) : auth.authenticated ? (
          isUserEmailVerified() ? (
            (!_.isEmpty(user.claims) &&
              !_.isEmpty(permissions) &&
              (routePermission === '*' ||
                userHasPermission(routePermission)) && (
                <Component {...props} />
              )) ||
            (props.match.path === '/setup' ? (
              <Component {...props} />
            ) : (
              <AuthorizationChecker />
            ))
          ) : (
            <Redirect
              to={{
                ...props,
                pathname: '/verify',
                state: { from: props.location },
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              ...props,
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  user: state.user,
  permissions: state.permissions,
});

export default connect(mapStateToProps)(RestrictedRoute);
