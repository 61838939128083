import { firestore } from '.';
import { getDocuments, getDocument } from './firestore';

export const organizationRoomsOnListen = (
  organizationId,
  next,
  error,
  complete
) =>
  firestore()
    .collection(`organizations/${organizationId}/rooms`)
    .orderBy('name', 'asc')
    .onSnapshot(
      querySnapshot =>
        next(
          querySnapshot
            ? {
                count: querySnapshot.size,
                rooms: querySnapshot.docs.map(doc => ({
                  id: doc.id,
                  ...doc.data(),
                })),
              }
            : {}
        ),
      error,
      complete
    );

export const addOrganizationRoom = (organizationId, roomData) =>
  firestore()
    .collection(`organizations/${organizationId}/rooms`)
    .add(roomData);

export const updateOrganizationRoom = (organizationId, { id, ...roomData }) =>
  firestore()
    .doc(`organizations/${organizationId}/rooms/${id}`)
    .update(roomData);

export const fetchRooms = (organizationId, location) => {
  if (!organizationId) return null;

  let conditions = [];

  if (location)
    conditions = [
      ...conditions,
      { field: 'location', operation: '==', value: location },
    ];

  return getDocuments({
    path: `organizations/${organizationId}/rooms`,
    conditions,
    orderBy: [{ field: 'name', direction: 'asc' }],
  });
};

export const getRoom = (organizationId, roomId) => {
  if (!organizationId || !roomId) return null;
  return getDocument({
    path: `organizations/${organizationId}/rooms/${roomId}`,
  });
};
