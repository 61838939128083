import React from 'react';
import { connect } from 'react-redux';
import { Segment, Grid, Image, Header } from 'semantic-ui-react';
import GoogleMapReact from 'google-map-react';

import goddardLogo from './asset/goddard-logo.jpg';

const Marker = ({ text }) => (
  <div
    style={{
      color: 'white',
      background: '#00447C',
      padding: '15px 10px',
      display: 'inline-flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '100%',
      transform: 'translate(-50%, -50%)',
    }}
  >
    {text}
  </div>
);

const OrganizationMap = currentOrganization => {
  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: 'AIzaSyCIzTljC_I7KloOIZbyYj_Z3KgdNubEKhA' }}
      defaultCenter={{ lat: 38.889931, lng: -77.009003 }}
      defaultZoom={11}
      options={{
        panControl: false,
        mapTypeControl: false,
        scrollwheel: false,
        zoomControl: false,
        fullscreenControl: false,
      }}
    >
      <Marker lat={59.955413} lng={30.337844} text={currentOrganization.name} />
    </GoogleMapReact>
  );
};

const OrganizationInfo = ({ organizations: { currentOrganization } }) => (
  <div>
    <div style={{ width: '100%', height: '220px' }}>
      <OrganizationMap currentOrganization={currentOrganization} />
    </div>

    <Segment basic>
      <Grid container stackable verticalAlign="middle">
        <Grid.Row columns={2}>
          <Grid.Column width={4}>
            <Image fluid src={goddardLogo} rounded />
          </Grid.Column>
          <Grid.Column width={12}>
            <Header>{currentOrganization.name}</Header>
            <p>
              {`${currentOrganization.address1 ||
                ''} ${currentOrganization.address2 || ''}`}
              <br />
              {`${currentOrganization.city ||
                ''}, ${currentOrganization.state ||
                ''} ${currentOrganization.zipcode || ''}`}
            </p>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={2}>
          <Grid.Column>
            <div>Size</div>
            <Header className="no-margin">Medium</Header>
          </Grid.Column>
          <Grid.Column>
            <div>Office Phone</div>
            <Header className="no-margin">
              {currentOrganization.phone || ''}
            </Header>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <div>Website</div>
            <Header className="no-margin">
              <a href="goddardschool.com">goddardschool.com</a>
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    {/* <Grid celled className="no-margin">
      <Grid.Row columns={2}>
        <Grid.Column>Left</Grid.Column>
        <Grid.Column>Right</Grid.Column>
      </Grid.Row>
    </Grid> */}
  </div>
);

const mapStateToProps = state => ({
  user: state.user,
  organizations: state.organizations,
});

export default connect(
  mapStateToProps,
  null
)(OrganizationInfo);

// export default OrganizationInfo;
