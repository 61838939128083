import _ from 'lodash';
import {
  LOCATIONS_FETCHED,
  LOCATIONS_LISTENER_STARTED,
  LOCATION_SELECTED,
  LOCATION_SELECTION_CLEARED,
  LOCATION_UPDATED,
} from '../actions/types';

const initialState = {
  selectedLocation: {},
  count: 0,
  list: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOCATIONS_LISTENER_STARTED:
      return { ...state };

    case LOCATIONS_FETCHED:
      return {
        ...state,
        count: action.count,
        list: _.orderBy(action.locations, location =>
          location.name.toLowerCase()
        ),
      };
    case LOCATION_SELECTED:
      return { ...state, selectedLocation: action.location };

    // Replace selected with the newly updated data.
    // New data is fetched by the listener as soon as listener receives the update.
    case LOCATION_UPDATED: {
      return {
        ...state,
        selectedLocation: _.find(state.list, { id: state.selectedLocation.id }),
      };
    }
    case LOCATION_SELECTION_CLEARED:
      return { ...state, selectedLocation: {} };

    default:
      return state;
  }
};
