import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';

const GuestRoute = ({
  auth,
  authenticatedRedirect,
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={
      props =>
        auth.authenticating ? (
          <Dimmer page active inverted>
            <Loader inline="centered">Authenticating ...</Loader>
          </Dimmer>
        ) : (
          <Component {...props} />
        )
      // auth.authenticated ? (
      //   <Redirect to={authenticatedRedirect || '/'} />
      // ) : (
      //   <Component {...props} />
      // )
    }
  />
);

GuestRoute.propTypes = {
  component: PropTypes.func.isRequired,
  authenticatedRedirect: PropTypes.string,
  auth: PropTypes.shape({
    authenticating: PropTypes.bool.isRequired,
    authenticated: PropTypes.bool.isRequired,
  }).isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(GuestRoute);
