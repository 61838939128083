import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Container, Segment, Message, Header, Icon } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';

//Import actions
import { sendEmailVerifyLink, signOut } from '../redux/actions/userActions';
import { getCurrentUser } from '../api/firebase/account';

class EmailSent extends Component {
  state = {
    error: '',
    icon: '',
    header: '',
    message: '',
  };

  static getDerivedStateFromProps(props, state) {
    const { history } = props;
    const currentUser = getCurrentUser();

    if (!currentUser) {
      history.replace('/');
      return {};
    }

    if (currentUser && currentUser.emailVerified) {
      history.replace('/');
      return {};
    }

    return {};
  }

  renderPageHead = () => (
    <Helmet>
      <title>Email is Sent</title>
      <body class="email-sent" />
    </Helmet>
  );

  renderSendLink = () => {
    const { sendEmailVerifyLink, signOut } = this.props;

    const message = this.state.message ? (
      <Segment basic>
        <Message success content="Email verification resent" />
      </Segment>
    ) : (
      ''
    );

    return (
      <Segment basic textAlign="center">
        {message}
        <Segment basic>
          Did not get it.
          <NavLink
            to=""
            onClick={() => {
              sendEmailVerifyLink()
                .then(() => {
                  // this.setState({
                  //   icon: 'check',
                  //   header: 'Email verification resent',
                  //   message: `Email verification resent to ${
                  //     getCurrentUser().email
                  //   }. `,
                  //   error: '',
                  // });
                  return (
                    <Message success content="Email verification resent" />
                  );
                })
                .catch(error => {
                  this.setState({
                    verifying: false,
                    successMessage: '',
                    message: '',
                    error: error.message,
                  });
                });
            }}
          >
            Resend email confirmation again
          </NavLink>
        </Segment>
        <Segment basic>
          <NavLink
            to=""
            onClick={e => {
              e.preventDefault();
              signOut();
            }}
          >
            Sign me out
          </NavLink>
        </Segment>
      </Segment>
    );
  };

  render() {
    return (
      <Segment basic className="no-padding no-margin">
        {this.renderPageHead()}

        <Container textAlign="center">
          <Header as="h1">
            <Icon name="mail" size="big" />
          </Header>
          <Header as="h1">Confirm your account</Header>
          <p>
            Thanks for signing up with us. Your account is almost ready.
            <br />
            Please check your inbox to confirm your email address:
            <br />
            {getCurrentUser() && getCurrentUser().email}
          </p>
          {this.renderSendLink()}
        </Container>
      </Segment>
    );
  }
}

EmailSent.propTypes = {
  sendEmailVerifyLink: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
};

export default connect(
  null,
  {
    sendEmailVerifyLink,
    signOut,
  }
)(EmailSent);
