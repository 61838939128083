import React from 'react';
import PropTypes from 'prop-types';
import { Card, Header, List, Table } from 'semantic-ui-react';
import Avatar from 'react-avatar';

const StudentCell = ({ student, onClick }) => {
  return (
    <Table.Row key={student.id} onClick={onClick} className="cell">
      <Table.Cell>
        <a href="#">{student.typeOfWork}</a>
      </Table.Cell>
      {/* <Table.Cell>{student.positionDescription}</Table.Cell> */}
      <Table.Cell>{student.companyName}</Table.Cell>
      <Table.Cell>{student.sector}</Table.Cell>
      <Table.Cell>{student.status}</Table.Cell>
    </Table.Row>
  );
};

StudentCell.propTypes = {
  students: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default StudentCell;
