import React from 'react';
import { connect } from 'react-redux';
import { Container, Segment, Header, Grid } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import moment from 'moment';

import Stats from '../Components/Stats';
import SectionCard from '../Components/Shared/SectionCard';
import ActivityFeed from '../Components/Stats/ActivityFeed';
import RemindersEvents from '../Components/Stats/RemindersEvents';
import StudentsRatio from '../Components/Stats/StudentsRatio';

const Dashboard = props => {
  const renderPageHead = () => (
    <Helmet>
      <title>Dashboard</title>
      <body class="dashboard" />
    </Helmet>
  );

  const renderPageTitle = () => {
    const today = moment().format('dddd');

    return (
      <Segment basic clearing>
        <Header as="h1" floated="left">
          {`Happy ${today}, ${props.user && props.user.firstName}`}
        </Header>
      </Segment>
    );
  };

  return (
    <Segment basic className="no-padding no-margin">
      {renderPageHead()}

      <Segment basic className="banner">
        <Container>
          {renderPageTitle()}

          <Segment clearing basic className="main-stats">
            <Stats />
          </Segment>
        </Container>
      </Segment>

      <Segment basic>
        <Container>
          <Grid container stackable>
            <Grid.Row columns={2}>
              <Grid.Column>
                <SectionCard header="Ratio" component={StudentsRatio} />
              </Grid.Column>
              <Grid.Column>
                <SectionCard header="Families" component={StudentsRatio} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <SectionCard
                  header="Reminders and Events"
                  component={RemindersEvents}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <SectionCard header="Activity Feed" component={ActivityFeed} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    </Segment>
  );
};

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(Dashboard);
