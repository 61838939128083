import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Segment,
  Icon,
  Header,
  Button,
  Loader,
  Dimmer,
} from 'semantic-ui-react';

// Import hoc
import withSlidingPanel from '../Shared/withSlidingPanel';

// Import components
import LocationForm from '../Forms/LocationForm';
import LocationsList from '../Locations/LocationsList';

const SlidingLocationsForm = withSlidingPanel(LocationForm, {
  title: 'Add A Location',
});

class SetupLocations extends Component {
  state = { isOpen: false, added: false };

  renderAddBlock = () => {
    const {
      locations: { list },
    } = this.props;

    if (list.length) return null;

    return (
      <Segment basic clearing textAlign="center">
        <Segment basic>
          <Icon name="location arrow" size="huge" color="red" />
          <Header as={'h2'}>Add Your Center Information</Header>
          <p>
            Tell us where your center is located so we can properly configure
            your account.
            <br />
            Ready? Let's start.
          </p>
        </Segment>
        <Segment basic>
          <SlidingLocationsForm
            isOpen={this.state.isOpen}
            onClose={status => {
              this.setState({ isOpen: false, added: status === 'added' });
            }}
          />
          <Button
            content="Add a Location"
            primary
            onClick={() => this.setState({ isOpen: true })}
          />
        </Segment>
      </Segment>
    );
  };

  /**
   * This loader will be displayed only during first location creation.
   * Sometimes cloud triggers are slow so this is used as a visual queue.
   */
  renderFirstTimeLoader = () => {
    const {
      currentOrganization: { setup },
    } = this.props;

    if (this.state.added && !setup.firstLocationCreated)
      return (
        <Dimmer active inverted>
          <Loader
            inline="centered"
            indeterminate
            content="Just a moment while we add your first location..."
          />
        </Dimmer>
      );
  };

  renderListBlock = () => {
    const {
      locations: { list },
    } = this.props;

    if (!list.length) return null;
    return <LocationsList />;
  };
  render() {
    return (
      <Segment basic>
        {/* {this.renderFirstTimeLoader()} */}
        {this.renderAddBlock()}
        {this.renderListBlock()}
      </Segment>
    );
  }
}

SetupLocations.propTypes = {};

const mapStateToProps = state => ({
  currentOrganization: state.organizations.currentOrganization,
  locations: state.locations,
});
export default connect(mapStateToProps)(SetupLocations);
