import React from 'react';
import { Dropdown } from 'semantic-ui-react';

const options = [
  { key: 'English', value: 'English', text: 'English' },
  { key: 'Español', value: 'Español', text: 'Español' },
];

const LanguagePicker = ({ staticContext, control, children, ...rest }) => {
  return (
    <Dropdown {...rest} options={options} selectOnBlur={false} clearable />
  );
};

export default LanguagePicker;
