import React, { Component } from 'react';
import { connect } from 'react-redux';
import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/react';
import { Button, Modal } from 'semantic-ui-react';

// Import API.
import { storage } from '../../api/firebase';

// Import helpers.
import FirebaseCloudStorage from './FirebaseCloudStorage';

class DocumentUploader extends Component {
  constructor(props) {
    super();
    this.state = {
      modalOpen: false,
    };

    this.initUppy(props);
  }

  initUppy = props => {
    const storageRef = storage()
      .ref()
      .child(props.uploadPath);

    this.uppy = new Uppy({
      id: 'uppy1',
      autoProceed: props.autoProceed || false,
      restrictions: {
        maxFileSize: props.maxFileSize || 30000000,
        allowedFileTypes: props.allowedFileTypes || '',
        minNumberOfFiles: 1,
      },
    })
      .use(FirebaseCloudStorage, {
        storageRef,
        refId: props.uniqueId || '',
        rename: true,
      })
      .on('file-added', result => {}) // When individual files are added
      .on('upload-success', (file, body, uploadURL) => {}) // When individual files are uploaded to remote storage.
      .on('complete', result => {
        const { successful } = result;
        if (this.props.onUploaded && successful)
          this.props.onUploaded(successful);

        this.handleClose();
        this.clearFiles();
      });
  };

  clearFiles = () => {
    if (this.uppy) {
      const currentFiles = this.uppy.getFiles();
      currentFiles.forEach(currentFile => this.uppy.removeFile(currentFile.id));
    }
  };

  handleOpen = e => {
    if (e) e.preventDefault();
    this.setState({
      modalOpen: true,
    });
  };

  handleClose = () => {
    this.setState({
      modalOpen: false,
    });
  };

  render() {
    return (
      <div>
        <Modal
          trigger={
            <Button
              primary
              icon="cloud upload"
              floated="right"
              content="Upload"
              className="round"
              onClick={this.handleOpen}
            />
          }
          closeIcon
          open={this.state.modalOpen}
          size="small"
          header={this.props.title || 'Uploader'}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          onClose={() => {
            if (this.uppy) {
              this.uppy.reset();
            }
            this.handleClose();
          }}
          content={
            <Dashboard
              uppy={this.uppy}
              hideProgressAfterFinish
              width={1000}
              proudlyDisplayPoweredByUppy={false}
              closeModalOnClickOutside
              open={this.state.modalOpen}
              onRequestClose={this.handleClose}
            />
          }
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  currentOrganization: state.organizations.currentOrganization,
  selectedStudent: state.students.selectedStudent,
});

export default connect(mapStateToProps)(DocumentUploader);
