import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import {
  Container,
  Image,
  Menu,
  Responsive,
  Segment,
  Sidebar,
} from 'semantic-ui-react';

// Import Logo
import moxitLogo from '../../styles/img/moxit-logo-white-100.png';

// Import component
import TopNavigation from '../Navigation/TopNavigation';
import MobileHamburgerNavigation from '../Navigation/MobileHamburgerNavigation';

import Masthead from './Masthead';
import Footer from './Footer';

class MobileContainer extends Component {
  state = {};

  handlePusherClick = () => {
    const { sidebarOpened } = this.state;

    if (sidebarOpened) this.setState({ sidebarOpened: false });
  };

  handleToggle = () =>
    this.setState({ sidebarOpened: !this.state.sidebarOpened });

  render() {
    const { children } = this.props;
    const { sidebarOpened } = this.state;

    return (
      <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
        <Sidebar.Pushable as={Segment} basic>
          <Sidebar
            as={Menu}
            animation="uncover"
            vertical
            visible={sidebarOpened}
            style={{ backgroundColor: '#fff' }}
          >
            <TopNavigation viewport="mobile" />
          </Sidebar>

          <Sidebar.Pusher
            dimmed={sidebarOpened}
            onClick={this.handlePusherClick}
            className="mobile-container"
          >
            <Segment vertical className="navbar no-padding">
              <Container className="no-padding no-margin">
                <Menu inverted secondary>
                  <MobileHamburgerNavigation handleToggle={this.handleToggle} />
                  {/* <Menu.Item as="a" header>
                    <Image
                      size="small"
                      src={moxitLogo}
                      style={{ marginRight: '1.5em' }}
                    />
                  </Menu.Item> */}
                  <Menu.Item as={NavLink} content="Gigs" to="/gigs" />
                  {/* <Menu.Item
                    as={NavLink}
                    content="Freelancers"
                    to="/freelancers"
                  /> */}
                </Menu>
              </Container>
            </Segment>

            <Masthead />

            <Container>
              <Segment raised>{children}</Segment>
            </Container>

            <Footer />
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Responsive>
    );
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
};

export default MobileContainer;
