import React from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { Menu, Image } from 'semantic-ui-react';

// Import helpers.
import { getCurrentUser, userHasPermission } from '../../api/firebase/account';

// Import components.
import UserProfileMenu from './UserProfileMenu';
// import GlobalSearch from '../Search/GlobalSearch';

// Import Logo
import moxitLogo from '../../styles/img/moxit-logo-white-100.png';

import routeList from '../Routes';

const TopNavigation = ({ auth, user, permissions, viewport = 'desktop' }) => {
  // if (!getCurrentUser()) return null;

  const menuRoutes = routeList.map((menu, index) => {
    const {
      enabled,
      hasMenu,
      permission,
      restrictedRoute,
      component,
      ...rest
    } = menu;

    return (
      enabled &&
      hasMenu &&
      (permission === '*' || userHasPermission(permission)) && (
        <Menu.Item key={index} as={NavLink} {...rest} content={menu.name} />
      )
    );
  });

  const nav =
    viewport === 'mobile' ? (
      <div>
        {menuRoutes}
        <Menu.Menu position="right">
          {/* <Menu.Item>
            <GlobalSearch fluid />
          </Menu.Item> */}
          <Menu.Item>
            <UserProfileMenu viewport="mobile" />
          </Menu.Item>
        </Menu.Menu>
      </div>
    ) : (
      <Menu className="top-nav" inverted style={{ marginBottom: '5em' }}>
        {/* <Menu.Item>
          <Image as={NavLink} to="/" src={moxitLogo} size="tiny" />
        </Menu.Item> */}
        <Menu.Item as={NavLink} content="Unfurlough.us" to="/" />
        <Menu.Item as={NavLink} content="Gigs" to="/gigs" />
        {/* <Menu.Item as={NavLink} content="Freelancers" to="/freelancers" /> */}
        {menuRoutes}
        <Menu.Menu position="right">
          {/* <Menu.Item>
            <GlobalSearch fluid />
          </Menu.Item> */}
          <UserProfileMenu />
        </Menu.Menu>
      </Menu>
    );

  return nav;
};

const mapStateToProps = state => ({
  auth: state.auth,
  user: state.user,
  permissions: state.permissions,
  currentOrganization: state.organizations.currentOrganization,
});

export default withRouter(connect(mapStateToProps)(TopNavigation));
