import React from 'react';
import { Card, Header } from 'semantic-ui-react';
import Avatar from 'react-avatar';

const OrganizationCard = ({
  organization,
  onClick,
  currentUserDefaultOrganization,
}) => (
  <Card className="shadow" key={organization.id} onClick={onClick}>
    <Card.Content>
      <Avatar
        name={organization.name}
        round
        size={40}
        className="right floated"
      />
      <Card.Header>
        <Header as={'h3'}>{organization.name}</Header>
      </Card.Header>
      <Card.Meta>{organization.phone}</Card.Meta>
      <Card.Description>
        <p>{`${organization.address1 || ''} ${organization.address2 || ''}`}</p>
        <p>{`${organization.city || ''}, ${organization.state ||
          ''} ${organization.zipcode || ''}`}</p>
      </Card.Description>
    </Card.Content>

    <Card.Content extra>
      {currentUserDefaultOrganization === organization.id ? 'Selected' : ''}
    </Card.Content>
  </Card>
);

export default OrganizationCard;
