import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Segment,
  Icon,
  Header,
  Button,
  Loader,
  Dimmer,
} from 'semantic-ui-react';

// Import hoc
import withSlidingPanel from '../Shared/withSlidingPanel';

// Import components
import RoomForm from '../Forms/RoomForm';
import RoomsList from '../Rooms/RoomsList';

const SlidingRoomsForm = withSlidingPanel(RoomForm, {
  title: 'Add A Room',
  subtitle: 'Add a room in your organization',
});

class SetupRooms extends Component {
  state = { isOpen: false, added: false };

  renderAddBlock = () => {
    const {
      rooms: { list },
    } = this.props;

    if (list.length) return null;

    return (
      <Segment basic clearing textAlign="center">
        <Segment basic>
          <Icon name="cubes" size="huge" color="red" />
          <Header as={'h2'}>Add Your Rooms</Header>
          <p>Tell us details about the rooms at your center.</p>
        </Segment>
        <Segment basic>
          <SlidingRoomsForm
            isOpen={this.state.isOpen}
            onClose={status => {
              this.setState({ isOpen: false, added: status === 'added' });
            }}
          />
          <Button
            content="Add a Room"
            primary
            onClick={() => this.setState({ isOpen: true })}
          />
        </Segment>
      </Segment>
    );
  };

  /**
   * This loader will be displayed only during first room creation.
   * Sometimes cloud triggers are slow so this is used as a visual queue.
   */
  renderFirstTimeLoader = () => {
    const {
      currentOrganization: { setup },
    } = this.props;

    if (this.state.added && !setup.firstRoomCreated)
      return (
        <Dimmer active inverted>
          <Loader
            inline="centered"
            indeterminate
            content="Just a moment while we add your first room..."
          />
        </Dimmer>
      );
  };

  renderListBlock = () => {
    const {
      rooms: { list },
    } = this.props;

    if (!list.length) return null;
    return <RoomsList />;
  };
  render() {
    return (
      <Segment basic>
        {/* {this.renderFirstTimeLoader()} */}
        {this.renderAddBlock()}
        {this.renderListBlock()}
      </Segment>
    );
  }
}

SetupRooms.propTypes = {};

const mapStateToProps = state => ({
  currentOrganization: state.organizations.currentOrganization,
  rooms: state.rooms,
});
export default connect(mapStateToProps)(SetupRooms);
