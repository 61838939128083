import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header, Form, Segment } from 'semantic-ui-react';
import Validator from 'validator';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

// Import actions.
import { resetPassword } from '../../../redux/actions/userActions';

// Import components
import ShowErrors from '../../Messages/ShowError';

class SignInForm extends Component {
  state = {
    data: {
      email: '',
      password: '',
    },
    loading: false,
    errors: {},
  };

  onChange = (e, { name, value }) =>
    this.setState({
      data: { ...this.state.data, [name]: value },
      errors: _.omit(this.state.errors, name),
    });

  onSubmit = () => {
    const errors = this.validate();
    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      const {
        data: { email },
      } = this.state;
      this.setState({ loading: true });

      this.props
        .resetPassword(email)
        .then(user => {
          if (user) {
            const { location, history } = this.props;
            if (location.state && location.state.from) {
              const { pathname } = location.state.from;
              history.replace(pathname);
            } else {
              history.replace('/');
            }
          }
        })
        .catch(error => {
          this.setState({
            loading: false,
            errors: {
              ...this.state.errors,
              'Forgot Password Error': error.message,
            },
          });
        });
    }
  };

  validate = () => {
    const errors = {};
    if (!Validator.isEmail(this.state.data.email))
      errors.email = 'Invalid email';
    return errors;
  };

  render() {
    const { data, errors, loading } = this.state;

    return (
      <Segment basic textAlign="left">
        <Header as="h2" textAlign="center">
          Can't log in?
        </Header>
        <ShowErrors errors={errors} />
        <Form onSubmit={this.onSubmit} loading={loading} noValidate>
          <Form.Input
            required
            error={!!errors.email}
            autoComplete="username"
            name="email"
            type="email"
            label="Email"
            placeholder="Enter your email address"
            value={data.email}
            onChange={this.onChange}
          />

          <Form.Field>
            <strong>If you don’t see your reset email...</strong>
            <div>Be sure to check your spam filter.</div>
          </Form.Field>

          <Form.Button primary fluid size="large" content="Login" />
        </Form>
      </Segment>
    );
  }
}

SignInForm.propTypes = {
  resetPassword: PropTypes.func.isRequired,
};

export default withRouter(
  connect(
    null,
    { resetPassword }
  )(SignInForm)
);
