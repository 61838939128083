import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Menu, Icon } from 'semantic-ui-react';
import _ from 'lodash';

// Import helpers.
import { getCurrentUser } from '../../api/firebase/account';

const MobileHamburgerNavigation = ({
  auth,
  user,
  permissions,
  handleToggle,
}) => {
  if (!getCurrentUser()) return null;
  if (auth.authenticating || !auth.authenticated) return null;
  // if (!user || !user.claims) return null;
  // if (_.isEmpty(permissions)) return null;

  return (
    <Menu.Item onClick={handleToggle}>
      <Icon name="sidebar" />
    </Menu.Item>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  user: state.user,
  permissions: state.permissions,
});

export default withRouter(connect(mapStateToProps)(MobileHamburgerNavigation));
