import React from 'react';
import { Container, Segment } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';

const Unauthorized = props => {
  const renderPageHead = () => (
    <Helmet>
      <title>Unauthorized</title>
      <body class="auth error" />
    </Helmet>
  );

  return (
    <Container className="no-padding no-margin">
      {renderPageHead()}

      <Segment clearing basic>
        <h1 className="text-center">
          You are not authorized to see this content ¯\_(ツ)_/¯
        </h1>
      </Segment>
    </Container>
  );
};

export default Unauthorized;
