import React from 'react';
import { Grid, Header, Segment, Image } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';

// Import Logo
import moxitLogoSquare from '../styles/img/moxit-logo.png';

// Import pages.
import VerifyEmail from '../Components/Actions/VerifyEmail';
import ResetPassword from '../Components/Actions/ResetPassword';

const Action = ({ location, history }) => {
  const renderPageHead = () => (
    <Helmet>
      <title>Account Action</title>
      <body class="auth-action" />
    </Helmet>
  );

  const selectMode = () => {
    const { search } = location;
    const params = new URLSearchParams(search);

    // Get the action to complete.
    const mode = params.get('mode');

    // Get the one-time code from the query parameter.
    const actionCode = params.get('oobCode');

    // Get the continue Url to distinguish if the user is invited
    const continueUrl = params.get('continueUrl');

    switch (mode) {
      case 'recoverEmail':
        // Display reset password handler and UI.
        return null;
      case 'resetPassword':
        // Display email recovery handler and UI.
        return (
          <ResetPassword actionCode={actionCode} continueUrl={continueUrl} />
        );

      case 'verifyEmail':
        return <VerifyEmail actionCode={actionCode} />;

      default:
        history.replace('/404');
        break;
    }
  };

  return (
    <Grid textAlign="center" style={{ height: '100%' }} verticalAlign="middle">
      {renderPageHead()}

      <Grid.Column style={{ maxWidth: 500 }}>
        {/* <Header as="h2" textAlign="center">
          <Image src={moxitLogoSquare} />
        </Header> */}
        <Segment>{selectMode()}</Segment>
      </Grid.Column>
    </Grid>
  );
};

export default Action;
