import React from 'react';
import PropTypes from 'prop-types';
import { Card, Header } from 'semantic-ui-react';
import Avatar from 'react-avatar';

const RoomCard = ({ room, onClick }) => (
  <Card className="shadow" key={room.id} onClick={onClick}>
    <Card.Content>
      <Avatar name={room.name} round size={40} className="right floated" />
      <Card.Header>
        <Header as={'h3'}>{room.name}</Header>
      </Card.Header>
      <Card.Meta>{room.locationName}</Card.Meta>
      <Card.Description>
        <br />
        <br />
      </Card.Description>
    </Card.Content>
  </Card>
);

RoomCard.propTypes = {
  room: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default RoomCard;
