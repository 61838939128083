import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Label } from 'semantic-ui-react';
import moment from 'moment';
import { getRoomById } from '../../redux/actions/roomActions';

const StudentEnrollment = ({
  selectedStudent: {
    id,
    enrollmentStatus,
    rooms,
    tourDate,
    enrollmentDate,
    graduationDate,
    photosAllowed,
  },
}) => {
  if (!id) return null;
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={6}>Enrollment Status</Grid.Column>
        <Grid.Column width={10}>
          <Label
            content={enrollmentStatus ? 'Active' : 'Inactive'}
            color={enrollmentStatus ? 'green' : 'red'}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>Rooms</Grid.Column>
        <Grid.Column width={10}>
          <b>
            {rooms && rooms.length
              ? rooms.map(room => getRoomById(room).name).join(', ')
              : 'No room selected'}
          </b>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>Tour Date</Grid.Column>
        <Grid.Column width={10}>
          <b>
            {tourDate
              ? moment(`${tourDate}`, 'x').format('YYYY-MM-DD')
              : 'No tour date'}
          </b>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>Enrollment Date</Grid.Column>
        <Grid.Column width={10}>
          <b>
            {enrollmentDate
              ? moment(`${enrollmentDate}`, 'x').format('YYYY-MM-DD')
              : 'No enrollment date'}
          </b>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>Graduation Date</Grid.Column>
        <Grid.Column width={10}>
          <b>
            {graduationDate
              ? moment(`${graduationDate}`, 'x').format('YYYY-MM-DD')
              : 'No graduation date'}
          </b>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>Photos Allowed</Grid.Column>
        <Grid.Column width={10}>
          <b> {photosAllowed ? 'Yes' : 'No'} </b>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

StudentEnrollment.propTypes = {
  selectedStudent: PropTypes.shape({
    id: PropTypes.string,
    enrollmentStatus: PropTypes.bool,
    rooms: PropTypes.arrayOf(PropTypes.string),
    tourDate: PropTypes.number,
    enrollmentDate: PropTypes.number,
    graduationDate: PropTypes.number,
    photosAllowed: PropTypes.bool,
  }),
};
const mapStateToProps = state => ({
  selectedStudent: state.students.selectedStudent,
});
export default connect(mapStateToProps)(StudentEnrollment);
