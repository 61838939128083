import React from 'react';
import { NavLink } from 'react-router-dom';
import { Grid, Header, Image, Segment } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';

// Import components
import SignInForm from '../Components/Forms/Auth/SignInForm';
import moxitLogoSquare from '../styles/img/moxit-logo.png';

const SignIn = () => {
  const renderPageHead = () => (
    <Helmet>
      <title>Sign In</title>
      <body class="auth sign-in" />
    </Helmet>
  );

  return (
    <Grid textAlign="center" style={{ height: '100%' }} verticalAlign="middle">
      {renderPageHead()}

      <Grid.Column style={{ maxWidth: 500 }}>
        {/* <Header as="h2" textAlign="center">
          <Image src={moxitLogoSquare} />
        </Header> */}
        <Segment>
          <SignInForm />
        </Segment>
        <Segment basic>
          Need an account? <NavLink to="/signup">Create an account</NavLink>.
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

export default SignIn;
