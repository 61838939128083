import React from 'react';
import { connect } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

const AuthorizationChecker = ({
  currentOrganization: { setup },
  permissions,
  history,
  match,
}) => {
  if (_.isEmpty(permissions)) {
    return (
      <Dimmer page active inverted>
        <Loader inline="centered">Authorizing ...</Loader>
      </Dimmer>
    );
  } else {
    history.push('/401');
  }
  return null;
};

const mapStateToProps = state => ({
  permissions: state.permissions,
  currentOrganization: state.organizations.currentOrganization,
});

export default withRouter(connect(mapStateToProps)(AuthorizationChecker));
