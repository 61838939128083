import {
  ORGANIZATION_LISTENER_STARTED,
  ORGANIZATION_SELECTED,
  MOXIT_ORGANIZATIONS_FETCHED,
  ORGANIZATION_SWITCHED,
  ORGANIZATION_CREATED,
  ORGANIZATION_UPDATED,
} from './types';

// Import API.
import {
  organizationOnListen,
  getAllOrganizations,
  createOrganization,
  updateOrganization,
} from '../../api/firebase/organizations';
import {
  updateUserProfile,
  updateOrganizationUser,
} from '../../api/firebase/users';

export const organizationListenerStarted = () => ({
  type: ORGANIZATION_LISTENER_STARTED,
});

export const organizationFetched = organization => ({
  type: ORGANIZATION_SELECTED,
  organization,
});

export const moxitOrganizationsFetched = organizations => ({
  type: MOXIT_ORGANIZATIONS_FETCHED,
  organizations,
});

export const organizationSwitched = () => ({
  type: ORGANIZATION_SWITCHED,
});

export const organizationCreated = () => ({
  type: ORGANIZATION_CREATED,
});

export const organizationUpdated = () => ({
  type: ORGANIZATION_UPDATED,
});

export const startOrganizationListener = organizationId => dispatch => {
  dispatch(organizationListenerStarted());
  return organizationOnListen(
    organizationId,
    organization => {
      if (organization) dispatch(organizationFetched(organization));
    },
    error => console.log(error.message)
  );
};

export const getMoxitOrganizations = (limit, last) => async dispatch => {
  try {
    const organizations = await getAllOrganizations(limit, last);
    return dispatch(moxitOrganizationsFetched(organizations));
  } catch (error) {
    throw Error(error);
  }
};

export const createMoxitOrganization = data => async dispatch => {
  try {
    await createOrganization(data);
    dispatch(getMoxitOrganizations());
    return dispatch(organizationCreated());
  } catch (error) {
    throw Error(error);
  }
};

export const updateMoxitOrganization = data => async dispatch => {
  try {
    await updateOrganization(data.id, data);
    dispatch(getMoxitOrganizations());
    return dispatch(organizationUpdated());
  } catch (error) {
    throw Error(error);
  }
};

export const switchOrganization = (
  currentOrganizationId,
  newOrganizationId,
  uid
) => async dispatch => {
  try {
    if (!currentOrganizationId)
      throw Error('Current organization id is required');
    if (!newOrganizationId) throw Error('New organization id is required');
    if (!uid) throw Error('User uid is required');

    await updateUserProfile(uid, { defaultOrganization: newOrganizationId });
    await updateOrganizationUser(currentOrganizationId, uid, {
      defaultOrganization: newOrganizationId,
    });

    return dispatch(organizationSwitched());
  } catch (error) {
    throw Error(error);
  }
};
