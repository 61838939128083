import React from 'react';
import { Card, Label, Icon } from 'semantic-ui-react';

const icons = {
  ios: 'apple',
  android: 'android',
};

const RoomCard = ({ device, onClick }) => (
  <Card
    className="shadow"
    key={device.id}
    onClick={onClick}
    header={device.name}
    meta={
      <div>
        <div>
          username: <code>{device.email}</code>
        </div>
        <div>
          password: <code>{device.password}</code>
        </div>
      </div>
    }
    extra={
      <div>
        <Icon size="big" name={icons[device.type]} />
        <Label
          content={device.enabled ? 'Enabled' : 'Disabled'}
          color={device.enabled ? 'green' : 'red'}
        />
      </div>
    }
  />
);
export default RoomCard;
