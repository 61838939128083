import React from 'react';
import { Responsive, Container, Sidebar, Segment } from 'semantic-ui-react';

import TopNavigation from './../Navigation/TopNavigation';

import Masthead from './Masthead';
import Footer from './Footer';

const DesktopContainer = ({ layout, children }) => {
  return (
    <Responsive minWidth={Responsive.onlyTablet.minWidth}>
      <Sidebar.Pushable>
        <Sidebar.Pusher
          style={{ height: '100vh' }}
          dimmed={layout.sliderOpen}
          className="desktop-container"
        >
          <TopNavigation />
          <Masthead />

          <Container>
            <Segment raised>{children}</Segment>
          </Container>

          <Footer />
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </Responsive>
  );
};

export default DesktopContainer;
